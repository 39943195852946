import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, Modal, Space, Switch, Table, message } from 'antd'
import { delSafra, getSafra, putSafra } from '../../../services/Safra'

import { Notification } from '../../../Components/notification'

import FormSafra from '../../../Components/safra'

import { Container } from './styled'
import { useLogic } from '../../../context/useLogic'
import { IconAlert } from '../../../Components/iconsAlert'
import { pagination } from '../../../Components/Default'
import ViewPage from '../../../Components/ViewPage'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { tryError } from '../../../services/Afins'

const Safra: React.FC = () => {
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [updateUpdateSafra, setUpdateUpdateSafra] = useState<number>()
  const [codSafra, setCodSafra] = useState<number>()

  const { lisSafra, setLisSafra } = useLogic()

  const listarSafra = async () => {
    try {
      setLoadingTable(true)
      const response = await getSafra({})

      if (response.status === 200) {
        setLisSafra(response.data.itens)
        setLoadingTable(false)
      }
    } catch (error) {
      return error
    }
  }

  const handleClickVisuSafra = async (data) => {
    setVisibleForm(true)
    setUpdateUpdateSafra(data)
    setCodSafra(data)
  }

  // const handleChangeSwitchStatus = async (status: boolean, cod: number) => {
  //   try {
  //     const resp = await putSafra(cod, { status: status ? 1 : 0 })

  //     if (resp.status === 200) {
  //       Notification({
  //         message: 'Safra atualizada com sucesso',
  //         type: 'success',
  //       })
  //     }
  //   } catch (error) {
  //     tryError(error)
  //   }
  // }

  const handleTrashSafra = async (data) => {
    const responsePro = lisSafra.find((item) => item.cod == data)

    try {
      Modal.confirm({
        title: 'Excluir',
        icon: <IconAlert type='confirm' size={6} />,
        content: `Deseja realmente remover a Safra ${responsePro.nome}?`,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          try {
            const responseDel = await delSafra(data)

            if (responseDel.status === 200) {
              Notification({
                message: 'Safra excluída com sucesso',
                type: 'success',
              })

              listarSafra()
              message.destroy()
            }
          } catch (error) {
            tryError(error)
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdateUpdateSafra(0)
  }

  useEffect(() => {
    listarSafra()
  }, [])

  return (
    <Container>
      <ViewPage
        onPermission={setPermissions}
        rotina={26}
        title='Safra'
        btnClick={handleVisibleForm}
        content={
          <FormSafra
            listSafra={lisSafra}
            codSafra={codSafra}
            setCodSafra={setCodSafra}
            permissions={permissions}
            visible={visibleForm}
            setOnClose={setVisibleForm}
            update={updateUpdateSafra}
            setUpdate={setUpdateUpdateSafra}
            atualizar={listarSafra}
          />
        }
      >
        <Table
          loading={loadingTable}
          dataSource={lisSafra}
          pagination={pagination}
          scroll={{ y: 'calc(90vh - 275px)', x: 'auto' }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {lisSafra.length}</b>
            </div>
          )}
          bordered
          size='small'
          columns={[
            // {
            //   key: 0,
            //   width: 60,
            //   title: 'Ativo',
            //   dataIndex: 'status',
            //   render: (data, record) => {
            //     return (
            //       <>
            //         {record.cod}
            //         <Switch
            //           size='small'
            //           checked={data === 1}
            //           onChange={(data) => handleChangeSwitchStatus(data, record.cod)}
            //           unCheckedChildren='Não'
            //           checkedChildren='Sim'
            //         />
            //       </>
            //     )
            //   },
            // },
            {
              key: 1,
              title: 'Nome da Safra',
              dataIndex: 'nome',
              render: (nome) => <div style={{ minWidth: 100 }}>{nome}</div>,
            },
            {
              key: 2,
              title: 'Data início',
              dataIndex: 'data_inicio',
              render: (data) => <>{data.split('-').reverse().join('/')}</>,
            },
            {
              key: 3,
              title: 'Data fim',
              dataIndex: 'data_final',
              render: (data) => <>{data.split('-').reverse().join('/')}</>,
            },

            {
              title: 'Ações',
              align: 'center',
              dataIndex: 'cod',
              width: '90px',
              key: 'acao',
              render: (data) => (
                <Space>
                  <Button
                    shape='circle'
                    hidden={permissions?.alteracao === 0 && permissions?.acesso === 0}
                    onClick={() => handleClickVisuSafra(data)}
                    icon={<FontA icon={permissions?.acesso === 1 ? solid('eye') : solid('pen')} />}
                    size='middle'
                  />
                  <Button
                    shape='circle'
                    hidden={permissions?.exclusao === 0}
                    onClick={() => handleTrashSafra(data)}
                    className='trash-button'
                    type='primary'
                    icon={<FontA icon={solid('trash')} />}
                    size='middle'
                  />
                </Space>
              ),
            },
          ]}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickVisuSafra(record.cod)
              },
            }
          }}
        />
      </ViewPage>
    </Container>
  )
}

export default Safra