import styled, { css } from 'styled-components'

export const Container = styled.div<{ responsive?: boolean }>`
  ${(p) =>
    p.responsive &&
    css`
      width: 92vw;
      height: 90vh;
      overflow: auto;
    `}
  .card-temp {
    display: flex;
    justify-content: space-between;

    .card-temp-grau {
      font-size: 30px;
    }
  }
`
