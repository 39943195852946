import styled from "styled-components";

//STYLES
const Tbody = styled.div`
    width: 100%;
    min-height: 40px;
    border-left: thin solid rgba(0,0,0,0.06);
    border-right: thin solid rgba(0,0,0,0.06);
    overflow-y: auto;
`;

//PROPS
interface Props {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

export const BodyTable: React.FC<Props> = ({ children, style }) => {
    return (
        <Tbody style={style}>
            { children }
        </Tbody>
    )
}