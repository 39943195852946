import { H2Component } from "./styled"

//PROPS
interface Props {
    children: string | React.ReactNode;
    
}

export const TitleH2: React.FC<Props> = ({ children }) => {
    return (
        <H2Component>
            {children}
        </H2Component>
    )
}