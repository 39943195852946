import styled from 'styled-components'

export const Container = styled.div`
  width: 40px;
  height: 76px;
  position: relative;
  display: flex;
  align-items: center;

  .menu-user {
    width: 500px;

    ul {
      li {
        font-size: 25px;
      }
    }
  }
  .btn-avatar-usuario {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    color: #fff;
    font-weight: 600;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-size: 17px;
    cursor: pointer;
    text-transform: uppercase;
    box-sizing: border-box;
    transition: .3s;
    overflow: hidden;
  }
  .btn-avatar-usuario:hover {
    transform: scale(1.05);
    filter: brightness(110%);
  }
  .btn-avatar-usuario:active {
    transform: scale(0.9);
  }
  .btn-avatar-usuario__img {
    width: 100%;
    border-radius: 50%;
    transform: scale(1.4);
  }
`

export const PopoverOpcoes = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .PopoverOpcoes__logado {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-bottom: thin solid var(--border-color);

    .PopoverOpcoes__logado__nome {
      display: flex;
      margin: 0;
      padding: 0;
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 500;
      color: var(--text-color);
    }
  }

  .PopoverOpcoes__menu {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;

    li.PopoverOpcoes__menu__opt {
      width: 100%;

      button.PopoverOpcoes__menu__opt__btn {
        width: 100%;
        height: 40px;
        text-align: left;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: var(--text-color);
        transition: .4s;
        border-radius: 5px;
      }
      button.PopoverOpcoes__menu__opt__btn:hover {
        color: var(--primary-color);
      }
      button.PopoverOpcoes__menu__opt__btn#menu_opt_2:hover {
        color: red;
      }
      button.PopoverOpcoes__menu__opt__btn:active {
        transform: scale(0.95);
      }
    }
  }

`
