import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { api } from '../../api'

export interface TypesMapaFertilidade {
  itens: ItensMapaFertilidadeTypes[]
  links: {
    anterior: string
    atual: string
    proximo: string
  }
  mensagem: string
  pagina: number
  quantidadePorPagina: number
  timestamp: number
  total: number
}

export interface ItensMapaFertilidadeTypes {
  cod_elemento_pedido: number
  valor: string
  valor_distribuido: string
  valor_interpretado: string
  elemento_descricao: string
  qualidade: string
  ponto: number
  longitude: string
  latitude: string
  resposta_k: string
  observacao: string
  classificacao: string
  create_time: string
  update_time: string
  delete_time: string
  ordem?: number
  cod_propriedade: number
  elemento: string
  cod_elemento: number
  profundidade: string
  minimo: string
  maximo: string
  limite_minimo: string
  limite_maximo: string
  media: string
  descricao_profundidade?: string;
}

type TypesQuery = {
  cod?: number
  codPedido: number
  embed?: string
  pagination?: number
  codConta?: any
  codCliente?: any
  token?: string
  page?: number
  elementoUsoMapa?: boolean
}

const url = '/ap-resultado-analise-os'

export const getMapaFertilidade = async (params: TypesQuery) => {
  const { codCliente, conta } = localConfig()
  const { token = null } = params

  const { pagination = -1 } = params
  const resp: AxiosResponse<TypesMapaFertilidade> = await api.get(url, {
    params: {
      codCliente,
      codConta: conta,
      pagination,
      ...params,
    },
  })

  return resp
}
