import styled from "styled-components";

//STYLES
const Item = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    border-right: thin solid rgba(0,0,0,0.06);
`;

//PROPS
interface Props {
    children?: React.ReactNode;
    width?: number | string;
    gap?: number;
}

export const RowItem: React.FC<Props> = ({ children, width, gap }) => {
    return (
        <Item style={{ width: width, gap: gap }}>
            {children}
        </Item>
    )
}