export const paleta_cores = [
    'cyan', 
    '#ff0000',
    '#be0aff',
    '#0aff99',
    '#ff8700',
    '#580aff',
    '#deff0a',
    '#147df5',
    '#01befe',
    '#ffdd00',
    '#8f00ff',
    '#adff02',
    '#ff006d',
    '#ff7d00',
    '#6a00ff',
    '#ff00ff',
    '#ff0040',
    '#ffff00',
    '#00ff15',
    '#00ffff',
    '#f20089',
    '#ff9500',
    '#12ffd1',
    '#cb0eff',
    '#ffca09',
    '#ff0c72',
    '#009cff',
    '#09f04a',
]