import styled from 'styled-components'

interface Types {
  bg?: string
}

export const Container = styled.div<Types>`
  display: flex;
  min-height: 100vh;
  margin: 0 auto;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  justify-content: center;
  align-items: center;

  .login {
    background-color: #ffffff;
    width: 350px;
    padding: 30px 30px 0 30px;
    border-radius: 7px;

    img {
    }
    span {
    }
  }
`
