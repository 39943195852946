import { App as AppAntd, ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import PTbr from 'antd/lib/locale/pt_BR'
import React from 'react'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import GlobalStyled from './GlobalStyled'
import ServiceWorkerWrapper from './ServiceWorkerWrapper'
import Views from './Views'

import './All.css'
import './global-variables.css'
import './global.css'

import { UseAccess } from './context/useAccess'
import { LogicProvider as AppProvider } from './context/useLogic'
import { store } from './features'

const App: React.FC = () => {
  return (
      <Provider store={store}>
        <GlobalStyled />
        <ServiceWorkerWrapper />
        <ConfigProvider
          locale={PTbr}
          theme={{
            token: { colorPrimary: '#5da57d' },
            components: {
              Menu: { subMenuItemBg: '#3B5063' },
              Card: { colorBorderSecondary: '#dfdfdf' },
            },
            hashed: true,
          }}
        >
          <AppAntd>
            <UseAccess>
              <AppProvider>
                <Router basename='/'>
                  <Switch>
                    <Route component={Views} />
                  </Switch>
                </Router>
              </AppProvider>
            </UseAccess>
          </AppAntd>
        </ConfigProvider>
      </Provider>
  )
}

export default App
