import styled from "styled-components";

export const InputWrapper = styled.div`
    width: 100%;
    height: 60px;

    :is([data-label = false]) {
        height: 45px;
    }

    label {
        font-size: 13px;
        font-weight: 500;
        color: var(--text-color);
        display: inline-flex;
        gap: 5px;
    }

    span.InputWrapper__obrigatorio {
        color: var(--text-color-light);
        font-weight: 400;
        font-size: 11px;
    }

    div.InputWrapper__campo {
        width: 100%;
        height: 40px;
        border: thin solid var(--border-color);
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        box-sizing: border-box;
        padding: 0 10px;
    }
    div.InputWrapper__campo:focus-within {
        border: thin solid var(--primary-color);
        outline: thin solid rgba(93, 165, 125, 0.2);
    }
    div.InputWrapper__campo:is([data-error = true]) {
        border-color: #ff4d4f;
        outline: thin solid rgba(255, 117, 87, 0.2);
    }
    div.InputWrapper__campo:is([data-disabled = true]) {
        background: var(--disabled-color);
        border-color: var(--disabled-color);
        cursor: not-allowed;
        color: rgba(0,0,0,0.7);
    }

    .InputWrapper__campo__icon {
        opacity: 0.4;
    }

    div.InputWrapper__campo:is([data-error = true]) .InputWrapper__campo__icon {
        color: #ff4d4f;
    }

    input.InputWrapper__campo__input {
        width: calc(100% - 5px);
        height: 100%;
        border: none;
    }
    input.InputWrapper__campo__input:disabled {
        cursor: not-allowed;
    }
    input.InputWrapper__campo__input::placeholder {
        color: rgba(0,0,0,0.25);
    }

    button.InputWrapper__campo__clear {
        border: none;
        background: transparent;
        display: none;
        cursor: pointer;
        opacity: 0.5;
    }  
    button.InputWrapper__campo__clear:hover {
        opacity: 1;
    }  
    div.InputWrapper__campo:focus-within button.InputWrapper__campo__clear {
        display: flex;
    }

    span.InputWrapper__error {
        font-weight: 500;
        font-size: 11px;
        color: #ff4d4f;
        display: flex;
        line-height: 12px;
    }

`