import React, { useEffect, useRef, useState } from 'react'

import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import Icon from '@ant-design/icons'
import {
  Button,
  message,
  Modal,
  Popconfirm,
  RefSelectProps,
  Select,
  Space,
  Table,
  Upload,
} from 'antd'

import { UploadChangeParam } from 'antd/lib/upload'

// @ts-ignore
import { kml } from '@tmcw/togeojson'
import { Notification } from '../../../Components/notification'

import { delTalhao, getKmlTalhao, getTalhao } from '../../../services/Talhao'

import FormTalhao from '../../../Components/talhao'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { pagination } from '../../../Components/Default'
import DesenhaTalhao from '../../../Components/desenhoTalhao'
import Icons from '../../../Components/Icons'
import { IconAlert } from '../../../Components/iconsAlert'
import ImporteKmlTalhao from '../../../Components/importKmlTalhao'
import Svg from '../../../Components/talhasvg'
import ViewPage from '../../../Components/ViewPage'
import { useLogic } from '../../../context/useLogic'
import { fileTypeText, parseXml } from '../../../services/Afins'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { getPropriedade } from '../../../services/Propriedades'
import { Container } from './styled'

interface TypesMapa {
  zoom: number
  center:
    | {
        lat: number
        lng: number
      }
    | google.maps.LatLng
  mapContainerStyle?: { [key: string]: string }
}

interface TypeDataTalhao {
  alert?: boolean
  talhao: string
  ativo: boolean
  coord:
    | {
        lat: number
        lng: number
      }[]
    | google.maps.LatLng
}
const { Option } = Select

const Talhao: React.FC = () => {
  const uploadFile = useRef(null)
  const url = useRef(null)
  const refSelect = useRef<RefSelectProps>()

  const { responsive } = useLogic()

  const [dataTalhao, setDataTalhao] = useState([])
  const [dataPropriedade, setDataPropriedade] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [udateTalhao, setUpdateTalhao] = useState(0)
  const [codPro, setCodPro] = useState<number>(0)
  const [nameTalhao, setNameTalhao] = useState('')
  const [propsMap, setPropsMapa] = useState<TypesMapa>({
    zoom: 2,
    center: {
      lat: 0,
      lng: 0,
    },
    mapContainerStyle: { width: '100%', height: '78vh' },
  })
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [updateSelect, setUpdateSelect] = useState<any>()
  const [polygonTalhao, setPolygonTalhao] = useState([])
  const [dataPolygon, setDataPolygon] = useState<TypeDataTalhao[]>([])
  const [getVisibleDesenhaTalalhao, setVisibleDesenhaTalalhao] = useState(false)
  const [visibleImportTalhao, setVisibleImportTalhao] = useState(false)

  const [delListTalhao, setDelListTalhao] = useState([])

  // console.log('dataTalhao', dataTalhao);
  

  const handleClickVisuTalhao = (codPro, codTalhao) => {
    setVisibleForm(true)
    setUpdateTalhao(codTalhao)
    setCodPro(codPro)
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdateTalhao(0)
  }

  const listarPropriedades = async () => {
    setLoadingTable(true)
    const response = await getPropriedade('')
    const dados = response.data.itens
    setDataPropriedade(dados)
    if (dados.length === 1) {
      setUpdateSelect(dados[0].cod)

      listTalhao(dados[0].cod, undefined)
    } else {
      setUpdateSelect(null)
    }
    setLoadingTable(false)
  }

  const listTalhao = async (data, event) => {
    setUpdateSelect(data)
    setLoadingTable(true)
    const response = await getTalhao(data, '')
    setDataTalhao(response?.data.itens)
    setLoadingTable(false)
    setCodPro(data ? data : 0)
  }

  const centerMap = (coord) => {
    const bounds = new window.google.maps.LatLngBounds()

    coord.forEach((propriedade) => {
      propriedade.forEach((talhao) => {
        bounds.extend(talhao)
      })
    })
    return bounds.getCenter()
  }

  const onChangeUpload = async (info: UploadChangeParam) => {
    message.loading('Carregando Talhôes', 99999)

    if (info.file.status === 'done') {
      const domKml = parseXml(await fileTypeText(info.file.originFileObj))
      const dataGeoJson = kml(domKml)

      const propridade = []
      const talhoes = []

      const dados = dataGeoJson.features
      const filtrado = dados.filter((data) => data.geometry.type === 'Polygon')

      if (filtrado.length > 0) {

        for (const item of filtrado) {
          const fragment = item
          const talhaoPoly = []

          const coordinates = fragment.geometry.coordinates[0]

          for (const item_coordenada of coordinates) {
            const coord = item_coordenada

            talhaoPoly.push({ lat: coord[1], lng: coord[0] })
          }

          setNameTalhao(
            fragment.properties.name ? fragment.properties.name : fragment.properties.NOME
          )
          propridade.push({
            talhao: fragment.properties.name ? fragment.properties.name : fragment.properties.NOME,
            coord: talhaoPoly,
            ativo: true,
          })

          talhoes.push(talhaoPoly)
        }
      } else {
        Notification({
          message: 'Arquivo Inválido',
          type: 'warning',
        })
        message.destroy()
        return
      }

      const centerPoly = centerMap(talhoes)

      setPropsMapa({
        center: centerPoly,
        zoom: 11,
      })

      setPolygonTalhao(talhoes)
      setDataPolygon(propridade)
      message.destroy()
      setVisibleImportTalhao(true)
    }
  }

  const onRemoveFile = () => {
    //
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const handleCloseModal = (data) => {
    setVisibleDesenhaTalalhao(!data)
    listTalhao(codPro, '')
  }

  const handleTrashTalhao = async (codPro, codTalhao) => {
    const nomePro = dataTalhao.filter((item) => item.cod === codTalhao)

    try {
      Modal.confirm({
        title: 'Excluir',
        icon: <IconAlert type='confirm' size={6} />,
        content: `Deseja realmente remover o Talhao ${nomePro[0]?.nome}?`,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delTalhao(codPro, codTalhao)

          if (responseDel.status === 200) {
            Notification({
              message: 'Talhao Excluido',
              type: 'success',
            })

            listTalhao(codPro, '')
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  const handleClickDesenhaTalhao = () => {
    setUpdateTalhao(0)
    setVisibleDesenhaTalalhao(true)
  }

  const handleSelectTableRow = (cod) => {
    setDelListTalhao(cod)
  }

  const handleRemoveAllTalhoes = async () => {
    setLoadingTable(true)
    for (let i = 0; i < delListTalhao.length; i += 1) {
      const data = delListTalhao[i]

      await delTalhao(codPro, data)

      if (delListTalhao.length - 1 === i) {
        listTalhao(codPro, '')
        setDelListTalhao([])
        setLoadingTable(false)
      }
    }
  }

  const handleClikBaixarKml = async (arquivo, nome, data) => {
    const response = await getKmlTalhao(data, arquivo)

    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    })
    url.current.href = URL.createObjectURL(blob)
    url.current.download = `${nome}.kml`
    url.current.click()
  }

  useEffect(() => {
    listarPropriedades()
  }, [])

  useEffect(() => {
    if (visibleForm === false) {
      listarPropriedades()
    }
  }, [visibleForm])

  return (
    <Container>
      <ViewPage
        title='Talhões da propriedade'
        onPermission={setPermissions}
        newHide
        rotina={28}
        btnClick={null}
        disableNew={codPro === 0}
        btnsExta={
          <Space>
            <Select
              ref={refSelect}
              onChange={listTalhao}
              placeholder='Selecione uma Propriedade'
              showSearch
              popupMatchSelectWidth={false}
              value={updateSelect}
              style={{ minWidth: '175px' }}
              optionFilterProp='children'
            >
              {dataPropriedade.map((data) => (
                <Option key={data.cod} value={data.cod}>
                  {data.nome}
                </Option>
              ))}
            </Select>
            <Button
              hidden={permissions?.inclusao === 0}
              onClick={handleVisibleForm}
              disabled={codPro === 0}
              type='primary'
              icon={<Icon component={() => <Icons icon={faPlus} />} />}
            >
              Novo
            </Button>
            <Upload
              accept='.kml'
              ref={uploadFile}
              onChange={onChangeUpload}
              onRemove={onRemoveFile}
              showUploadList={false}
              maxCount={1}
              multiple={false}
              customRequest={dummyRequest}
            >
              <Button
                hidden={permissions?.inclusao === 0}
                disabled={codPro === 0}
                type='primary'
                icon={<Icon component={() => <FontA icon={solid('upload')} />} />}
              >
                Importar Kml
              </Button>
            </Upload>
            <Button
              hidden={permissions?.inclusao === 0 || responsive}
              disabled={codPro === 0}
              type='primary'
              icon={<Icon component={() => <FontA icon={solid('pen')} />} />}
              onClick={handleClickDesenhaTalhao}
            >
              Desenhar
            </Button>
            <Popconfirm
              icon={<IconAlert type='confirm' size={4} />}
              onConfirm={handleRemoveAllTalhoes}
              title='Deseja realmente remover os talhões selecionados?'
            >
              <Button
                hidden={permissions?.exclusao === 0}
                disabled={delListTalhao.length <= 1}
                type='primary'
                icon={<Icon component={() => <FontA icon={solid('trash')} />} />}
                danger
              >
                Remover selecionados
              </Button>
            </Popconfirm>
          </Space>
        }
        content={
          <FormTalhao
            onClose={setVisibleForm}
            dataTalhao={dataTalhao}
            permissions={permissions}
            visible={visibleForm}
            update={udateTalhao}
            setUpdate={setUpdateTalhao}
            codPro={codPro}
            atualizar={listTalhao}
          />
        }
      >
        <Table
          rowKey='cod'
          rowSelection={
            permissions?.exclusao === 1
              ? {
                  type: 'checkbox',
                  selectedRowKeys: delListTalhao,
                  onChange: (data) => handleSelectTableRow(data),
                }
              : null
          }
          loading={loadingTable}
          pagination={pagination}
          dataSource={dataTalhao}
          scroll={{ y: 'calc(90vh - 275px)', x: 'auto' }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataTalhao.length}</b>
            </div>
          )}
          bordered
          // @ts-ignore
          atualizar={listTalhao}
          size='small'
          columns={[
            {
              key: 1,
              title: 'Talhão',
              dataIndex: 'kml',
              width: '80px',
              render: (data) => {
                return (
                    <Icon
                      component={() => <Svg coordinates={data?.coordenadas} fill='#70ce9f' />}
                    />
                )
              },
            },
            {
              key: 1,
              title: 'Nome',
              dataIndex: 'nome',
              width: 'auto',
            },
            {
              key: 2,
              title: 'Área (ha)',
              dataIndex: 'area',
              render: (data) => {
                return <>{data.replace('.', ',')}</>
              },
            },
            {
              title: 'Ações',
              dataIndex: 'cod',
              align: 'center',
              key: 'acao',
              width: '135px',
              render: (data, fullData) => (
                <>
                  <Space>
                    <Button
                      hidden={permissions?.acesso === 0 && permissions?.alteracao === 0}
                      shape='circle'
                      onClick={() => handleClickVisuTalhao(fullData.cod_propriedade, data)}
                      icon={
                        <FontA icon={permissions?.acesso === 1 ? solid('eye') : solid('pen')} />
                      }
                      size='middle'
                    />
                    <Button
                      shape='circle'
                      disabled={fullData.kml === null}
                      onClick={() => handleClikBaixarKml(fullData.arquivo, fullData.nome, data)}
                      icon={<FontA icon={solid('download')} />}
                      size='middle'
                    />
                    <Button
                      hidden={permissions?.exclusao === 0}
                      shape='circle'
                      onClick={() => handleTrashTalhao(fullData.cod_propriedade, data)}
                      className='trash-button'
                      type='primary'
                      icon={<FontA icon={solid('trash')} />}
                      size='middle'
                    />
                  </Space>
                  <a style={{ display: 'none' }} href='empty' ref={url}>
                    ref
                  </a>
                </>
              ),
            },
          ]}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickVisuTalhao(record.cod_propriedade, record.cod)
              },
            }
          }}
        />
      </ViewPage>
      <Modal
        style={{ top: 20, minWidth: '95%' }}
        open={getVisibleDesenhaTalalhao}
        onCancel={handleCloseModal}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ danger: true, type: 'primary' }}
      >
        <DesenhaTalhao
          visible={getVisibleDesenhaTalalhao}
          onClose={handleCloseModal}
          update={udateTalhao}
          propriedade={codPro}
          atualizar={listTalhao}
          cultivavel={false}
        />
      </Modal>
      <Modal
        closeIcon
        style={{ top: 20, minWidth: '95%' }}
        open={visibleImportTalhao}
        destroyOnClose={visibleImportTalhao}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <ImporteKmlTalhao
          listTalhoesRecord={dataTalhao}
          mapSet={propsMap}
          propriedade={codPro}
          nameTalhao={nameTalhao}
          paths={polygonTalhao}
          dataPolygon={dataPolygon}
          onClose={() => {
            setVisibleImportTalhao(false)
            listTalhao(codPro, '')
          }}
        />
      </Modal>
    </Container>
  )
}

export default Talhao
