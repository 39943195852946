import { Dayjs } from 'dayjs'
import { api } from '../../api'

import { localConfig } from '../../../Configs/localConfig'
import { EstadioItens } from '../../estadio'
import { ItemMoniNivelControl } from '../nivelControle'

export interface TypePadroesMonito {
  itens: [PadroesMonitoItens]
}

export interface PadroesMonitoItens {
  cod?: number
  cod_conta?: number
  cod_cliente?: number
  cod_nivel_controle?: number
  padrao_consultoria?: number | boolean
  cod_usuario?: number
  nome?: string
  data?: string | Dayjs
  status?: number
  cultura_nome?: string
  cod_cultura?: number
  item?: ItemMoniNivelControl[]
  estadio?: EstadioItens[]
}

interface TypeQuery {
  codConta?: number | string
  codCliente?: number | string
  codCultura?: number
  cod?: number
  pagination?: number | -1
  embed?: string
}

export const getPadraoMonitoramento = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  const { codCliente, conta, acesso } = localConfig()

  params = {
    pagination,
    codConta: conta,
    codCliente,
    ...params,
  }

  const response = api.get('/monitoramento/perfil-monitoramento', {
    params,
  })

  return response
}

export const postPadraoMonitoramento = async (data) => {
  const config = localConfig()

  const response = api.post('/monitoramento/perfil-monitoramento', data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      embed: 'variacao',
    },
  })

  return response
}

export const putPadraoMonitoramento = async (cod, data) => {
  const config = localConfig()

  const response = api.put(`/monitoramento/perfil-monitoramento/${cod}`, data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}

export const delPadraoMonitoramento = async (cod) => {
  const config = localConfig()

  const response = api.delete(`/monitoramento/perfil-monitoramento/${cod}`, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}
