import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'
import { tryError } from '../Afins'
import { ItensMonitoramentoTypes } from '../monitoramento'

export interface TypeGetTalhao {
  itens: [TalhaoItens]
}

export interface TalhaoItens {
  cod?: number
  cod_conta?: number
  cod_talhao?: number
  isSubArea?: boolean
  cod_propriedade?: number
  area?: number
  arquivo?: string
  nome?: string
  observacao?: string
  arquivo_url?: string
  /**
   * @param {status} 1 = Ativo | 0 = Desativado
   */
  status?: 1 | 0
  length?: number
  cor?: string
  monitoramento?: ItensMonitoramentoTypes[]

  kml?: {
    nome?: string
    lookAt?: {
      latitude?: any
      longitude?: any
      lat?: any
      lng?: any
    }
    coordenadas?: [
      {
        latitude?: any
        longitude?: any

        lat?: any
        lng?: any
      }
    ]
  }
}

export const getTalhao = async (codPropri, codTalhao) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetTalhao> = await api.get(
    `/talhao?codConta=${config.conta}&codCliente=${config.codCliente}&codPropriedade=${codPropri}&cod=${codTalhao}&pagination=-1`
  )

  return response
}

export const getTalhaoPorSafra = async (codPropri, codTalhao) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetTalhao> = await api.get(
    `/talhao-safra?codConta=${config.conta}&codCliente=${config.codCliente}&codPropriedade=${codPropri}&cod=${codTalhao}&codSafra=${config?.codSafra}&pagination=-1`
  )

  return response
}

export const postTalhao = (codPro, data) => {
  const config = localConfig()

  const response = api.post(
    `/talhao?codConta=${config.conta}&codCliente=${config.codCliente}&codPropriedade=${codPro}`,
    data
  )

  return response
}

export const postMultiTalhao = (codPro, data) => {
  const config = localConfig()

  const response = api
    .post(
      `/talhao?codConta=${config.conta}&codCliente=${config.codCliente}&codPropriedade=${codPro}`,
      data
    )
    .then((response: AxiosResponse<TypeGetTalhao>) => {
      return response
    })
    .catch((error) => tryError(error))

  return response
}

export const putTalhao = async (codPro, codTalhao, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetTalhao> = await api.put(
    `/talhao/${codTalhao}?codConta=${config.conta}&codCliente=${config.codCliente}&codPropriedade=${codPro}`,
    data
  )

  return response
}

export const getDocTalhao = async (cod, file) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse = await api.get(
    `/talhao/${cod}/visualizar/arquivo/${file}?codConta=${config.conta}&codCliente=${config.codCliente}`
  )
  return response
}

export const getKmlTalhao = async (cod, file) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse = await api({
    url: `/talhao/${cod}/visualizar/arquivo/${file}?codConta=${config.conta}&codCliente=${config.codCliente}`,
    method: 'GET',
    responseType: 'blob',
  })

  return response
}

export const delTalhao = (codPro, codTalhao) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response = api.delete(
    `/talhao/${codTalhao}?codConta=${config.conta}&codCliente=${config.codCliente}&codPropriedade=${codPro}`
  )

  return response
}
