import { Table } from 'antd'
import { AxiosResponse } from 'axios'
import { groupBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { pagination } from '../../../../Components/Default'
import ViewPage from '../../../../Components/ViewPage'
import NovoMonitoramento from '../../../../Components/monitoramento/padraoDePontos'
import Svg from '../../../../Components/talhasvg'
import { useLogic } from '../../../../context/useLogic'
import { PropriedadeItens } from '../../../../services/Propriedades'
import { TalhaoCultivaveisItens, getTalhaoCultivavel } from '../../../../services/TalhaoSafra'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { SubAreaItens, TypeSubArea, getSubArea } from '../../../../services/subArea'

import { Container } from './styles'

const PageNovoMonitoramento: React.FC = () => {
  const { checkSafra } = useLogic()
  const history = useHistory()
  const [permissions, setPermissions] = useState<ItensPerUserLogged>({} as ItensPerUserLogged)
  const [visibleDrawer, setVisibleDrawer] = useState(false)

  const [dataPontos, setDataPontos] = useState<
    { cod_propriedade: number; talhoes: TalhaoCultivaveisItens[] }[]
  >([])
  const [propriedades, setPropriedades] = useState<PropriedadeItens[]>([])
  const [subArea, setSubArea] = useState<SubAreaItens[]>([])

  const handleClickNew = () => {
    setVisibleDrawer(true)
  }

  const handleDefaultPoint = async () => {
    const resp = await getTalhaoCultivavel({ embed: 'padrao_pontos', pagination: -1 })
    const sub: AxiosResponse<TypeSubArea> = await getSubArea({
      pagination: -1,
      embed: 'padrao_pontos',
    })

    setSubArea(sub.data.itens)

    const dados = resp.data.itens

    const teste = groupBy(dados, 'cod_propriedade')

    const obj = Object.entries(teste).map(([key, value]) => {
      return { cod_propriedade: Number(key), talhoes: value }
    })

    setDataPontos(obj)
  }

  useEffect(() => {
    const idDaSafra = checkSafra('/monitoramento/padrao_pontos')
    handleDefaultPoint()

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])
  return (
    <Container>
      <ViewPage
        rotina={71}
        nameButton='Visualizar pontos'
        title='Pontos padrão para monitoramento'
        onPermission={setPermissions}
        btnClick={() => handleClickNew()}
        content={
          <NovoMonitoramento
            permissions={permissions}
            visible={visibleDrawer}
            onClose={setVisibleDrawer}
            onListPropriedade={setPropriedades}
          />
        }
      >
        <Table
          dataSource={dataPontos}
          rowKey={(rec) => rec.cod_propriedade}
          size='small'
          bordered
          pagination={pagination}
          scroll={{ y: 'calc(90vh - 255px)' }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataPontos.length}</b>
            </div>
          )}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                rowKey='cod'
                size='small'
                bordered
                dataSource={record.talhoes}
                pagination={pagination}
                expandable={{
                  expandedRowRender: (rec) => {
                    const sub = subArea.filter((item) => item.cod_talhao === rec.cod)
                    return (
                      <Table
                        rowKey='cod'
                        size='small'
                        bordered
                        dataSource={sub}
                        pagination={pagination}
                        columns={[
                          {
                            key: 1,
                            dataIndex: 'kml',
                            width: 60,
                            title: 'SubArea',
                            render: (data, rec) => (
                              // @ts-ignore
                              <Svg fill='#FFA500' coordinates={rec.kml.coordenadas} />
                            ),
                          },
                          {
                            key: 2,
                            dataIndex: 'nome',
                            title: 'Talhão',
                          },
                          {
                            key: 3,
                            dataIndex: 'padrao_pontos',
                            title: 'Qtda de pontos',
                            render: (data) => data.length,
                          },
                        ]}
                      />
                    )
                  },

                  rowExpandable: (record) =>
                    subArea.filter((item) => item.cod_talhao === record.cod).length > 0,
                }}
                footer={() => (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'right',
                    }}
                  >
                    <b>Total geral: {record.talhoes.length}</b>
                  </div>
                )}
                columns={[
                  {
                    key: 1,
                    dataIndex: 'kml',
                    width: 60,
                    title: 'Talhão',
                    render: (data, rec) => (
                      // @ts-ignore
                      <Svg fill='#8BBEA3' coordinates={rec.kml.coordenadas as any} />
                    ),
                  },
                  { key: 2, dataIndex: 'nome', title: 'Nome' },
                  {
                    key: 3,
                    dataIndex: 'padrao_pontos',
                    title: 'Qtda de pontos',
                    render: (data) => data.length,
                  },
                  {
                    key: 4,
                    dataIndex: 'cod',
                    title: 'Qtda de subarea',
                    render: (data) => subArea.filter((item) => item.cod_talhao === data).length,
                  },
                ]}
              />
            ),
            rowExpandable: (record) => record.talhoes.length > 0,
          }}
          columns={[
            {
              key: 1,
              dataIndex: 'cod_propriedade',
              title: 'Propriedade',
              render: (data) => propriedades.find((item) => data === item.cod)?.nome,
            },
          ]}
        />
      </ViewPage>
    </Container>
  )
}

export default PageNovoMonitoramento
