import styled from "styled-components";

export const SelectWrapper = styled.div`
    width: 100%;
    height: 60px;
    position: relative;

    label {
        font-size: 13px;
        font-weight: 500;
        color: var(--text-color);
    }

    div.SelectWrapper__campo {
        width: 100%;
        height: 40px;
        border: thin solid var(--border-color);
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        box-sizing: border-box;
        padding: 0 10px;
        cursor: text;
    }
    :is([data-disabled = true]) div.SelectWrapper__campo {
        background-color: var(--disabled-color);
        cursor: not-allowed;
    }
    div.SelectWrapper__campo:focus-within {
        border: thin solid var(--primary-color);
        outline: thin solid rgba(93, 165, 125, 0.2);
    }

    .SelectWrapper__campo__icon {
        opacity: 0.4;
        cursor: text;
    }

    input.SelectWrapper__campo__input {
        width: calc(100% - 5px);
        height: 100%;
        border: none;
        color: var(--text-color);
    }
    input.SelectWrapper__campo__input::placeholder {
        color: rgba(0,0,0,0.25);
    }
    input.SelectWrapper__campo__input:disabled {
        cursor: not-allowed;
    }

    div.SelectWrapper__icons {
        display: flex;
    }

    button.SelectWrapper__campo__limpar {
        background-color: transparent;
        border: none;
        cursor: pointer;
        opacity: 0.45;
    }
    button.SelectWrapper__campo__limpar:hover {
        opacity: 1;
    }

    button.SelectWrapper__campo__arrow {
        border: none;
        background-color: transparent;
        cursor: text;
        opacity: 0.5;
        transition: .2s;
    }  
    :is([data-disabled = true]) button.SelectWrapper__campo__arrow {
        cursor: not-allowed;
    }
    div.SelectWrapper__campo:focus-within button.SelectWrapper__campo__arrow {
        transform: rotate(180deg);
    }  

    div.SelectWrapper__lista {
        position: absolute;
        left: 0;
        top: 61px;
        margin-top: 2px;
        width: 100%;
        max-height: 50vh;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
        transition: .2s;
        transition-timing-function: ease-out;
        overflow-y: auto;
        z-index: 999;

        :is([data-hasLabel = false]) {
            top: 40px;
        }
    }
    div.SelectWrapper__lista:is([data-open = true]) {
        transform: scaleY(1);
        opacity: 1;
    }

`