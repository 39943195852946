import styled from 'styled-components'

export const Container = styled.div`
  .ant-col .nivel-col {
    padding: 3px;
    transition: 0.5s;
    &:hover {
      background-color: rgba(83, 229, 208, 0.1);
    }
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .variacao-p {
    color: #949494;
    font-size: 10px;
    margin-bottom: 1px;
  }

  .overflow-checkbox-group {
    overflow: auto;
    height: calc(90vh - 200px);
    background-color: #fafafa;
    padding: 5px;
  }
`
