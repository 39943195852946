import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center !important;
  }
`

export const ContentMap = styled.div<{ colorBadge?: string }>`
  width: 600px;
  position: absolute;
  height: 100vh;
  padding: 7px;
  z-index: 9999;

  .ant-badge.ant-badge-status .ant-badge-status-dot {
    margin-left: 15px;
    width: 20px !important;
    height: 20px !important;
  }

  .ant-badge-status-processing::after {
    border: 20px solid ${(p) => p.colorBadge} !important;
  }

  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
    width: 200px;
  }

  .description-padding {
    padding: 0 !important;
  }
`

const slideRightOpen = keyframes`
  to {
    margin-right: 0px;;
  } from {
    margin-right: -400px;
  }
`

const slideRightClose = keyframes`
  from {
    margin-right: 0px;;
  } to {
    margin-right: -400px;
  }
`

export const DivDrawerPonto = styled.div<{ slideOpen: boolean; colorBadge?: string }>`
  position: absolute;
  z-index: 9999;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  background-color: #ffffff;
  right: 0;
  margin-right: 0px;
  width: 400px;
  height: 100vh;

  animation: ${(p) => (p.slideOpen ? slideRightOpen : slideRightClose)} 0.2s forwards;

  .ant-badge-status-processing {
    position: relative;
    background-color: ${(p) => p.colorBadge} !important;
  }

  .ant-badge-status-processing::after {
    border: 1px solid ${(p) => p.colorBadge} !important;
  }
  .ant-badge.ant-badge-status .ant-badge-status-dot {
    margin-left: 15px;
    width: 10px !important;
    height: 10px !important;
  }
`

export const HideMarker = styled.div`
  z-index: 9999;
  position: absolute;
  padding: 10px;
  margin: 10px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
`

export const BadgePulse = styled.div<{ colorBadge?: string }>`
  top: 5px;
  .ant-badge-status-processing::after {
    border: 1px solid ${(p) => p.colorBadge} !important;
  }
`

export const MedMax = styled.div<{ hide?: boolean }>`
  display: flex;
  justify-content: center;
  font-weight: 500;
  min-width: 100%;
  font-size: 9px;
  background-color: #eeeeee;
  margin-top: 12px;

  div {
    padding: 2px;
    opacity: ${(p) => (p.hide ? 0 : 1)};
  }
`

export const OverFlowDescription = styled.div<{ sizOverflow?: boolean }>`
  max-height: ${(p) => (p.sizOverflow ? 'calc(100vh - 435px)' : 'calc(100vh - 350px);')};
  position: relative;
  overflow: auto;
  width: 100%;
`

export const PanelDetails = styled.div`
  position: relative;
  min-height: 90vh;
`

const slideDetailsOpen = keyframes`
  to {
    margin-left: 0px;
  } from {
    margin-left: -550;
  }
`

const slideDetailsClose = keyframes`
  from {
    margin-left: 0px;
  } to {
    margin-left: -550;
  }
`

export const DetailsMonitoramento = styled.div<{ slideOpen?: boolean }>`
  z-index: 9999;
  margin-left: -600px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 90vh;
  transition: 0.5s;
  animation: ${(p) => (p.slideOpen ? slideDetailsOpen : slideDetailsClose)} 0.2s forwards;

  background-color: #ffffff;
  table {
    width: 100%;
    th {
      background-color: #fafafa;
    }
    .media {
      td {
        text-align: center;
      }
    }
  }
  table,
  th,
  td {
    border: 1px solid #f0f0f0;
    padding: 10px;
  }

  table tr td.cores {
    width: 100%;
    div {
      display: flex;
      flex-direction: row;
      height: 10px;
      div {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    .logo {
      padding-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      img {
        width: 200px;
      }
    }
  }
`

export const ContentPanel = styled.div<{ view?: boolean }>`
  position: relative;
  display: ${(p) => (p.view ? 'none' : '')};
`

export const Inverte = styled.b<{ invert?: boolean }>`
  padding: 3px;
  background-color: ${(p) => (p.invert ? '#ffff02' : '#01FF00')};
  border-radius: 5px;
  color: ${(p) => (p.invert ? '#000000' : '#000000')};
`
export const DivCultura = styled.div`
  border: 1px solid #d9d9d9;
  height: 30px;
  padding: 3px;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #c3c4c3;
  :hover{
    cursor: not-allowed;
  }
`
