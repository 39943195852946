import styled from "styled-components";

//STYLES
const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--border-color);
`;

//PROPS
interface Props {
    size?: number;
}

export const LineDivider: React.FC<Props> = ({ size }) => {
    return (
        <Line style={{height: size}} />
    )
}