import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { faCopy, faExclamationTriangle, faMapMarked } from '@fortawesome/free-solid-svg-icons'
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Popconfirm,
  Popover,
  Row,
} from 'antd'
import React from 'react'
import { useLogic } from '../../../context/useLogic'
import Icons from '../../Icons'
import { IconAlert } from '../../iconsAlert'
import { CardBtn } from '../styled'

type Props = {
  addAnalise: any
  areaTalhao: any
  malha: any
  totalFinalAnalise: any
  totalAnalises: number
  handleClickDuplicar: any
  permissions: any
  handleClickVisuPontos: (i: any, analise: any) => void
  handleClickBaixarKml: (i: any, nome: any, dado: any) => void
  handleRemoveAnalise: (i: any) => void
  handleTrashPonto: (dataI: any, i: any, index: any) => void
  url: any
  handleClickAddPonto: (i: any) => void
  propriedadeSelecionado: any
  areaAmostra: any
  checkMalhaPonto: (i: any) => void
}

const AnalisesOrder: React.FC<Props> = (props) => {
  const {
    addAnalise,
    areaTalhao,
    malha,
    totalFinalAnalise,
    handleClickDuplicar,
    permissions,
    handleClickVisuPontos,
    totalAnalises,
    handleClickBaixarKml,
    handleRemoveAnalise,
    handleTrashPonto,
    url,
    handleClickAddPonto,
    propriedadeSelecionado,
    areaAmostra,
    checkMalhaPonto,
  } = props

  const { responsive } = useLogic()
  return (
    <Card
      size='small'
      title='Analises'
      bodyStyle={{
        height: responsive ? 'calc(90vh - 300px)' : 'calc(90vh - 240px)',
        overflow: 'auto',
        ...(responsive ? { padding: 0 } : {}),
      }}
      style={responsive ? { border: 'none' } : {}}
      headStyle={responsive ? { display: 'none', padding: 0, margin: 0 } : {}}
      actions={
        addAnalise.length <= 0
          ? []
          : [
              <div>
                <b>Tamanho do grid amostral: </b> {(areaTalhao / malha.features?.length).toFixed(1)}
              </div>,
              <>
                <b>Reais por Ha: </b>{' '}
                {(totalFinalAnalise / areaTalhao).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </>,
              <>
                <b>Total de amostras: </b>
                {totalAnalises}
              </>,
              <>
                <b>Valor Total: </b>{' '}
                {totalFinalAnalise.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </>,
            ]
      }
    >
      {addAnalise.length <= 0 ? (
        <Empty
          style={{
            height: 'calc(100vh - 350px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        />
      ) : (
        addAnalise.map((analise, i) => {
          let valorAnalise
          if (analise.cod_analises[0]?.valor === undefined) {
            valorAnalise = analise.valor_analise.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          } else {
            valorAnalise = (
              analise.cod_analises[0].valor * addAnalise[i].pontos.length
            ).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          }

          return (
            <Card
              key={analise.cod}
              style={!responsive ? { marginTop: '10px' } : {}}
              title={`Análises: ${
                analise.cod_analises[0]?.nome === undefined
                  ? analise.title
                  : `${analise.cod_analises[0].nome} | ${analise.profundidade}`
              }`}
              headStyle={{ backgroundColor: '#f0f0f0' }}
              size='small'
              extra={
                <>
                  <Popconfirm
                    title='Duplicar Análise?'
                    onConfirm={() => handleClickDuplicar(i)}
                    cancelText='Não'
                    okText='Sim'
                    cancelButtonProps={{
                      type: 'primary',
                      danger: true,
                    }}
                  >
                    <Button
                      // disabled={disableCampos}
                      className='info-button iconbutton'
                      hidden={permissions?.inclusao === 0}
                      shape='circle'
                      size='small'
                      type='primary'
                    >
                      <Icons icon={faCopy} />
                    </Button>
                  </Popconfirm>
                  {'  '}
                  <Button
                    className='primary-button'
                    shape='circle'
                    onClick={() => handleClickVisuPontos(i, analise)}
                    size='small'
                    type='primary'
                  >
                    <Icons icon={faMapMarked} />
                  </Button>
                  {'  '}
                  <a style={{ display: 'none' }} href='empty' ref={url}>
                    ref
                  </a>
                  <Button
                    className='info-button'
                    title='Baixar KML'
                    shape='circle'
                    onClick={() =>
                      handleClickBaixarKml(
                        i,
                        analise.cod_analises[0]?.nome === undefined
                          ? analise.title
                          : analise.cod_analises[0].nome,
                        analise
                      )
                    }
                    size='small'
                    type='primary'
                  >
                    <Icons icon={solid('download')} />
                  </Button>
                  {'  '}
                  <Popconfirm
                    title='Remover Análise?'
                    icon={<IconAlert type='confirm' size={4} />}
                    cancelText='Não'
                    okText='Sim'
                    cancelButtonProps={{
                      type: 'primary',
                      danger: true,
                    }}
                    onConfirm={() => handleRemoveAnalise(analise.id)}
                  >
                    <Button
                      hidden={permissions?.exclusao === 0}
                      style={{ borderRadius: '50%' }}
                      // disabled={disableCampos}
                      shape='circle'
                      size='small'
                      type='primary'
                      danger
                    >
                      <Icons icon={solid('trash')} />
                    </Button>
                  </Popconfirm>
                </>
              }
            >
              <Row justify='start' gutter={[4, 4]}>
                <Form.List name={['analise', i]}>
                  {(pontos, { add, remove }) => {
                    return (
                      <>
                        <Form.Item hidden initialValue={analise.idAnalise} name='cod_tipo_analise'>
                          <Input type='hidden' />
                        </Form.Item>

                        {analise.pontos.map((data, index) => (
                          <Col key={data.cod} style={{ width: '48px' }}>
                            <Popover
                              content={
                                <>
                                  {/* @ts-ignore */}
                                  {checkMalhaPonto(parseInt(data.NOME, 10)) ? null : (
                                    <p>
                                      <Alert
                                        message='Ponto não existe na Malha Geral'
                                        type='warning'
                                        icon={<Icons icon={faExclamationTriangle} />}
                                        showIcon
                                      />
                                    </p>
                                  )}
                                  <Button
                                    disabled={permissions?.exclusao === 0}
                                    onClick={() => handleTrashPonto(data.INDICE, i, index)}
                                    size='small'
                                    shape='circle'
                                    type='primary'
                                    danger
                                  >
                                    <Icons icon={solid('trash')} />
                                  </Button>
                                </>
                              }
                              title={`Ponto: ${data.NOME}`}
                              trigger='hover'
                            >
                              <CardBtn>
                                <Card
                                  size='small'
                                  key={data.INDICE}
                                  className={
                                    // @ts-ignore
                                    checkMalhaPonto(parseInt(data.NOME, 10))
                                      ? 'info-button btn-ponto'
                                      : 'trash-button btn-ponto'
                                  }
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {parseInt(data.NOME, 10)}
                                </Card>
                              </CardBtn>
                              <Form.Item
                                name={['pontos', index, 'ponto']}
                                initialValue={parseInt(data.NOME, 10)}
                                hidden
                              >
                                <Input type='hidden' />
                              </Form.Item>
                            </Popover>
                          </Col>
                        ))}
                        <Col>
                          <Button
                            onClick={() => handleClickAddPonto(i)}
                            className='primary-button'
                            style={{ width: '44px', height: '32px' }}
                            icon={<Icons icon={solid('plus')} />}
                            size='small'
                            type='primary'
                            block
                          />
                        </Col>
                      </>
                    )
                  }}
                </Form.List>

                <Divider />
                <Col span={22}>
                  <p>
                    <b>Total de Amostras: </b> {addAnalise[i].pontos.length} de{' '}
                    {malha.features?.length} (
                    {((addAnalise[i].pontos.length * 100) / malha.features?.length).toFixed(2)}
                    %)
                  </p>

                  <p>
                    <b>Tamanho do grid amostral: </b>
                    {(areaTalhao / addAnalise[i].pontos.length).toFixed(1)}
                  </p>

                  <p>
                    Obs:{' '}
                    {analise?.cod_analises[0]?.cod === 5
                      ? propriedadeSelecionado.area
                      : areaAmostra.toFixed(2)}{' '}
                    ha para cada amostra
                  </p>

                  <p>
                    <b>Valor: </b>
                    {valorAnalise}
                  </p>
                </Col>
              </Row>
            </Card>
          )
        })
      )}
    </Card>
  )
}

export default AnalisesOrder
