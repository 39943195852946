/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */

interface TypesSvg {
  fill?: string
  stroke?: string
  size?: number
  disable?: boolean
}

interface TypesAlert {
  size?: number
  fill?: string
  disable?: boolean
}

export function SvgPolyline(props: TypesSvg) {
  const { fill = 'green', stroke = 'green' } = props
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='48px'
      height='48px'
      viewBox='0 0 48 48'
      enableBackground='new 0 0 48 48'
      xmlSpace='preserve'
    >
      <polyline
        fill='none'
        stroke={stroke}
        strokeWidth='3'
        strokeOpacity={0.2}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        points='
	8.547,39.453 14.121,28.266 25.404,29.619 19.832,11.407 32.35,15.56 39.453,8.547 '
      />
      <g>
        <circle fill={fill} cx='9' cy='39' r='3' />
        <circle fill={fill} cx='39' cy='9' r='3' />
        <circle fill={fill} cx='20' cy='11' r='3' />
        <circle fill={fill} cx='31' cy='15' r='3' />
        <circle fill={fill} cx='14' cy='29' r='3' />
        <circle fill={fill} cx='25' cy='29' r='3' />
      </g>
    </svg>
  )
}

export function IconSuccess(props: TypesAlert) {
  const { size = 42 } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${size}mm`}
      height={`${size}mm`}
      version='1.1'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 4233 4233'
    >
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <circle fill='#42D651' cx='2117' cy='2117' r='2117' />
        <path
          fill='white'
          d='M1698 3460c28,-53 57,-128 82,-186 27,-60 58,-122 89,-181 61,-116 124,-230 192,-343 131,-217 275,-445 427,-647 177,-237 516,-677 730,-884l267 -267 -77 -179c-48,16 -223,140 -276,177 -500,348 -1036,807 -1438,1264l-211 239 -746 -592 -328 265c17,30 275,288 322,338 105,111 214,221 321,332l243 251c25,26 54,60 81,84 87,78 266,287 322,329z'
        />
        <path
          fill='currentColor'
          fillOpacity='0.101961'
          d='M2117 2117l1496 -1497c827,827 827,2167 0,2993 -826,827 -2166,827 -2993,0l1497 -1496z'
        />
      </g>
    </svg>
  )
}

export function IconInfo(props: TypesAlert) {
  const { size = 42 } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${size}mm`}
      height={`${size}mm`}
      version='1.1'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 4233 4233'
    >
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <g id='_1722453393760'>
          <circle fill='#4B97E3' cx='2117' cy='2117' r='2117' />
          <g>
            <path
              fill='white'
              d='M1649 1937l-106 -87c163,-183 312,-313 446,-390 134,-78 249,-116 343,-116 52,0 92,12 119,35 28,23 42,55 42,95 0,47 -53,252 -158,613 -172,590 -258,946 -258,1067 0,25 6,44 18,61 11,16 23,24 36,24 52,0 186,-105 402,-316l96 97c-224,216 -388,355 -491,415 -103,60 -187,90 -252,90 -58,0 -103,-18 -136,-55 -34,-37 -51,-88 -51,-153 0,-208 123,-716 370,-1524 11,-39 17,-73 17,-102 0,-34 -15,-51 -44,-51 -33,0 -71,14 -112,40 -42,28 -136,113 -281,257z'
            />
            <path
              fill='white'
              d='M2516 708c51,0 93,17 125,50 33,34 49,78 49,131 0,72 -25,136 -75,191 -49,55 -104,83 -166,83 -49,0 -90,-17 -123,-52 -32,-35 -48,-80 -48,-136 0,-75 23,-138 70,-189 48,-52 104,-78 168,-78z'
            />
          </g>
          <path
            fill='currentColor'
            fillOpacity='0.101961'
            d='M2117 2117l1496 -1497c827,827 827,2167 0,2993 -826,827 -2166,827 -2993,0l1497 -1496z'
          />
        </g>
      </g>
    </svg>
  )
}

export function IconWarning(props: TypesAlert) {
  const { size = 42 } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${size}mm`}
      height={`${size}mm`}
      version='1.1'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 4233 4233'
    >
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <circle fill='#FFCC00' cx='2117' cy='2117' r='2117' />
        <g id='_1722548464816'>
          <path
            fill='#FFD42A'
            d='M752 3021c0,53 26,80 78,80l2575 0c73,0 93,-86 62,-146 -48,-92 -120,-207 -172,-307l-85 -154c-30,-51 -56,-100 -86,-153 -53,-91 -121,-224 -172,-307 -45,-76 -129,-231 -172,-310 -15,-28 -28,-51 -42,-76l-561 -1004c-18,-22 -61,-27 -84,-6l-132 229c-30,50 -57,103 -87,153l-521 918c-77,127 -190,330 -262,460 -28,51 -58,103 -87,153 -66,116 -157,269 -217,383 -15,26 -35,51 -35,87z'
          />
          <path
            fill='#4D4D4D'
            d='M853 3102c-204,2 -47,-203 2,-290l538 -946c120,-210 239,-421 359,-631 86,-152 181,-325 269,-473 22,-38 60,-135 108,-134 38,1 53,19 69,51 14,28 27,52 42,79 207,361 417,748 620,1112l532 953c55,98 187,280 -31,279 -768,-4 -1542,-2 -2309,-1l-199 1zm1241 -2620c-50,10 -94,36 -124,75 -18,25 -55,94 -70,124l-65 114 -74 125c-7,21 -56,113 -70,121 -2,18 -88,164 -104,187 -5,18 -59,111 -71,121 -1,12 -63,123 -71,127l-61 109 -109 191 -69 122 -102 178c-7,20 -57,113 -70,120 -2,13 -68,130 -76,136 -5,17 -46,93 -58,100 -3,23 -89,163 -107,196 -11,21 -26,47 -38,68 -14,23 -11,8 -21,23 -8,11 -8,20 -18,32l-62 116c-8,21 -19,40 -28,61 -6,16 -15,55 -22,65l0 61 14 48c19,60 70,112 130,130l53 12c73,7 677,2 766,2 511,0 1022,0 1533,0 82,0 308,10 373,-10 45,-14 82,-43 110,-81 39,-52 37,-90 46,-150 -9,-14 -8,-49 -14,-69 -13,-42 -43,-89 -65,-128l-65 -115c-16,-34 -101,-171 -103,-192 -15,-10 -59,-95 -66,-116 -10,-7 -66,-111 -68,-124 -16,-13 -61,-99 -70,-124l-107 -186 -52 -98 -77 -137 -69 -123c-15,-22 -97,-166 -99,-183 -15,-12 -64,-103 -71,-125 -9,-6 -65,-109 -67,-123 -17,-13 -57,-91 -67,-116l-102 -181 -68 -124 -70 -124c-9,-10 -19,-30 -26,-43 -13,-25 -11,-18 -29,-37 -29,-32 -60,-33 -82,-52l-82 -8c-8,6 0,4 -16,5z'
          />
          <g>
            <path
              fill='#4D4D4D'
              d='M2082 1214c-48,10 -94,30 -132,60 -53,42 -43,84 -38,143 10,117 20,234 30,351 10,117 21,235 30,352 4,54 2,106 41,150 32,37 89,53 136,43 130,-26 117,-155 124,-258l39 -532c3,-47 15,-136 12,-177 -7,-93 -161,-149 -242,-132z'
            />
            <path
              fill='#4D4D4D'
              d='M2322 2655c20,-110 -56,-214 -165,-233 -112,-19 -216,55 -236,165 -19,114 56,215 168,235 111,20 214,-57 233,-167z'
            />
          </g>
        </g>
        <path
          fill='currentColor'
          fillOpacity='0.101961'
          d='M2117 2117l1496 -1497c827,827 827,2167 0,2993 -826,827 -2166,827 -2993,0l1497 -1496z'
        />
      </g>
    </svg>
  )
}

export function IconConfirm(props: TypesAlert) {
  const { size = 42 } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${size}mm`}
      height={`${size}mm`}
      version='1.1'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 4233 4233'
    >
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <circle fill='#336699' cx='2117' cy='2117' r='2117' />
        <path
          fill='white'
          d='M2141 762c-387,0 -655,190 -774,387 -71,116 -19,164 22,190l171 119c56,41 119,37 160,-19 101,-137 224,-230 384,-230 204,0 320,130 320,283 0,320 -484,550 -484,926l0 82c0,45 33,78 74,78l328 0c41,0 74,-26 74,-74l0 -26c0,-287 521,-521 521,-1027 0,-350 -272,-689 -796,-689zm41 2709c178,0 327,-149 327,-327 0,-183 -149,-332 -327,-332 -183,0 -331,149 -331,332 0,178 148,327 331,327z'
        />
        <path
          fill='currentColor'
          fillOpacity='0.101961'
          d='M2117 2117l1496 -1497c827,827 827,2167 0,2993 -826,827 -2166,827 -2993,0l1497 -1496z'
        />
      </g>
    </svg>
  )
}

export function IconError(props: TypesAlert) {
  const { size = 42 } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${size}mm`}
      height={`${size}mm`}
      version='1.1'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 4233 4233'
    >
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <circle fill='#FF5757' cx='2117' cy='2117' r='2117' />
        <path
          fill='white'
          d='M2109 2542l393 619c23,35 42,67 105,67l495 0c80,0 99,-64 64,-118l-648 -1019 607 -968c35,-54 19,-118 -61,-118l-470 0c-63,0 -79,26 -101,61l-359 613 -371 -613c-23,-35 -38,-61 -102,-61l-492 0c-67,0 -102,61 -64,121l616 972 -654 1009c-35,48 -25,121 61,121l495 0c63,0 86,-29 108,-64l378 -622z'
        />
        <path
          fill='currentColor'
          fillOpacity='0.101961'
          d='M2117 2117l1496 -1497c827,827 827,2167 0,2993 -826,827 -2166,827 -2993,0l1497 -1496z'
        />
      </g>
    </svg>
  )
}

export function IconUser(props: TypesAlert) {
  const { size } = props

  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={`${size}mm`}
      height={`${size}mm`}
      viewBox='796 796 200 200'
      enableBackground='new 796 796 200 200'
      xmlSpace='preserve'
      fill='#336699'
    >
      <g>
        <path
          d='M896.003,827.425c-20.538,0-37.187,19.66-37.187,43.921c0,24.258,16.648,43.924,37.187,43.924s37.188-19.667,37.188-43.924
   C933.191,847.085,916.541,827.425,896.003,827.425z'
        />
        <path
          d='M896,796c-55.141,0-100,44.859-100,99.999C796.001,951.14,840.86,996,896,996c55.139,0,99.999-44.86,99.999-100.001
   C995.999,840.859,951.14,796,896,796z M962.014,953.885c-0.029-0.111-0.044-0.223-0.075-0.333
   c-4.735-16.523-15.472-30.494-29.687-39.455c-2.805-1.768-6.442-1.48-8.931,0.71c-7.63,6.719-17.069,10.72-27.319,10.72
   c-10.45,0-20.061-4.156-27.767-11.113c-2.46-2.222-6.082-2.556-8.91-0.829c-14.407,8.797-25.353,22.689-30.299,39.192
   c-13.012-15.325-20.887-35.145-20.887-56.777c0-48.446,39.414-87.86,87.86-87.86c48.445,0,87.859,39.414,87.859,87.86
   C983.859,918.159,975.597,938.412,962.014,953.885z'
        />
      </g>
    </svg>
  )
}

export function MarkerSvg(props: TypesAlert) {
  const { size } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 384 512'
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      width={`${size}mm`}
      height={`${size}mm`}
      enableBackground='new 796 796 200 200'
      xmlSpace='preserve'
      fill='#336699'
    >
      <path d='M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z' />
    </svg>
  )
}

export function ChartTreeMap(props: TypesAlert) {
  const { size } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 380 380'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='180' height='180' rx='10' fill='currentColor' />
      <rect y='200' width='180' height='180' rx='10' fill='currentColor' />
      <rect x='200' y='267' width='180' height='113' rx='10' fill='currentColor' />
      <rect x='200' y='134' width='180' height='113' rx='10' fill='currentColor' />
      <rect x='200' y='1' width='180' height='113' rx='10' fill='currentColor' />
    </svg>
  )
}

export function PolygonSvg(props: TypesAlert) {
  const { size } = props
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox='0 0 256 256'
      id='Flat'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M233.46,97.46a36,36,0,0,0-50.92-50.92h0a36.18,36.18,0,0,0-4.12,5l-22.54-6.14A36,36,0,0,0,94.54,22.54h0a36.07,36.07,0,0,0-7.79,39.24L57.2,88.38a36.06,36.06,0,0,0-42.66,6.16h0a36,36,0,0,0,45.73,55.2l65.27,47.87a36,36,0,1,0,59.92-15.07,37.69,37.69,0,0,0-2.85-2.55L208,108A35.87,35.87,0,0,0,233.46,97.46Zm-93.73,80.8L74.46,130.39a36.19,36.19,0,0,0-1.21-24.17l29.55-26.6a35.92,35.92,0,0,0,46.78-11.11l22.54,6.14a35.88,35.88,0,0,0,10.42,22.81,37.69,37.69,0,0,0,2.85,2.55L160,172A35.89,35.89,0,0,0,139.73,178.26ZM216.49,63.51a12,12,0,1,1-17,0h0A12,12,0,0,1,216.49,63.51Zm-105-24a12,12,0,1,1,0,17,12,12,0,0,1,0-17Zm-80,89a12,12,0,0,1,0-17h0a12,12,0,1,1,0,17Zm137,88a12,12,0,0,1-17-17h0a12,12,0,0,1,17,17Z' />
    </svg>
  )
}

export function PolySubStuff(props: TypesAlert) {
  const { size } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 797 810'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_105_26)'>
        <path d='M469.99 651.843L250.446 655.384' stroke='currentColor' strokeWidth='30' />
        <path d='M286.132 177.209L113.252 328.898' stroke='currentColor' strokeWidth='30' />
        <path d='M552.451 158.766L333.689 158.787' stroke='currentColor' strokeWidth='30' />
        <path d='M532.466 627.642L677.07 480.472' stroke='currentColor' strokeWidth='30' />
        <path d='M121.206 382.654L208.259 607.382' stroke='currentColor' strokeWidth='30' />
        <path d='M595.84 198.796L682.893 423.524' stroke='currentColor' strokeWidth='30' />
        <path
          d='M608.492 145.636C615.475 163.661 606.523 183.934 588.498 190.916C570.473 197.898 550.201 188.946 543.218 170.921C536.236 152.896 545.188 132.624 563.213 125.642C581.238 118.66 601.51 127.612 608.492 145.636Z'
          stroke='currentColor'
          strokeWidth='30'
        />
        <path
          d='M337.385 143.414C344.367 161.439 335.416 181.711 317.391 188.693C299.366 195.676 279.094 186.724 272.111 168.699C265.129 150.674 274.081 130.402 292.106 123.42C310.131 116.437 330.403 125.389 337.385 143.414Z'
          stroke='currentColor'
          strokeWidth='30'
        />
        <path
          d='M139.277 343.482C146.259 361.506 137.307 381.779 119.282 388.761C101.257 395.743 80.9851 386.791 74.0028 368.767C67.0206 350.742 75.9724 330.469 93.9973 323.487C112.022 316.505 132.294 325.457 139.277 343.482Z'
          stroke='currentColor'
          strokeWidth='30'
        />
        <path
          d='M720.83 435.639C727.812 453.663 718.86 473.936 700.835 480.918C682.811 487.9 662.538 478.948 655.556 460.924C648.574 442.899 657.526 422.626 675.55 415.644C693.575 408.662 713.848 417.614 720.83 435.639Z'
          stroke='currentColor'
          strokeWidth='30'
        />
        <path
          d='M525.939 630.17C532.921 648.195 523.969 668.467 505.944 675.45C487.92 682.432 467.647 673.48 460.665 655.455C453.683 637.43 462.635 617.158 480.659 610.176C498.684 603.193 518.957 612.145 525.939 630.17Z'
          stroke='currentColor'
          strokeWidth='30'
        />
        <path
          d='M256.428 634.836C263.41 652.861 254.458 673.134 236.433 680.116C218.408 687.098 198.136 678.146 191.154 660.121C184.172 642.096 193.124 621.824 211.148 614.842C229.173 607.86 249.446 616.811 256.428 634.836Z'
          stroke='currentColor'
          strokeWidth='30'
        />
        <path
          d='M139.277 343.482C146.259 361.506 137.307 381.779 119.282 388.761C101.257 395.743 80.9851 386.791 74.0028 368.767C67.0206 350.742 75.9724 330.469 93.9973 323.487C112.022 316.505 132.294 325.457 139.277 343.482Z'
          stroke='currentColor'
          strokeWidth='30'
        />
        <path
          d='M307.855 331.932L205.396 422.879L290.368 518.606L392.827 427.66L307.855 331.932Z'
          fill='currentColor'
        />
        <path
          d='M500.126 197.467L375.414 254.178L428.399 370.696L553.111 313.986L500.126 197.467Z'
          fill='currentColor'
        />
        <path
          d='M592.705 398.118L455.807 403.406L460.749 531.311L597.646 526.022L592.705 398.118Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_105_26'>
          <rect
            width='609'
            height='632'
            fill='white'
            transform='translate(0.415283 220.325) rotate(-21.1748)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export function SvgPdf(props: TypesAlert) {
  const { fill = '#FB3449' } = props
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 303.188 303.188'
      xmlSpace='preserve'
      height='30px'
    >
      <g>
        <polygon
          style={{ fill: '#E8E8E8' }}
          points='219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	'
        />
        <path
          style={{ fill }}
          d='M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
   c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
   c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
   c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
   c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
   c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
   c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
   c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
   c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
   c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
   c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
   C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
   c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
   c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
   c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
   c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
   c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
   c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
   c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
   c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
    M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
   c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
   c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z'
        />
        <polygon style={{ fill }} points='227.64,25.263 32.842,25.263 32.842,0 219.821,0 	' />
        <g>
          <path
            style={{ fill: '#A4A9AD' }}
            d='M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
     v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
      M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
     h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z'
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d='M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
     h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
     c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
     C160.841,257.523,161.76,254.028,161.76,249.324z'
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d='M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
     L196.579,273.871L196.579,273.871z'
          />
        </g>
        <polygon style={{ fill: '#A4A9AD' }} points='219.821,50.525 270.346,50.525 219.821,0 	' />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  )
}

export function SvgExcel(props: TypesAlert) {
  const { disable = false } = props
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='35px' height='35px'>
      <rect width='16' height='9' x='28' y='15' fill={disable ? '#666666' : '#21a366'} />
      <path fill='#185c37' d='M44,24H12v16c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V24z' />
      <rect width='16' height='9' x='28' y='24' fill={disable ? '#858585' : '#107c42'} />
      <rect width='16' height='9' x='12' y='15' fill={disable ? '#666666' : '#3fa071'} />
      <path fill={disable ? '#858585' : '#33c481'} d='M42,6H28v9h16V8C44,6.895,43.105,6,42,6z' />
      <path fill={disable ? '#666666' : '#21a366'} d='M14,6h14v9H12V8C12,6.895,12.895,6,14,6z' />
      <path
        d='M22.319,13H12v24h10.319C24.352,37,26,35.352,26,33.319V16.681C26,14.648,24.352,13,22.319,13z'
        opacity='.05'
      />
      <path
        d='M22.213,36H12V13.333h10.213c1.724,0,3.121,1.397,3.121,3.121v16.425	C25.333,34.603,23.936,36,22.213,36z'
        opacity='.07'
      />
      <path
        d='M22.106,35H12V13.667h10.106c1.414,0,2.56,1.146,2.56,2.56V32.44C24.667,33.854,23.52,35,22.106,35z'
        opacity='.09'
      />
      <linearGradient
        id='flEJnwg7q~uKUdkX0KCyBa'
        x1='4.725'
        x2='23.055'
        y1='14.725'
        y2='33.055'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0' stopColor={disable ? '#858585' : '#18884f'} />
        <stop offset='1' stopColor={disable ? '#666666' : '#0b6731'} />
      </linearGradient>
      <path
        fill='url(#flEJnwg7q~uKUdkX0KCyBa)'
        d='M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16	C24,33.105,23.105,34,22,34z'
      />
      <path
        fill='#fff'
        d='M9.807,19h2.386l1.936,3.754L16.175,19h2.229l-3.071,5l3.141,5h-2.351l-2.11-3.93L11.912,29H9.526	l3.193-5.018L9.807,19z'
      />
    </svg>
  )
}
