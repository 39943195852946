import React, { useEffect, useState } from 'react'
import ViewPage from '../../../Components/ViewPage'

import { Button, Space, Table } from 'antd'
import { ItensSharedFilesUser, getSharedFilesUser } from '../../../services/SharedFiles'
import { Container } from './styles'

import Icon, { ReloadOutlined } from '@ant-design/icons'
import { faDownLong } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import { pagination } from '../../../Components/Default'
import Icons from '../../../Components/Icons'
import { tryError } from '../../../services/Afins'
import { ItensFilesUser, getFilesUser } from '../../../services/SharedFiles/users'
import { api } from '../../../services/api'

const SharedFiles: React.FC = () => {
  const [sharedFiles, setSharedFiles] = useState<ItensSharedFilesUser[]>([])
  const [users, setUsers] = useState<ItensFilesUser[]>([])
  const [loading, setLoading] = useState(false)

  const listSharedFiles = async () => {
    setLoading(true)
    const resp = await getSharedFilesUser({ pagination: -1 })

    setSharedFiles(resp.data.itens)
    setLoading(false)
  }

  const listUsers = async () => {
    const resp = await getFilesUser({ ordem: 'create_time', pagination: -1 })
    setUsers(resp.data.itens)
  }

  const handleDownload = async (cod: number, fileName) => {
    try {
      const resp = await api.get(`/compartilhamento-arquivo/${cod}/visualizar-arquivo`, {
        responseType: 'blob',
      })

      const href = URL.createObjectURL(resp.data)

      const link = document.createElement('a')

      link.href = href

      link.download = fileName

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)

      URL.revokeObjectURL(href)
    } catch (error) {
      tryError(error)
    }
  }

  useEffect(() => {
    listUsers()
    listSharedFiles()
  }, [])

  return (
    <Container>
      <ViewPage
        newHide
        title='Arquivos compartilhados'
        btnClick={() => {}}
        btnsExta={
          <Button
            type='primary'
            onClick={listSharedFiles}
            loading={loading}
            icon={<ReloadOutlined />}
          >
            Atualizar
          </Button>
        }
        content={<></>}
      >
        <Table
          loading={loading}
          dataSource={orderBy(
            sharedFiles,
            (data) => {
              return dayjs(data.create_time).unix()
            },
            'desc'
          )}
          size='small'
          pagination={pagination}
          scroll={{ y: 'calc(100vh - 370px)' }}
          bordered
          columns={[
            {
              title: 'Data',
              sorter: (data) => dayjs(data.create_time).unix(),
              dataIndex: 'create_time',
              render: (data: number) => dayjs(data).format('DD/MM/YYYY HH:mm:ss'),
            },
            {
              title: 'Usuário',
              dataIndex: 'usuario_nome',
              render: (data) => data,
            },
            { title: 'Descrição', dataIndex: 'descricao' },
            { title: 'Arquivo', dataIndex: 'nome' },
            {
              title: 'Download',
              dataIndex: 'cod',
              width: 90,
              align: 'center',
              render: (cod: number, record) => (
                <Space>
                  <Button
                    shape='circle'
                    type='default'
                    onClick={() => handleDownload(cod, record.arquivo)}
                    icon={<Icon component={() => <Icons icon={faDownLong} />} />}
                  />
                </Space>
              ),
            },
          ]}
        />
      </ViewPage>
    </Container>
  )
}

export default SharedFiles
