import { useState } from "react";
import { faPagelines } from "@fortawesome/free-brands-svg-icons";
import { Button, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAtom, faBoxesPacking, faDna, faEye, faFlaskVial, faPlus, faSeedling, faVial, faViruses } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { CompatibilidadesAtivos } from "./styled";

//APP
import { useScreenSize } from '../../../../utils/tsx';
import { gerarKey, removerAcentos } from "../../../../utils";
import { obterLarguraTabResponsiva, obterMensagemLoading, obterPlaceholder, obterTipoLancamento, obterTituloColuna } from "./meta";
import { useGetNomeRecomendacaoAtivosCadastrados } from "../../../../services/recomendacao/ativos/novo-index";

//COMPONENTS
import Tabs from "../../../../Components/Tabs";
import Input from "../../../../Components/CustomUi/input";
import Carregando from "../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../Components/ButtonCustom";
import PageContainer from "../../../../Components/pageContainer";
import { Box } from "../../../../Components/CustomUi/box";
import { Inline } from "../../../../Components/CustomUi/inline";
import { TitleH1 } from "../../../../Components/CustomUi/titleH1";
import { TitleH2 } from "../../../../Components/CustomUi/titleH2";
import { RowItem } from "../../../../Components/CustomUi/table/rowItem";
import { RowTable } from "../../../../Components/CustomUi/table/rowTable";
import { BodyTable } from "../../../../Components/CustomUi/table/bodyTable";
import { HeaderTable } from "../../../../Components/CustomUi/table/headerTable";
import { ErrorDisplay } from "../../../../Components/CustomUi/error";
import { GavetaCadastrar } from "./components/gavetaCadastrar";
import { GavetaVisualizar } from "./components/gavetaVisualizar";

export const NovoCompatibilidadeAtivo: React.FC = () => {

    //STATES
    const [search, setSearch] = useState<string>('');
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [openDrawerVer, setOpenDrawerVer] = useState<boolean>(false);
    const [id_ativo_selecionado, setId_ativo_selecionado] = useState<number | null>(null);
    const [nome_ativo_selecionado, setNome_ativo_selecionado] = useState<string>('');
    const [tab_selecionada, setTab_selecionada] = useState<string>('Insumos');
    const [apiError, setApiError] = useState<boolean>(false);
    const [is_herbicida, setIs_herbicida] = useState<boolean>(true);

    //API
    const { data_ativos, carregando_ativos, refetch_data_ativos } = useGetNomeRecomendacaoAtivosCadastrados(obterTipoLancamento(tab_selecionada), setApiError);

    function encontrarAtivo(buscar_nome: string) {
        const ativoSemAcentos = removerAcentos(buscar_nome);
        return function(item) {
            const nomeSemAcentos = removerAcentos(String(item?.['item_lancamento_nome']));
            return nomeSemAcentos.toLowerCase().includes(ativoSemAcentos.toLowerCase()) || !buscar_nome;
        }
    }

    const { currentWidth } = useScreenSize({});

    return (
        <CompatibilidadesAtivos>
            <PageContainer>

                <TitleH1>Incompatibilidades</TitleH1>

                <Box padding={15} gap={10}>

                    {/* <TitleH2>Itens cadastrados</TitleH2> */}

                    <Tabs
                        tabs={[
                            {descricao: 'Insumos', icone: faBoxesPacking, width: obterLarguraTabResponsiva('Insumos', currentWidth, 125)},
                            {descricao: 'Ativos', icone: faVial, width: obterLarguraTabResponsiva('Ativos', currentWidth, 125)},
                            {descricao: 'Grupo Químico', icone: faFlaskVial, width: obterLarguraTabResponsiva('Grupo Químico', currentWidth, 170)},
                            {descricao: 'Formulação', icone: faAtom, width: obterLarguraTabResponsiva('Formulação', currentWidth, 150)},
                            {descricao: 'Cultura', icone: faPagelines, width: obterLarguraTabResponsiva('Cultura', currentWidth, 125)},
                            {descricao: 'Classe Agronômica', icone: faSeedling, width: obterLarguraTabResponsiva('Classe Agronômica', currentWidth, 200)},
                            {descricao: 'Biotecnologia', icone: faDna, width: obterLarguraTabResponsiva('Biotecnologia', currentWidth, 170)},
                            {descricao: 'Grupo', icone: faViruses, width: obterLarguraTabResponsiva('Grupo', currentWidth, 125)},
                        ]}
                        selecionado={tab_selecionada}
                        onChange={setTab_selecionada}
                        trackWidth={148}
                        useTabWidth
                        apenasIcones={currentWidth < 1260}
                    />

                    {currentWidth < 1260 &&(
                        <TitleH2>
                            {tab_selecionada}
                        </TitleH2>
                    )}

                    <Inline>

                        <Input 
                            value={search}
                            onChange={setSearch}
                            placeholder={`Buscar ${obterPlaceholder(tab_selecionada)}`}
                        />
                        <Button 
                            icon={<FontAwesomeIcon icon={faPlus} />} 
                            style={{marginTop: 4}} 
                            type="primary"
                            onClick={()=>{setOpenDrawer(true)}}
                        >
                            Cadastrar
                        </Button>
                    </Inline>

                    <HeaderTable 
                        itens={[
                            {valor: obterTituloColuna(tab_selecionada), width: 'calc(100% - 150px)'},
                            {valor: 'Opções', width: 150}
                        ]}
                    />
                    <BodyTable style={{marginTop: -10, height: 'calc(100vh - 345px)'}}>

                    {apiError ? 
                        <ErrorDisplay
                            loading={carregando_ativos}
                            tentarNovamente={refetch_data_ativos}
                        />
                    :
                    <>
                        {carregando_ativos ?
                            <Carregando
                                legenda={`Carregando lista de ${obterMensagemLoading(tab_selecionada)}`}
                                justifyContent="center"
                                animation
                                height={150}
                            />
                        :<>
                            {(data_ativos?.itens ?? []).filter(encontrarAtivo(search))?.length === 0 
                            ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={search?.length > 0 ? <span><b>"{search}"</b> não encontrado</span> : `Nenhum(a) ${obterPlaceholder(tab_selecionada)} cadastrado`} />
                            : <>
                                {(data_ativos?.itens ?? []).filter(encontrarAtivo(search)).map((item, index)=> {
                                    return (
                                        <RowTable key={gerarKey(index)} onDoubleClick={()=>{setId_ativo_selecionado(item?.item_lancamento);setNome_ativo_selecionado(item?.item_lancamento_nome);setOpenDrawerVer(true)}}>
                                            <RowItem width={(data_ativos?.itens ?? []).length > 7 ? 'calc(100% - 150px)' : 'calc(100% - 140px)'}>
                                                {item?.item_lancamento_nome}
                                            </RowItem>
                                            <RowItem width={150} gap={10}>
                                                <ButtonCustom
                                                    onClick={()=>{
                                                        setIs_herbicida(item?.item_lancamento_cod_classe_agronomica === 144)
                                                        setId_ativo_selecionado(item?.item_lancamento);
                                                        setNome_ativo_selecionado(item?.item_lancamento_nome);
                                                        setOpenDrawerVer(true)}
                                                    }
                                                    icon={<FontAwesomeIcon icon={faEye} />}
                                                    type="secondary"
                                                >
                                                    Ver / Editar
                                                </ButtonCustom>
                                            </RowItem>
                                        </RowTable>
                                    )
                                })}
                            </>
                            }
                            
                        </>
                        }
                    </>
                    }

                        
                        
                    </BodyTable>
                
                </Box>

            </PageContainer>

            <GavetaCadastrar 
                open={openDrawer}
                onClose={setOpenDrawer}
                atualizarListaPrincipal={refetch_data_ativos}
                id_inicial={obterTipoLancamento(tab_selecionada)}
                nome_inicial={tab_selecionada}
            />

            <GavetaVisualizar 
                open={openDrawerVer}
                onClose={setOpenDrawerVer}
                id_ativo_selecionado={id_ativo_selecionado}
                nome_ativo_selecionado={nome_ativo_selecionado}
                atualizarListaPrincipal={refetch_data_ativos}
                id_tipo_principal={obterTipoLancamento(tab_selecionada)}
                is_herbicida={is_herbicida}
            />

        </CompatibilidadesAtivos>
    )
}