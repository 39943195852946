import { Card, Col, Row } from 'antd'
import dayjs from 'dayjs'
import dayJsPT from 'dayjs/locale/pt-br'
import localizeFormat from 'dayjs/plugin/localizedFormat'

import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { useLogic } from '../../context/useLogic'
import { TypesForecastList } from '../../services/apiClima'

import { Container } from './styled'

const WeatherForecast: React.FC = () => {
  const { dataClima, responsive } = useLogic()
  dayjs.locale(dayJsPT)
  dayjs.extend(localizeFormat)

  const [previView, setpreviView] = useState<TypesForecastList[]>([])

  const [loadingTemp, setLoadingTemp] = useState(true)

  const handlePrevisao = async () => {
    setLoadingTemp(true)

    const dados = dataClima

    let previ = []
    let dia = 0

    for (let i = 0; i < dados.list.length; i += 1) {
      const data = dados.list[i]

      const dataDia = dayjs(data.dt_txt).format('LL')
      const diaSemana = dayjs(data.dt_txt).format('dddd')
      const tempDia = Number(dayjs(data.dt_txt).format('DD'))

      data.dataDia = dataDia
      data.semana = diaSemana

      if (dia === tempDia) {
        previ = [...previ, [data]]
      } else if (dia === 0 || dia < tempDia) {
        previ = [...previ, data]
        dia = Number(dayjs(data.dt_txt).format('DD'))
      }
    }

    const filtro = previ
    previ = []
    filtro.forEach((data) => {
      if (data.dt_txt) {
        previ.push(data)
      }

      return previ
    })

    setpreviView(previ)
    setLoadingTemp(false)
  }

  useEffect(() => {
    if (dataClima?.list?.length > 0) {
      handlePrevisao()
    }
  }, [dataClima])

  return (
    <Container responsive={responsive}>
      <Card loading={loadingTemp}>
        {previView.map((data, index) =>
          index === 0 ? (
            <>
              <div key={uuid()} className='card-temp'>
                <div key={uuid()} className='card-temp-city'>
                  <b>
                    {dataClima?.city?.name}/{dataClima?.city?.uf}
                  </b>
                  <br />
                  <small>{`${dayjs().format('dddd')} ${dayjs().format('LL')}`}</small>
                </div>
                <div key={uuid()} className='card-temp-grau'>
                  <b>{`${data?.main.temp.toFixed(0)}º`}</b>
                  {'  '}
                  <b style={{ color: '#d2d2d2' }}>{`${data?.main.temp_min.toFixed(0)}º`}</b>
                </div>
              </div>
              <div key={uuid()} style={{ width: '100%' }}>
                <table key={uuid()} style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td style={{ maxWidth: '50px', textAlign: 'center' }}>
                        <img
                          src={`http://openweathermap.org/img/wn/${data?.weather[0]?.icon}@2x.png`}
                          alt={data?.weather[0].description}
                        />
                        <h4 className='capitalized'>{data?.weather[0].description}</h4>
                      </td>
                      <td>
                        <b>
                          Umidade <Icon icon='droplet' />
                        </b>
                        <h4>{`${data?.main.humidity}%`}</h4>
                      </td>
                      <td>
                        <b>
                          Chuva <Icon icon='cloud-showers-heavy' />
                        </b>

                        <h4>{`${data?.rain === undefined ? '0.0mm' : `${data?.rain['3h']}mm`}`}</h4>
                      </td>
                      <td>
                        <b>
                          Vento <Icon icon='wind' />
                        </b>
                        <h4>{`${data.wind.speed} km/h`}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : null
        )}
        <Col span={24}>
          <Row style={{ justifyContent: 'center' }} gutter={[8, 8]}>
            {previView.map((data, index) => (
              <>
                {index > 0 ? (
                  <Col key={String(index)}>
                    <Card key={String(index)} style={{ textAlign: 'center' }}>
                      <h5 className='capitalized'>{`${data.semana.substring(0, 3)}.`}</h5>
                      <img
                        title={data?.weather[0].description}
                        src={`http://openweathermap.org/img/wn/${data?.weather[0]?.icon.substring(
                          2,
                          0
                        )}d.png`}
                        alt={data?.weather[0].description}
                      />
                      <div>
                        <span>{`${data?.main.temp.toFixed(0)}º`}</span>
                        {'  '}
                        <span style={{ color: '#d2d2d2' }}>{`${data?.main.temp_min.toFixed(
                          0
                        )}º`}</span>
                      </div>
                    </Card>
                  </Col>
                ) : null}
              </>
            ))}
          </Row>
        </Col>
      </Card>
    </Container>
  )
}

export default WeatherForecast
