import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faMagnifyingGlass, faWarning, faXmark } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { InputWrapper } from "./styled";

//APP
import { removerEspacos } from "../../../utils";

//PROPS
interface Props {
    label?: string;
    placeholder: string;
    value: string;
    onChange: React.Dispatch<React.SetStateAction<string>>
    icon?: IconDefinition;
    obrigatorio?: boolean;
    error?: boolean;
    errorMessage?: string;
    desabilitado?: boolean;
    width?: number | string;
}

const Input: React.FC<Props> = ({ label, placeholder, value, onChange, icon, obrigatorio, error, errorMessage, desabilitado, width }) => {
    return (
        <InputWrapper style={{width: width}} data-label={!!label}>

            {!!label &&(
                <label htmlFor={removerEspacos(placeholder)}>{label} {obrigatorio? <span className="InputWrapper__obrigatorio">(obrigatório)</span> : null } </label>
            )}

            <div className="InputWrapper__campo" data-error={error} data-disabled={desabilitado}>

                <FontAwesomeIcon icon={(error ? faWarning :  icon) ?? faMagnifyingGlass} className="InputWrapper__campo__icon" />

                <input 
                    id={removerEspacos(placeholder)}
                    className="InputWrapper__campo__input"
                    placeholder={placeholder}
                    value={value}
                    onChange={(e)=>{onChange(e.currentTarget.value)}}
                    autoComplete="off"
                    disabled={desabilitado}
                />

                <button 
                    className="InputWrapper__campo__clear" 
                    type="reset" 
                    title="Limpar campo"
                    onClick={()=>onChange('')}
                    tabIndex={-1}
                >
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            {error &&(
                <span className="InputWrapper__error">
                    {errorMessage}
                </span>
            )}
            
        </InputWrapper>
    )
}

export default Input;