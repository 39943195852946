import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EditOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Descriptions, Popover, Upload, Typography, Modal } from 'antd';

//APP
import { useLogic, Itens } from '../../context/useLogic';

//STYLE
import { Container, PopoverOpcoes } from './styled';

//ASSETS
import cley from '../../assets/img/cley.jpg';

//COMPONENTS
const { Paragraph } = Typography;

//TYPE
type TypeOpcoes = {
    id: number;
    icone: React.ReactNode;
    descricao: string;
    onClick: Function;
}

const DropUser: React.FC = () => {

    //CONST
    let history = useHistory();
    const { itens } = useLogic();
    const opcoes_usuario: TypeOpcoes[] = [
        { id: 0, icone: <EditOutlined />, descricao: 'Editar Perfil', onClick: () => setVisibleEditPerfil(true) },
        { id: 1, icone: <SettingOutlined />, descricao: 'Configurações', onClick: () => { } },
        { id: 2, icone: <LogoutOutlined />, descricao: 'Sair', onClick: () => { history.push('/logout') } }
    ];

    //STATES
    const [userLogado, setUserLogado] = useState<Itens>({} as Itens)
    const [visibleEditPerfil, setVisibleEditPerfil] = useState(false)

    useEffect(() => {
        if (itens[0]) {
            itens[0].nome = itens[0]?.nome?.toLowerCase()

            if (itens[0].email === 'cley@vegatecnologia.com.br') {
                itens[0].doc_foto_perfil = cley
            } else {
                itens[0].doc_foto_perfil = null
            }

            setUserLogado(itens[0])
        }
    }, [])

    return (
        <Container>
            <Popover
                placement='bottomRight'
                trigger='click'
                content={
                    <PopoverOpcoes>
                        <div className='PopoverOpcoes__logado'>
                            <Avatar src={userLogado.doc_foto_perfil} style={{ background: 'var(--primary-color)', color: 'white', fontWeight: 500, fontSize: 18 }}>
                                {String(userLogado.nome).substring(0, 1).toLocaleUpperCase()}
                            </Avatar>
                            <p className='PopoverOpcoes__logado__nome'>
                                {String(userLogado.nome)}
                            </p>
                        </div>

                        <ul className='PopoverOpcoes__menu'>
                            {opcoes_usuario.map(({ id, descricao, icone, onClick }) => {
                                return (
                                    <li key={id} className='PopoverOpcoes__menu__opt'>
                                        <button className='PopoverOpcoes__menu__opt__btn' id={`menu_opt_${id}`} onClick={() => { onClick() }}>
                                            {icone}
                                            {descricao}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </PopoverOpcoes>
                }
            >

                <button className='btn-avatar-usuario' title={`Logado como ${String(userLogado.nome)}`}>
                    {userLogado.doc_foto_perfil
                        ? <img src={userLogado.doc_foto_perfil} alt='' className='btn-avatar-usuario__img' />
                        : <>{String(userLogado.nome).substring(0, 2)}</>
                    }

                </button>
            </Popover>

            <Modal open={visibleEditPerfil} onCancel={() => setVisibleEditPerfil(false)} title='Perfil'>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Upload>
                        <Avatar
                            draggable
                            src={userLogado.doc_foto_perfil}
                            size='large'
                            style={{
                                width: '80px',
                                height: '80px',
                                textAlign: 'center',
                                paddingTop: userLogado.doc_foto_perfil === null ? '15px' : null,
                                cursor: 'pointer',
                            }}
                        >
                            <span style={{fontSize: '60px', top: '16px'}}>
                                {String(userLogado.nome).substring(0, 1).toLocaleUpperCase()}
                            </span>
                        </Avatar>
                    </Upload>
                    <Descriptions style={{ width: '100%' }} column={1} size='small' title='Dados do usuario'>
                        <Descriptions.Item className='capitalized' label='Nome'>
                            <Paragraph style={{ width: '100%', textAlign: 'left' }} editable>
                                {String(userLogado.nome)}
                            </Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label='Email'>
                            <Paragraph>{String(userLogado.email)}</Paragraph>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </Modal>
        </Container>
    )
}

export default DropUser
