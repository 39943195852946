import styled from 'styled-components'

export const Container = styled.div`
  .ant-layout-content {
    overflow: hidden;
  }

  .ant-card-body {
    margin-bottom: -15px;
  }
  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 90%;
  }

  .ant-upload.ant-upload-select-picture-card,
  .ant-upload-list-picture-card-container {
    width: 100%;
    max-width: 200px;
    min-height: 200px;
  }

  .ant-drawer-header {
    padding: 0 !important;
  }
  .ant-drawer-body {
    padding: 5px;
    overflow: hidden;
  }

  .select-drop {
    font-size: 20px !important;
  }

  .ant-upload,
  .ant-upload-list-item,
  .ant-upload-list-item-done {
    width: 200px !important;
    height: 200px !important;
  }

  .upload-ico .ant-upload,
  .upload-ico .ant-upload-list-item,
  .upload-ico .ant-upload-list-item-done {
    width: 32px !important;
    height: 32px !important;
  }

  .log-user{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    /* height: 32px; */
    /* margin-top: -25px; */
    /* background-color: rebeccapurple; */
}


`

export const TabContentME = styled.div<{ responsive: boolean }>`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  width: 95%;

  .inputs-avatar {
    gap: ${(p) => (p.responsive ? '8px' : '40px')};
    display: flex;
    flex-direction: ${(p) => (p.responsive ? 'column' : 'row')};
    div {
      width: 100%;
    }
    .inline {
      display: flex;
      /* flex-direction: ${(p) => (p.responsive ? 'column' : 'row')}; */
      align-content: flex-start;
      flex-wrap: wrap;
      div {
        min-width: 150px;
        max-width: 200px;
      }
      gap: 8px;
    }
    .avatar-member-equipe {
      width: ${(p) => (p.responsive ? '100%' : '200px')};
      height: 200px;
   
    }
  }


  .end-comp {
    width: 100%;
    div {
      width: 100%;
    }
    gap: 8px;
    display: flex;
    flex-direction: ${(p) => (p.responsive ? 'column' : 'row')};
  }

  .end {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    .uf {
      width: 62px;
    }
    .cidade {
      width: 192px;
    }
    .cep {
      width: 92px;
    }
    .nacionalidade {
      width: 167px;
    }
    .escolaridade {
      width: 192x;
    }
  }





`
