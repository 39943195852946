import styled from 'styled-components';

//ASSETS
import Logo from '../../../../assets/img/logo.png'
import LogoSM from '../../../../assets/img/logo-sm.png'
import LogoTest from '../../../../assets/img/teste_logo.png'
import LogoSMTest from '../../../../assets/img/teste_logo_sm.png';

//STYLES 
const Container = styled.div`
    width: 100%;
    height: 82px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    /* box-shadow: 0px 0px 11px 0px rgba(85, 150, 115,.75); */

    :is([data-collapsed = true]) {
        height: 60px;
    }

    img {
        width: 68%;
        /* filter: drop-shadow(1px 1px 1px white) */
    }
    img:is([data-env='teste']) {
        width: 80%;
    }
`

//PROPS 
interface Props {
    collapsed: boolean;
}

export const LogoSideBar:React.FC<Props> = ({ collapsed }) => {

    const url: string = window.location.origin;
    const isTeste = (url === 'https://test.ttivos.app') || (url === 'http://localhost:3000');

    return (
        <Container data-collapsed={collapsed}>
            {isTeste ? (
                <img
                    // className={collapsed ? 'logo-sm-test' : 'logo-test'}
                    data-env={'teste'}
                    src={collapsed ? LogoSMTest : LogoTest}
                    alt='Logo SysAgro'
                    draggable={false}
                />
            ) : (
                <img
                    // className={collapsed ? 'logo-sm' : 'logo'}
                    src={collapsed ? LogoSM : Logo}
                    alt='Logo Ttivos'
                    draggable={false}
                />
            )}
        </Container>
    )
}