
//STYLE
import { TextAreaWrapper } from "./styled";

//PROPS
interface Props {
    label?: string;
    placeholder?: string;
    value: string;
    onChange: React.Dispatch<React.SetStateAction<string>>;
    height?: number | string;
    maxLength?: number;
    maxHeight?: number | string;
    resize?: boolean;
    disabled?: boolean;
    onKeyUp?: Function;
}

const TextArea: React.FC<Props> = ({ label, value, onChange, height, maxLength, maxHeight, 
    placeholder, resize, disabled, onKeyUp  }) => {

    const decodeHTMLEntities = (text) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    };

    return (
        <TextAreaWrapper>
            {!!label &&(
                <label className="TextAreaWrapper__label">
                    {label}
                </label>
            )}

            <textarea 
                className="TextAreaWrapper__textarea"
                placeholder={placeholder}
                value={decodeHTMLEntities(value)}
                onChange={(e)=>onChange(e.currentTarget.value)}
                maxLength={maxLength}
                style={{
                    height: height,
                    maxHeight: maxHeight,
                    resize: resize===false ? 'none' : undefined
                }}
                disabled={disabled}
                onKeyUp={()=>{onKeyUp?.()}}
            />
            

        </TextAreaWrapper>
    )
};


export default TextArea;