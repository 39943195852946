import React, { useEffect, useRef, useState } from 'react'

import { DownOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Dropdown,
  Form,
  Input,
  InputRef,
  Menu,
  message,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd'
import { AxiosResponse } from 'axios'
import {
  getPadraoMonitoramento,
  postPadraoMonitoramento,
  putPadraoMonitoramento,
  TypePadroesMonito,
} from '../../../services/monitoramento/padraoesmonitoramento'
import { Notification } from '../../notification'

import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import {
  getMoniNivelControle,
  MoniNivelControleItens,
  TypeMoniNivelControle,
} from '../../../services/monitoramento/nivelControle'

import { tryError } from '../../../services/Afins'
import { EstadioItens, getEstadios } from '../../../services/estadio'
import {
  getMoniNivelControleItem,
  MoniNivelItemControleItens,
} from '../../../services/monitoramento/nivelControleItens'
import { Container } from './styled'

interface TypesEspecie {
  update: number
  copy: number
  setCopy: (data: number) => void
  visible: boolean
  onClose: (data: boolean) => void
  permissions: ItensPerUserLogged
  codCulturas: number[]
  estadios: {
    consultoria: { estadios: number[] }
    consultor: { estadios: number[] }
  }
}
const menu = (onAll, onInverte) => (
  <Menu>
    <Menu.Item onClick={onAll}>Selecionar todos</Menu.Item>
    <Menu.Item onClick={onInverte}>Inverter seleção atual</Menu.Item>
  </Menu>
)
const PadraoMonitoramento: React.FC<TypesEspecie> = (props) => {
  const { update, onClose, visible, copy, setCopy, permissions, codCulturas, estadios } = props

  const refInputName = useRef<InputRef>()

  const refCheckBoxNivel = useRef<HTMLInputElement[]>([])

  const [drawerVisible, setDrawerVisible] = useState(false)

  const [loadingEdit, setLoadingEdit] = useState(false)

  const [padraoConsultoria, setPadraoConsultoria] = useState(false)

  const [items, setItems] = useState([])

  const [inputNivelLoading, setInputNivelLoading] = useState(false)

  const [itensARemover, setItensARemover] = useState([])

  const [dataCultura, setDataCultura] = useState<MoniNivelControleItens[]>([])

  const [dataNivelControle, setDataNivelControle] = useState<MoniNivelItemControleItens[]>([])

  const [dataEstadio, setDataEstadio] = useState<EstadioItens[]>([])

  const [nivelControleSelect, setNivelControleSelect] = useState<any[]>([])

  const [searchInputNivel, setSearchInputNivel] = useState('')

  const [estadioRemover, setEstadioRemover] = useState([])

  const [nivelValues, setNivelValues] = useState([])

  const [form] = Form.useForm()

  const listCultura = async () => {
    const resp: AxiosResponse<TypeMoniNivelControle> = await getMoniNivelControle({
      embed: 'item_distinto,nivel_controle_item_estadio',
    })

    setDataCultura(resp.data.itens)
  }

  const listEstadio = async (codCultura) => {
    const resp = await getEstadios({ codCultura, pagination: -1 })
    setDataEstadio(resp.data.itens)
  }

  const listNivelControle = async (codEstadio: number[] = []) => {
    if (codEstadio.length > 0) {
      setInputNivelLoading(true)
      const resp = await getMoniNivelControleItem({
        codEstadio: codEstadio.join(),
        embed: 'nivel_controle_item_estadio',
      })
      if (resp.status === 200) {
        const dados = resp.data.itens
        const inputArray: any[] = form.getFieldValue('item')
        let cods = []
        let codsRemove = []
        for (let i = 0; i < inputArray.length; i += 1) {
          const ia = inputArray[i]
          let temp = []
          let tempRemove = []
          for (let i = 0; i < dados.length; i += 1) {
            const ar = dados[i]
            ar.cod = `${ar.cod_especie}/${ar.cod_variacao_especie}`
            if (ia === ar.cod) {
              temp = [...temp, `${ar.cod_especie}/${ar.cod_variacao_especie}`]
            } else {
              tempRemove = [...tempRemove, `${ar.cod_especie}/${ar.cod_variacao_especie}`]
            }
          }
          if (temp.length > 0) {
            cods = [...cods, ...temp]
            codsRemove = [...codsRemove, ...tempRemove]
          }
        }
        const nivelSelect = cods.filter((este, i) => cods.indexOf(este) === i)
        setNivelControleSelect(nivelSelect)
        form.setFieldsValue({ item: nivelSelect })
        setDataNivelControle(dados.filter((item, i) => dados.indexOf(item) === i))
        setInputNivelLoading(false)
      }
    } else {
      setDataNivelControle([])
      form.setFieldsValue({ item: [] })
    }
  }

  const editPadraoMonito = async (data) => {
    message.loading('Aguarde...')
    setLoadingEdit(true)
    const resp: AxiosResponse<TypePadroesMonito> = await getPadraoMonitoramento({
      cod: data,
      embed: 'perfil_monitoramento_item,perfil_monitoramento_estadio',
    })
    if (resp.status === 200) {
      const dados = resp.data.itens[0]

      listEstadio(dados.cod_cultura)

      const estadios = dados.estadio.map((item) => item.cod_estadio)

      listNivelControle(estadios)

      const item = dados.item.map((rec) => {
        return `${rec.cod_especie}/${rec.cod_variacao_especie}`
      })

      setNivelValues(item)
      setPadraoConsultoria(dados.padrao_consultoria === 1)
      form.setFieldsValue({
        cod_nivel_controle: dados.cod_cultura,
        nome: dados.nome,
        padrao_consultoria: dados.padrao_consultoria === 1,
        estadios,
        item,
      })
      setLoadingEdit(false)
      message.destroy()
    }
  }

  const copyPadraoMonito = async (data) => {
    message.loading('Aguarde...')
    setLoadingEdit(true)
    const response: AxiosResponse<TypePadroesMonito> = await getPadraoMonitoramento({
      cod: data,
      embed: 'estadio,item',
    })

    const dados = response.data.itens[0]

    const dataItems = dados.item
    let item = []
    dataItems.forEach((cod) => {
      item = [...item, cod.cod_nivel_controle_item]
    })

    dados.item = item
    dados.nome = ''

    if (dados.cod_cliente === null && dados.cod_usuario === null) {
      dados.padrao_consultoria = true
    } else {
      dados.padrao_consultoria = false
    }
    // @ts-ignore
    if (dados.padrao_consultoria > 0) {
      setPadraoConsultoria(true)
    } else {
      setPadraoConsultoria(false)
    }

    setItems(item)

    form.setFieldsValue(dados)

    setLoadingEdit(false)
    refInputName.current.focus({ cursor: 'start' })
    message.destroy()
  }

  const handleChangeCultura = (data) => {
    listEstadio(data)
  }

  const handleBlurEstadio = () => {
    const data = form.getFieldValue('estadios')
    listNivelControle(data)
  }

  const handleChangeEstadio = (data: any[]) => {
    listNivelControle(data)
  }

  const handleChangeNivelControle = (checked: boolean, cod) => {
    const newNivel = nivelControleSelect.filter((rec) => rec !== cod)
    const existe = itensARemover.filter((rec) => rec === cod)
    let itemRemover = []
    if (!checked) {
      if (existe.length <= 0) {
        itemRemover = [...itensARemover, cod]
      }
      setNivelControleSelect(newNivel)
    } else if (existe.length > 0) {
      itemRemover = itensARemover.filter((rec) => rec !== cod)
    }

    return { itemRemover }
  }

  const handleChangePConsultoria = (data) => {
    setPadraoConsultoria(data)
  }

  const handleClickShift = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.shiftKey) {
    }
  }

  const filterNivel =
    searchInputNivel.length > 0
      ? dataNivelControle.filter((item) =>
          item.especie_nome.toLowerCase().includes(searchInputNivel.toLowerCase())
        )
      : []

  const handleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setItensARemover([])
    setDataNivelControle([])

    form.resetFields()
  }

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...', 99999)

      data.padrao_consultoria = data.padrao_consultoria ? 1 : 0

      data.cod_nivel_controle = dataNivelControle[0].cod_nivel_controle

      let itens = []

      if (data.item.length > 0) {
        for (let i = 0; i < data.item.length; i += 1) {
          const item = data.item[i]

          const temp = dataNivelControle.filter((rec) => rec.cod === item)

          if (temp.length > 0) {
            const nivel =
              temp[0].cod_variacao_especie === null
                ? { cod_especie: Number(temp[0].cod_especie) }
                : { cod_variacao_especie: Number(temp[0].cod_variacao_especie) }
            itens = [...itens, nivel]
          }
        }
      }

      data.item = itens

      if (update <= 0) {
        const response: AxiosResponse<TypePadroesMonito> = await postPadraoMonitoramento(data)
        if (response.status === 201) {
          Notification({
            message: 'Padrão de monitoramento cadastrado com sucesso',
            type: 'success',
          })
          onClose(true)
          setCopy(0)
          form.resetFields()
          message.destroy()
          setItensARemover([])
        }
      } else {
        if (itensARemover.length > 0) {
          const remover = itensARemover.map((rec) => {
            const temp = rec.split('/')
            if (temp[1] === 'null') {
              return { cod_especie: Number(temp[0]) }
            }
            return { cod_variacao_especie: Number(temp[1]) }
          })
          data.item_remover = { ...remover }
        }

        if (estadioRemover.length > 0) {
          data.estadio_remover = itensARemover
        }

        const response: AxiosResponse<TypePadroesMonito> = await putPadraoMonitoramento(
          update,
          data
        )
        if (response.status === 200) {
          Notification({
            message: 'Padrão de monitoramento atualizado com sucesso',
            type: 'success',
          })
          setItensARemover([])
          message.destroy()
        }
      }
    } catch (error) {
      tryError(error)
      message.destroy()
    }
  }

  const handleChangeCheckBoxSelectAll = (checked: boolean) => {
    let removerItem = []
    for (let i = 0; i < refCheckBoxNivel.current.length; i += 1) {
      const e: any = refCheckBoxNivel.current[i]
      removerItem = [
        ...removerItem,
        ...handleChangeNivelControle(checked, e.input.value).itemRemover,
      ]
    }
    setItensARemover(removerItem)
    const data = dataNivelControle.map((rec) => rec.cod)
    if (checked) {
      setNivelValues(data)
      form.setFieldsValue({
        item: data,
      })
    } else {
      setNivelValues([])
      form.setFieldsValue({ item: [] })
    }
  }

  const handleChangeInvertAll = () => {
    let newV = []
    for (let i = 0; i < refCheckBoxNivel.current.length; i += 1) {
      const e: any = refCheckBoxNivel.current[i]
      handleChangeNivelControle(!e.input.checked, e.input.value)
      if (!e.input.checked) {
        newV = [...newV, e.input.value]
      }
    }

    setNivelValues(newV)
    form.setFieldsValue({ item: newV })
  }

  useEffect(() => {
    setDrawerVisible(visible)
  }, [visible])

  useEffect(() => {
    if (update > 0) {
      editPadraoMonito(update)
    }

    if (copy > 0) {
      copyPadraoMonito(copy)
    }
  }, [update, copy])

  useEffect(() => {
    listCultura()
  }, [])

  return (
    <Container>
      <Form form={form} layout='vertical' onFinish={onFinish}>
        <Drawer
          placement='left'
          width='100%'
          open={drawerVisible}
          closeIcon={false}
          onClose={handleDrawerClose}
          style={{ position: 'absolute' }}
          bodyStyle={{ overflow: 'hidden' }}
          getContainer={false}
          footer={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Button
                hidden={permissions?.alteracao === 0 && permissions?.inclusao === 0}
                type='primary'
                htmlType='submit'
              >
                {update ? 'Atualizar' : 'Salvar'}
              </Button>
              {'   '}
              <Button danger onClick={handleDrawerClose} type='primary' style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>
          }
        >
          <Col span={24}>
            <Row
              style={{
                pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
              }}
              hidden={loadingEdit}
              gutter={[8, 0]}
            >
              <Col flex='1 1 130px'>
                <Form.Item rules={[{ required: true }]} name='cod_nivel_controle' label='Cultura'>
                  <Select disabled={update > 0} onBlur={handleChangeCultura}>
                    {dataCultura.map((data) => {
                      return (
                        <Select.Option
                          key={data.cod_cultura}
                          value={data.cod_cultura}
                          cultura={data.cod_cultura}
                        >
                          {data.cultura_nome}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col flex='1 1 200px'>
                <Form.Item rules={[{ required: true }]} name='nome' label='Nome'>
                  <Input />
                </Form.Item>
              </Col>
              <Col hidden={localStorage.getItem('cod-cliente') !== ''} flex='1 1 200px'>
                <Form.Item
                  initialValue={false}
                  hidden={localStorage.getItem('cod-cliente') !== ''}
                  name='padrao_consultoria'
                  label='Padrão Consultoria?'
                >
                  <Switch
                    checked={padraoConsultoria}
                    onChange={(data) => handleChangePConsultoria(data)}
                    unCheckedChildren='Não'
                    checkedChildren='Sim'
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Row gutter={[18, 8]}>
                  <Col span={5}>
                    <Form.Item rules={[{ required: true }]} name='estadios' label='Estádio'>
                      <Select
                        showSearch
                        onBlur={handleBlurEstadio}
                        optionFilterProp='filter'
                        size='large'
                        mode='multiple'
                      >
                        {dataEstadio.map((data) => {
                          let dis = []
                          if (padraoConsultoria) {
                            dis = estadios.consultoria.estadios.filter(
                              (rec: any) => rec.cod === data.cod
                            )
                          } else {
                            dis = estadios.consultor.estadios.filter(
                              (rec: any) => rec.cod === data.cod
                            )
                          }
                          return (
                            <Select.Option key={data.cod} value={data.cod} filter={data.nome}>
                              <Space>
                                <div className='nameNivel' style={{ width: '200px' }}>
                                  {data.nome}
                                </div>
                              </Space>
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={17}>
                    <Form.Item
                      initialValue={[]}
                      name='item'
                      label={
                        <Space size='small'>
                          <Checkbox
                            onChange={(e) => handleChangeCheckBoxSelectAll(e.target.checked)}
                            className={`select-all ${
                              nivelValues.length > 0 &&
                              nivelValues.length !== dataNivelControle.length
                                ? 'ant-checkbox-indeterminate'
                                : ''
                            } ${
                              dataNivelControle.length > 0 &&
                              nivelValues.length === dataNivelControle.length
                                ? 'ant-checkbox-checked'
                                : ''
                            }`}
                          />
                          <Dropdown
                            trigger={['click']}
                            overlay={menu(
                              () => handleChangeCheckBoxSelectAll(true),
                              () => handleChangeInvertAll()
                            )}
                            placement='bottomLeft'
                          >
                            <span style={{ fontSize: '.8em', cursor: 'pointer' }}>
                              <DownOutlined />
                            </span>
                          </Dropdown>
                          Nivel de controle
                        </Space>
                      }
                    >
                      {inputNivelLoading ? (
                        <div
                          style={{
                            width: '100%',
                            height: 'calc(90vh - 200px)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#fafafa',
                          }}
                        >
                          <Spin />
                        </div>
                      ) : (
                        <Checkbox.Group
                          value={nivelValues}
                          onChange={(data) => setNivelValues(data)}
                          className='overflow-checkbox-group'
                          disabled={dataNivelControle.length <= 0}
                        >
                          <Row>
                            <Input
                              onChange={(e) => setSearchInputNivel(e.target.value)}
                              placeholder='Pesquisar'
                            />
                            {filterNivel.length > 0
                              ? filterNivel.map((rec, index) => (
                                  <Col className='nivel-col' flex='0 1 300px'>
                                    <Checkbox
                                      ref={(e) => {
                                        refCheckBoxNivel.current[index] = e
                                      }}
                                      onChange={(e) =>
                                        handleChangeNivelControle(e.target.checked, e.target.value)
                                      }
                                      value={rec.cod}
                                    >
                                      {rec.especie_nome}
                                      {rec.cod_variacao_especie !== null ? (
                                        <p className='variacao-p'>{rec.variacao_especie_nome}</p>
                                      ) : null}
                                    </Checkbox>
                                  </Col>
                                ))
                              : dataNivelControle.map((rec, index) => (
                                  <Col className='nivel-col' flex='0 1 300px'>
                                    <Checkbox
                                      onClick={(e) => handleClickShift(e)}
                                      ref={(e) => {
                                        refCheckBoxNivel.current[index] = e
                                      }}
                                      onChange={(e) =>
                                        handleChangeNivelControle(e.target.checked, e.target.value)
                                      }
                                      value={rec.cod}
                                    >
                                      {rec.especie_nome}
                                      {rec.cod_variacao_especie !== null ? (
                                        <p className='variacao-p'>{rec.variacao_especie_nome}</p>
                                      ) : null}
                                    </Checkbox>
                                  </Col>
                                ))}
                          </Row>
                        </Checkbox.Group>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Form.Item name='status' hidden initialValue={1} />
            </Row>
          </Col>
        </Drawer>
      </Form>
    </Container>
  )
}

export default PadraoMonitoramento
