import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import NavegaCao from '../../Configs/NavegacaoAdmin'

import Container from './styled'

import { AdminViews } from '../../Views/admin'
import Logo from '../../assets/img/logo-admin.png'
import LogoSM from '../../assets/img/logo-sm-admin.png'
import { useLogic } from '../../context/useLogic'
import Icons from '../Icons'
import DropUser from '../dropUser'

const { Header, Sider, Content } = Layout

const { SubMenu } = Menu

const MenuLyout: React.FC = () => {
  const { setResponsive } = useLogic()

  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedWidth, setCollapsedWidth] = useState(80)

  const [openKeys, setOpenKeys] = useState([])

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (NavegaCao.map((item) => item.key).indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const toggle = (e) => {
    e.preventDefault()
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    localStorage.setItem('tipo-acesso', 'admin')
  }, [])

  return (
    <Container>
      <Layout>
        <Sider
          style={{
            overflow: 'hidden',
            height: '101vh',
            left: 0,
          }}
          className='corThema'
          theme='dark'
          trigger={null}
          collapsible
          collapsed={collapsed}
          width='250'
          breakpoint='lg'
          collapsedWidth={collapsedWidth}
          onBreakpoint={(broken) => {
            setResponsive(!!broken)
            return broken ? setCollapsedWidth(0) : setCollapsedWidth(80)
          }}
          onCollapse={(collapsed2) => {
            return collapsed2 ? setCollapsed(true) : setCollapsed(false)
          }}
        >
          <div className='area-logo'>
            <img
              className={collapsed ? 'logo-sm' : 'logo'}
              src={collapsed ? LogoSM : Logo}
              alt=''
            />
          </div>
          <Menu
            className='corThema'
            theme='dark'
            mode='inline'
            defaultSelectedKeys={['1']}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
          >
            {NavegaCao.map((menu) =>
              menu.submenu.length > 0 ? (
                <SubMenu
                  className='title'
                  style={{ display: menu.hidden ? 'none' : '' }}
                  icon={menu.icon ? <Icons icon={menu.icon} /> : null}
                  onTitleClick={() => (menu.path ? history.push(menu.path) : null)}
                  key={menu.key}
                  title={menu.title}
                >
                  {menu.submenu.map((subMenuFirst) =>
                    subMenuFirst.submenu.length > 0 ? (
                      <SubMenu
                        style={{ display: subMenuFirst.hidden ? 'none' : '' }}
                        icon={subMenuFirst.icon ? <Icons icon={subMenuFirst?.icon} /> : null}
                        onTitleClick={() => history.push(subMenuFirst.path)}
                        key={subMenuFirst.key}
                        title={subMenuFirst.title}
                      >
                        {subMenuFirst.submenu.map((subMenuSecond) =>
                          subMenuSecond.submenu.length > 0 ? (
                            <SubMenu
                              icon={
                                subMenuSecond.icon ? <Icons icon={subMenuSecond?.icon} /> : null
                              }
                              key={subMenuSecond.key}
                              title={subMenuSecond.title}
                            >
                              {subMenuSecond.submenu.map((subMenuTree) =>
                                subMenuTree.submenu.length > 0 ? (
                                  <SubMenu
                                    icon={
                                      subMenuTree.icon ? <Icons icon={subMenuTree?.icon} /> : null
                                    }
                                    key={subMenuTree.key}
                                    title={subMenuSecond.title}
                                  >
                                    {subMenuTree.submenu.map((subMenuFor) =>
                                      subMenuFor.submenu.length > 0 ? (
                                        <SubMenu
                                          icon={
                                            subMenuFor.icon ? (
                                              <Icons icon={subMenuFor?.icon} />
                                            ) : null
                                          }
                                          key={subMenuFor.key}
                                          title={subMenuFor.title}
                                        />
                                      ) : (
                                        <Menu.Item
                                          key={subMenuFor.key}
                                          icon={
                                            subMenuFor.icon ? (
                                              <Icons icon={subMenuFor?.icon} />
                                            ) : null
                                          }
                                        >
                                          <span>{subMenuFor.title}</span>
                                          <Link to={subMenuFor.path} />
                                        </Menu.Item>
                                      )
                                    )}
                                  </SubMenu>
                                ) : (
                                  <Menu.Item
                                    key={subMenuTree.key}
                                    icon={
                                      subMenuTree.icon ? <Icons icon={subMenuTree?.icon} /> : null
                                    }
                                  >
                                    <span>{subMenuTree.title}</span>
                                    <Link to={subMenuTree.path} />
                                  </Menu.Item>
                                )
                              )}
                            </SubMenu>
                          ) : (
                            <Menu.Item
                              key={subMenuSecond.key}
                              icon={
                                subMenuSecond.icon ? <Icons icon={subMenuSecond?.icon} /> : null
                              }
                            >
                              <span>{subMenuSecond.title}</span>
                              <Link to={subMenuSecond.path} />
                            </Menu.Item>
                          )
                        )}
                      </SubMenu>
                    ) : (
                      <Menu.Item
                        key={subMenuFirst.key}
                        icon={subMenuFirst.icon ? <Icons icon={subMenuFirst.icon} /> : null}
                      >
                        <span>{subMenuFirst.title}</span>
                        <Link to={subMenuFirst.path} />
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item
                  hidden={menu.hidden}
                  key={menu.key}
                  icon={menu.icon ? <Icons icon={menu?.icon} /> : null}
                >
                  <span>{menu?.title}</span>
                  {menu.path ? <Link to={menu.path} /> : null}
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Layout className='site-layout'>
          <Header className='site-layout-background'>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: toggle,
            })}
            {localStorage.getItem('tipo-acesso') === 'cliente' ? (
              <h3 style={{ display: 'inline-flex' }}>{localStorage.getItem('nome-cliente')}</h3>
            ) : null}
            <div className='option-user'>
              <DropUser />
            </div>
          </Header>
          <Content style={{ overflow: 'hidden' }}>
            <AdminViews />
          </Content>
        </Layout>
      </Layout>
    </Container>
  )
}

export default MenuLyout
