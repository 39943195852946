import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Aviso } from "./styles"
import { faCircleCheck, faCircleExclamation, faCircleInfo, faInfo, faSkullCrossbones } from "@fortawesome/free-solid-svg-icons";

//PROPS
interface Props {
    children: React.ReactNode;
    type?: 'warning' | 'danger' | 'success' | 'info';
}

export const Warning: React.FC<Props> = ({ children, type }) => {

    function iconType() {
        switch (type) {
            case 'warning':
                return faCircleExclamation;
            case 'danger':
                return faSkullCrossbones;
            case 'success':
                return faCircleCheck;
            case 'info':
                return faCircleInfo;
            default:
                return faInfo;
        }
    }

    return (
        <Aviso data-type={type}>
            <FontAwesomeIcon icon={iconType()} />
            {children}
        </Aviso>
    )
}