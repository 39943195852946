import styled from "styled-components";
import { TitleH1 } from "../titleH1";

//STYLES
const Header = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    align-items: center;
    border-top: thin solid var(--border-color);
    border-bottom: thin solid var(--border-color);
`;

//PROPS 
interface Props { 
    children: string | React.ReactNode;
}

export const HeaderDrawer: React.FC<Props> = ({ children }) => {
    return (
        <Header>
            <TitleH1>{children}</TitleH1>
        </Header>
    )
}