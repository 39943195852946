import { AxiosResponse } from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TypeLogin, postLogin } from '../services/login'

import dayJs from 'dayjs'
import dayJsPtBr from 'dayjs/locale/pt-br'

import navigateDefault, { TypesNavigate } from '../Configs/Navegacao'
import {
  buscaCidade,
  listEscolaridade,
  listEstadoCivil,
  listProfissao,
  listUf,
  tryError,
} from '../services/Afins'
import { ConsultoriaItensTypes, TypesGetConsultoria, getConsultoria } from '../services/Cliente'
import { ItensPerUserLogged, getPerUserLogged } from '../services/login/permissaoUsuarioLoado'
import { ItensPerfilTypes, getPerfil } from '../services/perfil'

interface AccessTypes {
  data?: string
  logged: TypeLogin['itens']
  onLogin: (email: string, senha: string, admin?: boolean, lab?: boolean) => any
  loggedInAs?: {
    type: 'CONTA' | 'CLIENTE' | 'CONTA_CLIENTE'
    codConta: number
    codCliente: number
  }
  navigate: TypesNavigate[]
  onPermissions: (codCliente?: number, codConta?: number) => void
  setLoggedInAs: (data: {
    type: 'CONTA' | 'CLIENTE' | 'CONTA_CLIENTE'
    codConta: number
    codCliente: number
  }) => void
  permission: ItensPerUserLogged[]
  loading: boolean
  listDefault: (type: ListDefaultTypes) => any
  nameCliente: string
  setNameCliente: (data: string) => void
  listPerfilAccess: ItensPerfilTypes[]
  nameSafra: string
  setNameSafra: (data: string) => void
  nomeLab?: string
  setNomeLab?: (data: string) => void
  codCultura: number
  setCodCultura: (data: number) => void
  consultoria: ConsultoriaItensTypes
  setConsultoria: (data: ConsultoriaItensTypes) => void
  atualizarArea?: boolean
  setAtualizarArea?:  (data: boolean) => void

}

interface ListDefaultTypes {
  type: 'UF' | 'ES' | 'EC' | 'PF' | 'CITY'
}

const AccessContext = createContext<AccessTypes>({} as AccessTypes)

export const UseAccess: React.FC = ({ children }) => {
  const [logged, setLogged] = useState<TypeLogin['itens']>()
  const [consultoria, setConsultoria] = useState<ConsultoriaItensTypes>({} as ConsultoriaItensTypes)
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const [loggedInAs, setLoggedInAs] = useState<AccessTypes['loggedInAs']>(
    {} as AccessTypes['loggedInAs']
  )

  const [nameCliente, setNameCliente] = useState('')
  const [nameSafra, setNameSafra] = useState('')
  const [nomeLab, setNomeLab] = useState('')
  const [codCultura, setCodCultura] = useState(0)
  const [atualizarArea, setAtualizarArea] = useState(false)
  const [listPerfilAccess, setListPerfilAccess] = useState<ItensPerfilTypes[]>([])

  const [dataUf, setDataUf] = useState([])
  const [dataEs, setDataEs] = useState([])
  const [dataEc, setDataEc] = useState([])
  const [dataPf, setDataPf] = useState([])
  const [dataCidades, setDataCidades] = useState([])

  dayJs.locale(dayJsPtBr)

  const listDefault = async ({ type }: ListDefaultTypes) => {
    let resp = []
    switch (type) {
      case 'UF':
        if (dataUf.length > 0) {
          resp = dataUf
        } else {
          resp = await listUf()
          setDataUf(resp)
        }
        break
      case 'ES':
        if (dataEs.length > 0) {
          resp = dataEs
        } else {
          resp = await listEscolaridade()
          setDataEs(resp)
        }
        break
      case 'EC':
        if (dataEc.length > 0) {
          resp = dataEc
        } else {
          resp = await listEstadoCivil()
          setDataEc(resp)
        }
        break
      case 'PF':
        if (dataPf.length > 0) {
          resp = dataPf
        } else {
          resp = await listProfissao()
          setDataPf(resp)
        }
        break

      case 'CITY':
        if (dataCidades.length > 0) {
          resp = dataCidades
        } else {
          resp = await buscaCidade('', '', '')
          setDataCidades(resp)
        }
        break

      default:
        break
    }

    return resp
  }

  const [navigate, setNavigate] = useState<any[]>([])

  const [permission, setPermission] = useState<ItensPerUserLogged[]>([])

  // <----Function Login---->
  async function onLogin(email, senha, admin, lab) {
  
    try {
      const resp: AxiosResponse<TypeLogin> = await postLogin({ email, senha, admin, lab})
   
      return resp
      
    } catch (error) {
      
      tryError(error)
    }
  }
  // <----Function Login---->

  async function onPermissions(codCliente = loggedInAs.codCliente, codConta = loggedInAs.codConta) {

    setLoading(true)
    const resp = await getPerUserLogged({ codCliente, codConta })
    const listPerfis = await getPerfil({})
    setListPerfilAccess(listPerfis.data.itens)

    const dados = resp.data.itens
    const navigateApp = [...navigateDefault]

    const navega = navigateApp.map((data) => {
      if (data.submenu.length > 0) {
        const subMenu = data.submenu.map((sub) => {
          const find = dados.filter((dado) => dado.cod_rotina === sub.id)

          if (sub.id !== 0) {
            if (find.length > 0) {
              if (
                find[0].acesso === 0 &&
                find[0].alteracao === 0 &&
                find[0].inclusao === 0 &&
                find[0].exclusao === 0
              ) {
                sub.hidden = true
              } else {
                sub.hidden = false
              }
            } else {
              sub.hidden = true
            }
          } else {
            sub.hidden = localStorage.getItem('tipo-acesso') === 'cliente' ? true : false
          }
          return sub
        })

        const check = subMenu.filter((item) => !item.hidden)

        if (check.length <= 0) {
          data.hidden = true
        } else {
          data.hidden = false
        }

        data.submenu = subMenu
      } else {
        const find = dados.filter((dado) => dado.cod_rotina === data.id)

        // if (data.id === 5) {
        //   console.log(find)
        // }

        if (find.length > 0) {
          if (
            find[0].acesso === 0 &&
            find[0].alteracao === 0 &&
            find[0].inclusao === 0 &&
            find[0].exclusao === 0
          ) {
            data.hidden = true
          } else {
            data.hidden = false
          }
        } else {
          data.hidden = true
        }
      }

      return data
    })
    setNavigate(navega)
    setPermission(dados)

    setLoading(false)
  }

  const handleGetConsultoria = async () => {
    const response: AxiosResponse<TypesGetConsultoria> = await getConsultoria()

    setConsultoria(response.data.itens[0])
  }

  useEffect(() => {
    if (loggedInAs.type) {
      onPermissions(loggedInAs.codCliente, loggedInAs.codConta)
      if (loggedInAs.type === 'CONTA') {
        handleGetConsultoria()
      }
    }
  }, [loggedInAs, logged])

  return (
    <AccessContext.Provider
      value={{
        logged,
        listPerfilAccess,
        loggedInAs,
        onLogin,
        setLoggedInAs,
        permission,
        onPermissions,
        navigate,
        loading,
        listDefault,
        setNameCliente,
        nameCliente,
        setNameSafra,
        nameSafra,
        nomeLab,
        setNomeLab,
        consultoria,
        setConsultoria,
        codCultura,
        setCodCultura,
        atualizarArea,
        setAtualizarArea
      }}
    >
      {children}
    </AccessContext.Provider>
  )
}

export const useAccess = () => {
  const context = useContext(AccessContext)

  return context
}
