import React, { useEffect, useState } from 'react'

import { Button, Modal, Popconfirm, Space, Table, Tag } from 'antd'

import { AxiosResponse } from 'axios'

import { faCopy, faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Notification } from '../../../../Components/notification'

import { useLogic } from '../../../../context/useLogic'

import { Container } from './styled'
import {
  delPadraoMonitoramento,
  getPadraoMonitoramento,
  PadroesMonitoItens,
  TypePadroesMonito,
} from '../../../../services/monitoramento/padraoesmonitoramento'
import PadraoMonito from '../../../../Components/monitoramento/padraomonitoramento'
import { localConfig } from '../../../../Configs/localConfig'
import { IconAlert } from '../../../../Components/iconsAlert'
import { pagination } from '../../../../Components/Default'
import ViewPage from '../../../../Components/ViewPage'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import Icons from '../../../../Components/Icons'
import { tryError } from '../../../../services/Afins'
import { useHistory } from 'react-router'

const PagePadraoMonitoramento: React.FC = () => {

  
  const { checkSafra } = useLogic()
  const history = useHistory()

  const [visibleForm, setVisibleForm] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [estadioSelect, setEstadioSelect] = useState<{
    consultoria: { estadios: number[] }
    consultor: { estadios: number[] }
  }>()

  const { itens } = useLogic()

  const codConta = localConfig().conta

  const { contas } = itens[0]?.acessoConta

  const contaFiltro = contas.find((data) => data.cod === Number(codConta))

  const [copy, setCopy] = useState<number>(0)

  const [update, setUpdate] = useState(0)

  const [dataListPadraoMonito, setDataListPadraoMonito] = useState<PadroesMonitoItens[]>([])

  const [dataTableRelative, setDataTableRelative] = useState<PadroesMonitoItens[]>([])

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [listPadraoMoni, setListPadraoMoni] = useState<number[]>([])

  const clienteSelecionado = localConfig().codCliente

  const listPadraoMonito = async () => {
    setLoadingTable(true)
    const response: AxiosResponse<TypePadroesMonito> = await getPadraoMonitoramento({
      embed: 'perfil_monitoramento_estadio',
    })

    setListPadraoMoni(response.data.itens.map((item) => item.cod_cultura))

    const dados = response.data.itens

    const consultoria = { estadios: [] }
    const consultor = { estadios: [] }

    for (let i = 0; i < dados.length; i += 1) {
      const rec = dados[i]

      if (rec.padrao_consultoria === 1) {
        consultoria.estadios = [...consultoria.estadios, ...rec.estadio.filter((item) => item.cod)]
      } else {
        consultor.estadios = [...consultor.estadios, ...rec.estadio.filter((item) => item.cod)]
      }
    }

    setEstadioSelect({ consultoria, consultor })

    setDataListPadraoMonito(response.data.itens)
    setDataTableRelative(response.data.itens)
    setLoadingTable(false)
  }
  const handleClickNewEstadio = () => {
    setVisibleForm(true)
    setUpdate(0)
  }

  const handleClickEdit = (data) => {
    setVisibleForm(true)
    setCopy(0)
    setUpdate(data)
  }

  const handleClickCopy = (data) => {
    setVisibleForm(true)
    setCopy(data)
    setUpdate(0)
  }

  const handlesPesquisa = (data) => {
    setLoadingTable(true)
    const dados = dataListPadraoMonito
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setDataTableRelative(response)
      setLoadingTable(false)
    }, 500)
  }

  const handleClickTrash = (cod, nome) => {
    Modal.confirm({
      title: `Deseja remover o padrão de monitoramento ${nome}?`,
      icon: <IconAlert type='confirm' size={6} />,
      okText: 'Sim',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        try {
          const response = await delPadraoMonitoramento(cod)

          if (response.status === 200) {
            Notification({
              message: 'Padrão de monitoramento excluído com sucesso',
              type: 'success',
            })

            listPadraoMonito()
          }
        } catch (error) {
          tryError(error)
        }
      },
    })
  }

  const handleCloseForm = (data) => {
    setVisibleForm(data)
    listPadraoMonito()
    if (!data) {
      setUpdate(0)
    }
  }
  useEffect(() => {
    listPadraoMonito()
  }, [])

  useEffect(() => {
    const idDaSafra = checkSafra('/monitoramento/padraomonitoramento')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])


  return (
    <Container>
      <ViewPage
        rotina={37}
        onPermission={setPermissions}
        title='Padrões de monitoramento'
        btnClick={handleClickNewEstadio}
        search
        inputChange={handlesPesquisa}
        content={
          <PadraoMonito
            permissions={permissions}
            codCulturas={listPadraoMoni}
            onClose={(data) => handleCloseForm(data)}
            visible={visibleForm}
            update={update}
            copy={copy}
            setCopy={setCopy}
            estadios={estadioSelect}
          />
        }
      >
        {' '}
        <Table
          loading={loadingTable}
          scroll={{ y: 'calc(90vh - 275px)' }}
          pagination={pagination}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataListPadraoMonito.length}</b>
            </div>
          )}
          bordered
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickEdit(record.cod)
              },
            }
          }}
          size='small'
          columns={[
            {
              key: 1,
              dataIndex: 'nome',
              title: 'Nome',
            },
            {
              key: 2,
              dataIndex: 'cultura_nome',
              title: 'Cultura',
            },
            {
              key: 3,
              dataIndex: 'padrao_consultoria',
              title: 'Tipo',
              render: (data, record) => {
                let print
                let cor

                if (data === 1) {
                  print = 'Padrão consultoria'
                  cor = 'green'
                } else if (record.cod_cliente !== null) {
                  print = 'Padrão cliente'
                  cor = 'purple'
                }
                return <Tag color={cor}>{print}</Tag>
              },
            },
            {
              key: 4,
              dataIndex: 'cod',
              width: '135px',
              align: 'center',
              title: 'Ações',
              render: (data, record) => (
                <Space size='small'>
                  <Button
                    shape='circle'
                    hidden={permissions?.alteracao === 0 && permissions?.acesso === 0}
                    onClick={() => handleClickEdit(data)}
                    disabled={record.padrao_consultoria === 1 && clienteSelecionado !== ''}
                    icon={<Icons icon={permissions?.acesso === 1 ? faEye : faPen} />}
                  />
                  {/* <Popconfirm
                    title='Deseja fazer uma copia desse padrão?'
                    onConfirm={() => handleClickCopy(data)}
                    icon={<IconAlert type='confirm' size={4} />}
                    cancelText='Não'
                    okText='Sim'
                    cancelButtonProps={{
                      type: 'primary',
                      danger: true,
                    }}
                  >
                    <Button
                      hidden={permissions?.inclusao === 0}
                      shape='circle'
                      icon={<Icons icon={faCopy} />}
                    />
                  </Popconfirm> */}
                  <Button
                    shape='circle'
                    hidden={permissions?.exclusao === 0}
                    disabled={record.padrao_consultoria === 1 && clienteSelecionado !== ''}
                    onClick={() => handleClickTrash(data, record.nome)}
                    type='primary'
                    danger
                    icon={<Icons icon={faTrash} />}
                  />
                </Space>
              ),
            },
          ]}
          dataSource={dataTableRelative}
        />
      </ViewPage>
    </Container>
  )
}

export default PagePadraoMonitoramento
