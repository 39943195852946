
//STYLE
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckBoxWrapper } from "./styled";

//PROPS
interface Props {
    label?: string;
    isChecked: boolean;
    trigger: React.Dispatch<React.SetStateAction<boolean>>;
    disabled?: boolean;
    onCheckClick?: Function;
    onMouseOver?: Function;
    onMouseOut?: Function;
    onlyCheckBox?: boolean;
}

const Checkbox: React.FC<Props> = ({ label, isChecked, trigger, disabled, onCheckClick, onMouseOver, onMouseOut, onlyCheckBox }) => {
    return (
        <CheckBoxWrapper style={{width: onlyCheckBox?16:undefined}}>
            <button 
                role="checkbox"
                className="CheckBoxWrapper__ckeckbox"
                data-checked={isChecked}
                onClick={()=>{trigger(!isChecked);onCheckClick?.()}}
                onBlur={()=>{}}
                onFocus={()=>{}}
                onMouseOver={()=>onMouseOver?.()}
                onMouseOut={()=>onMouseOut?.()}
                disabled={disabled}
            >
                {isChecked && <FontAwesomeIcon icon={faCheck} />}
            </button>

            {!!label &&(
                <label className="CheckBoxWrapper__label">
                    {label}
                </label>
            )}
        </CheckBoxWrapper>
    )
};

export default Checkbox;