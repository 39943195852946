import { Polygon } from '@react-google-maps/api'
import { Button, Col, ColorPicker, Form, Input, List, message, Modal, Row, Switch, Typography } from 'antd'
import { useEffect, useState } from 'react'

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router'
import { useLogic } from '../../../context/useLogic'
import { getBoundsAgrupamentoPropriedade, tryError } from '../../../services/Afins'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { getPropriedade, PropriedadeItens } from '../../../services/Propriedades'
import {
  getAgrupamentoPropriedades,
  ItensAgrupamentoPropriedadeTypes,
  postAgrupamentoPropriedades,
  putAgrupamentoPropriedades,
} from '../../../services/Propriedades/agrupamentoPropriedade'
import { getTalhaoCultivavel, TalhaoCultivaveisItens } from '../../../services/TalhaoSafra'
import { DefaultMap } from '../../DefaultMap'
import { Notification } from '../../notification'
import SidePainel from '../../SidePainel'
import { Container } from './styled'
import { BgColorsOutlined } from '@ant-design/icons'
import Demo from '../../Picker'
import Picker from '../../Picker'
import DiasDesdeData from '../../DiasDesdeData'
interface Coordenada {
  latitude: number
  longitude: number
}

interface Kml {
  coordenadas: Coordenada[]
}

interface DataPolygon {
  kml: Kml
}

type Props = {
  open: boolean
  onClose: (data: boolean) => void
  permissions?: ItensPerUserLogged
  update?: number
  setUpdate?: (data: number) => void
  refresh?: () => void
  agrupados?: number[]
  dataPolygon: DataPolygon[]
  dataAgrupamento: any
  map: google.maps.Map | null
}

interface PropriedadesPlus extends PropriedadeItens {
  select?: boolean
  ativo?: boolean
}

interface PropsPolygon {
  nomePropriedade: string
  cod_propriedade: number
  kml: {
    coordenadas: Coordenada[]
  }
  color: string
}

interface TypesMapa {
  zoom: number
  center:
    | {
        lat: number
        lng: number
      }
    | google.maps.LatLng
  mapContainerStyle?: { [key: string]: string }
}

const AgrupamentoPropriedade = (props: Props) => {
  const { onClose, open, permissions, update, refresh, agrupados, dataAgrupamento, setUpdate } = props
  const [form] = Form.useForm()

// console.log('dataAgrupamento comp', dataAgrupamento);

  const [map, setMap] = useState<google.maps.Map>()
  const [sideSidePainel, setSideSidePainel] = useState(false)
  const [selectPropriedade, setSelectPropriedade] = useState([])
  const [dataSelectPropriedade, setDataSelectPropriedade] = useState([])
  const [dataListAgrupamento, setDataListAgrupamento] = useState<PropriedadesPlus[]>([])
  const [loading, setLoading] = useState(false)
  const [dataTalhao, setDataTalhao] = useState([])
  const [talhoesCultivaveis, setTalhoesCultivaveis] = useState<TalhaoCultivaveisItens[]>([])
  const [dataPolygon, setDataPolygon] = useState<PropsPolygon[]>([])
  const [codPro, setCodPro] = useState<number>(0)
  const [dataPropriedaSelect, setDataPropriedaSelect] = useState({})
  const [nomePropriedade, setNomePropriedade] = useState<string>()
  const [updateSelect, setUpdateSelect] = useState<any>()
  const [arrayGrupoTalhoes, setArrayGrupoTalhoes] = useState([])
  const [arrayColors, setArrayColors] = useState([])
  const [arrayGrupoTalhoesEdit, setArrayGrupoTalhoesEdit] = useState([])
  const [sizePropriedade, setSizePropriedade] = useState(0)
  const [codClicadoPropriedade, setCodClicadoPropriedade] = useState<number>()
  const [arrayMessage, setArrayMessage] = useState([])
  const [disable, setDisable] = useState(false)
  const [arrayPropriedadeSemTalhao, setArrayPropriedadeSemTalhao] = useState([])
  const [verificacao, setVerificacao] = useState(false)
  const [cor, setCor] = useState('cyan')
  const [color, setColor] = useState('#00FFFF')
  const [select, setSelect] = useState(false)
  const[modalColor, setModalColor] = useState(false)
  // const[codSafra, setCodSafra] = useState()
  const { checkSafra, safraSelecionada } = useLogic()
  const history = useHistory()
  const [removeTalhoes, setRemoveTalhoes] = useState<ItensAgrupamentoPropriedadeTypes[]>([])
  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }



  const listPropriedades = async () => {
    let propAtiva = []
    setVerificacao(true)

    const codSafra = localStorage.getItem('codSafra')

    /* refinindo as cores e voltando o select para false quando saio do cadastro */
    if (dataListAgrupamento.length > 0) {
      propAtiva = [...dataListAgrupamento]

      propAtiva.map((prop) => {
        prop.select = false
        handleChangeColor('#00FFFF', prop.cod)
      })
    } else {


      const resp = await getPropriedade('', '', null, codSafra);

      const dados = resp.data.itens

      dados.map((item) => {
        if (item.status === 1) {
          propAtiva = [...propAtiva, item]
        }
      })
    }
    setDataListAgrupamento(propAtiva)
  }
 
  
  const resetMap = () => {
    if (map) {
      map.setCenter({ lat: 0.0, lng: 0.0 })
      map.setZoom(2)
    }
  }

  const handleToogleSwitchTalhao = async (data, cod, modificarEstado: boolean = true) => {
    const propriedades = [...dataListAgrupamento]
    const getTalhoesCultivaveis = await getTalhoesCultivaveisPropriedade(cod)

    /* Verifica se a propriedade possui talhão */
    if (data == true && getTalhoesCultivaveis.length == 0) {
      const nomePropriedadeNaoPossuiTalhao = dataListAgrupamento.find(
        (item) => item.cod == cod
      )?.nome

      const codClicado = dataListAgrupamento.find((item) => item.cod == cod)?.cod

      if (codClicado) {
        data = false
        setDisable(true)
      }

      setArrayMessage((item) => [...item, codClicado])

      Notification({
        message: `Propriedade ${nomePropriedadeNaoPossuiTalhao} não possui talhões `,
        type: 'warning',
      })

      message.destroy()
    }

    propriedades.map((propriedade) => {
      propriedade.select = propriedade.cod == cod ? data : propriedade?.select
    })

    if (data) {
      setDataListAgrupamento(propriedades)

      //filtra a propriedade que foi selecionada
      const propSelect = propriedades.find((rec) => rec.cod === cod)
      setDataPropriedaSelect(propSelect)

      // Adiciona os novos talhões ao array existente

      setDataPolygon((talhoes) => [...talhoes, ...getTalhoesCultivaveis])

      setTalhoesCultivaveis(getTalhoesCultivaveis)
    } else {
      // Remove talhões específicos do array com base em uma condição
      setDataPolygon((talhoes) => talhoes.filter((talhao) => talhao.cod_propriedade !== cod))
      // setDataPolygon((talhoes) => talhoes.filter((talhao) => talhao.cod_propriedade !== cod));
    }

    setCodPro(cod)
  }

  const getTalhoesCultivaveisPropriedade = async (codPropriedade) => {
    if (!arrayGrupoTalhoes[codPropriedade]) {
      const response = await getTalhaoCultivavel({ codPropriedade: codPropriedade })
      const dados = response.data.itens

      setArrayGrupoTalhoes((talhoes) => ({
        ...talhoes,
        [codPropriedade]: dados,
      }))

      return dados
    } else {
      return arrayGrupoTalhoes[codPropriedade]
    }
  }

  const polygonsTalhao = (talhoes: [TalhaoCultivaveisItens][]) => {
    return talhoes
      .map((item) => {
        if (item.length > 0) {
          const ta = item.map((data) => {
            const talhao = data.kml.coordenadas.map((item) => {
              return { lat: Number(item.latitude), lng: Number(item.longitude) }
            })

            return talhao
          })

          return {
            nomePropriedade: item[0]?.propriedade_nome,
            codPropriedade: item[0]?.cod_propriedade,
            coord: ta,
            color: 'cyan',
          }
        }
      })
      .filter((rec) => rec?.codPropriedade !== undefined)
  }

  const setColorPropriedade = (codPropriedade, cor) => {
    setArrayColors((colors) => ({
      ...colors,
      [codPropriedade]: cor,
    }))
  }

  const handleChangeColor = (hex: string, cod: number) => {
    const dados = [...dataPolygon]

    const newData = dados.map((rec) => {
      if (rec.cod_propriedade === cod) {
        setColor((rec.color = hex))
      }
      return rec
    })

    setColorPropriedade(cod, hex)
    setDataPolygon(newData)
  }

  /* Function edit, quando clicar no btn editar chama ela */
  const edit = async (cod) => {
    try {
      setLoading(true)

      /* Requisiçãop das propriedades agrupadas */
      const resp = await getAgrupamentoPropriedades({ cod, embed: 'propriedades' })
      const dados = resp.data.itens[0]

      const propriedades = [...dataListAgrupamento]

      // lista as propriedades agrupadas
      const listPro = dados.propriedades.map((rec) => {
        setColorPropriedade(rec.cod_propriedade, rec.cor)
        return rec.cod_propriedade
      })

      const newPro = propriedades.map((rec) => {
        const fill = dados?.propriedades.filter((item) => item.cod_propriedade === rec.cod)

        if (fill.length > 0) {
          setSelect((rec.select = true))
        }

        handleToogleSwitchTalhao(rec.select, rec.cod, false)
        return rec
      })

      setDataSelectPropriedade(listPro)
      setSelectPropriedade(listPro)
      form.setFieldsValue(dados)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Erro ao carregar dados para edição:', error)
    }
  }

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...')

      let codPropriedade = []
      let remove = []

      let propriedadesSelect = [...dataSelectPropriedade]

      dataListAgrupamento.forEach((item) => {
        if (item.select == true) {
          const findPropriedade = codPropriedade.find(
            (objPropriedade) => objPropriedade.cod_propriedade === item.cod
          )

          if (!findPropriedade) {
            codPropriedade.push({
              cod_propriedade: item.cod,
              cor: arrayColors[item.cod] ?? '#00FFFF',
            })
          }

          if (!propriedadesSelect.includes(item.cod)) {
            propriedadesSelect.push(item.cod)
          }
        } else if (item.select === false && item.select !== undefined && update > 0) {
          // console.log("INCLUDES", propriedadesSelect.includes(item.cod), "ITEM", item.cod);

          if (propriedadesSelect.includes(item.cod) && !remove.includes(item.cod)) {
            // Adiciona o id ao array apenas se não estiver presente
            propriedadesSelect = propriedadesSelect.filter((prop) => prop !== item.cod)
            remove = [...remove, item.cod]
          }
        }
      })

      setDataSelectPropriedade(propriedadesSelect)

      data.propriedades = codPropriedade

      if (codPropriedade.length < 2) {
        message.destroy()
        Notification({
          message: 'Erro: É necessário selecionar pelo menos duas propriedades.',
          type: 'error',
        })
        return // Stop further execution
      }

      if (remove.length > 0) {
        data.propriedades_remover = remove
      }

      if (update <= 0) {
        const response = await postAgrupamentoPropriedades(data)
        if (response.status === 201) {
          message.destroy()
          Notification({
            message: 'Agrupamento cadastrado com sucesso',
            type: 'success',
          })
          form.resetFields()
          onClose(false)
          setUpdate(0)
          refresh()
        }
      } else {
        const response = await putAgrupamentoPropriedades(data, update)
        if (update > 0 && response.status === 200) {
          message.destroy()
          Notification({
            message: 'Agrupamento atualizado com sucesso',
            type: 'success',
          })
        }
      }
    } catch (error) {
      tryError(error)
      message.destroy()
    }
  }



  const handleModalColor = () => {
    setModalColor(true)
  }


  useEffect(() => {
    // refSelect.current.focus()

    form.setFieldsValue({
      safra: JSON.parse(localStorage.getItem('codSafra')),
    })

    const idDaSafra = checkSafra('/agrupamento-propriedade')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [arrayMessage])

  useEffect(() => {
    if (dataPolygon.length > 0 && map) {
      const coordenadas: google.maps.LatLngLiteral[] = []
      dataPolygon.forEach((dp) => {
        dp.color = arrayColors[dp.cod_propriedade] ?? 'cyan'

        if (dp.kml && dp.kml.coordenadas) {
          dp.kml.coordenadas.forEach((e) => {
            if (
              typeof e.latitude === 'number' &&
              typeof e.longitude === 'number' &&
              isFinite(e.latitude) &&
              isFinite(e.longitude)
            ) {
              // lat: Number(item.latitude), lng: Number(item.longitude)
              coordenadas.push({ lat: Number(e.latitude), lng: Number(e.longitude) })
            }
          })
        }
      })
      const bounds = getBoundsAgrupamentoPropriedade(coordenadas)
      map.fitBounds(bounds)
    }
  }, [dataPolygon, map, selectPropriedade, arrayMessage, dataSelectPropriedade])

  /* Quando o componente é chamado (Aberto) ele faz a requisição get e faz a listagem de todas as propriedades do cliente */
  useEffect(() => {
    if (!open) {
      setUpdate(0)
      listPropriedades()
      onFinish
    } else {
      setDataPolygon([])
    }
  }, [open])

  useEffect(() => {
    if (update > 0) {
      edit(update)

      resetMap()
    } else {
      resetMap()
    }
  }, [update])

  useEffect(() => {
    if (update > 0) {
      resetMap()
    } else {
      resetMap()
    }
  }, [open])
  
  useEffect(() => {
    if (open) {
      const agrupamentoCheck = dataAgrupamento.filter((item) => item.cod === update)

      if (agrupamentoCheck.length > 0) {
        if (agrupamentoCheck[0].create_user) {
          setUsuarioQueCriou(agrupamentoCheck[0].create_user)

          const dateOnlyString = agrupamentoCheck[0].create_time.split(' ')[0]
          const timeOnlyString = agrupamentoCheck[0].create_time.split(' ')[1]

          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (agrupamentoCheck[0].update_user) {
          setUsuarioQueAlterou(agrupamentoCheck[0].update_user)

          const dateOnlyString = agrupamentoCheck[0].update_time.split(' ')[0]
          const timeOnlyString = agrupamentoCheck[0].update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [ logUser, disable, usuarioQueAlterou, usuarioQueCriou, update, horarioDeCriacao])

  // console.log('open', open);
  

  return (
    <Container>
      <DefaultMap onMapLoad={setMap} open={open} onClose={onClose}>
        <div style={{ width: '300px', position: 'relative', padding: 10 }}>
          <SidePainel
                 title={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <>{update ? 'Atualizar Agrupamento' : 'Cadastrar Agrupamento'}</>
                    {logUser && update > 0 && usuarioQueAlterou !== '' ? (
                      <>
                        <DiasDesdeData
                          dataCriacao={dataAlteracao}
                          onDataFromCreation={handleDataFromCreation}
                        />
                        <small style={{ opacity: '0.5', fontSize: '10px' }}>
                          atualizado por: {usuarioQueAlterou}{' '}
                          {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                            ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                            : `às ${horarioDeAtualizacao}h`}
                        </small>
                      </>
                    ) : !logUser && update > 0 ? (
                      <>
                        {' '}
                        {usuarioQueCriou === '' ? null : (
                          <small style={{ opacity: '0.5', fontSize: '10px' }}>
                            criado por: {usuarioQueCriou}{' '}
                            {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                              ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                              : `às ${horarioDeCriacao}h`}
                          </small>
                        )}
                      </>
                    ) : null}
                  </div>
                }
            open={sideSidePainel}
            onClose={(data) => {
              onClose(data)
              resetMap()
              form.resetFields()
            }}
            size='small'
            onSave={() => form.submit()}
            onCloseSide={setSideSidePainel}
          >
            <Form form={form} layout='vertical' onFinish={onFinish}>
              <Row gutter={[8, 0]}>
                <Col flex='0 1 400px'>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label='Nome do agrupamento'
                    name='nome'
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col flex='1 1 auto'>
                  <Form.Item label='Propriedades' name='propriedades'>
                    <div
                      style={{
                        maxHeight: 'calc(100vh - 500px)',
                        overflowY: 'auto',
                      }}
                    >
                      <List loading={loading}>
                        {dataListAgrupamento.map((data, index) => {
                          const v = selectPropriedade.filter((rec) => rec === data.cod)

                          let exibe
                          if (update === 0) {
                            const a = agrupados.filter((rec) => rec === data.cod)

                            if (a.length > 0) {
                              exibe = false
                            } else {
                              exibe = true
                            }
                          } else {
                            if (v.length > 0) {
                              exibe = true
                            } else {
                              const a = agrupados.filter((rec) => rec === data.cod)
                              if (a.length > 0) {
                                exibe = false
                              } else {
                                exibe = true
                              }
                            }
                          }

                          const key = index + 1
                          const color = dataPolygon.find((rec) => rec.cod_propriedade === data.cod)

                          return (
                            exibe && (
                              <List.Item key={key}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {dataPolygon.map((item) => {
                                    ;<h1>{item.cod_propriedade}</h1>
                                  })}
                                  <Switch
                                    disabled={
                                      arrayMessage.find((item) => item === data.cod) && !disable
                                    }
                                    onChange={(swtich) =>
                                      handleToogleSwitchTalhao(swtich, data.cod)
                                    }
                                    checkedChildren='Sim'
                                    unCheckedChildren='Não'
                                    checked={data?.select}
                                  />
                                  <div style={{ paddingLeft: '15px' }}>
                                    {data?.ativo ? (
                                      <h5 style={{ marginBottom: '0' }}>
                                        <Typography.Text>{data.talhao}</Typography.Text>
                                      </h5>
                                    ) : (
                                      <h5 style={{ marginBottom: '0' }}>{data.nome}</h5>
                                    )}

                                    <small>{data.area_total_talhao_safra} ha</small>
                                    
                                    
                                    <>
                                      {data.select === false &&
                                        arrayMessage.find((item) => item === data.cod) && (
                                          <span
                                            style={{
                                              color: 'red',
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faExclamationTriangle}
                                              style={{ marginRight: '5px' }}
                                            />
                                            Não possui talhões.
                                          </span>
                                        )}
                                    </>
                                  </div>
                                </div>

                                {data.select && (
                                  // <ColorPicker
                                  //   disabledAlpha
                                  //   onChange={(_, hex) => handleChangeColor(hex, data.cod)}
                                  //   presets={[
                                  //     {
                                  //       label: 'Recomendado',
                                  //       colors: [
                                  //         '#00FFFF',
                                  //         'red',
                                  //         'yellow',
                                  //         'blue',
                                  //         '#19F819',
                                  //         '#FFC0CB',
                                  //       ],
                                  //     },
                                  //   ]}
                                  //   value={color?.color || 'cyan'} // Defina ciano como valor inicial
                                  // />
                                  // // <Button onClick={handleModalColor}><BgColorsOutlined style={{fontSize: '20px'}} /></Button>
                                  <Picker value={color?.color || 'cyan'} onChange={(_, hex) => handleChangeColor(hex, data.cod)}/>
                                )}
                              </List.Item>
                            )
                          )
                        })}
                      </List>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              {/* Hidden */}

              <Form.Item initialValue='1' hidden name='status' />
              <Form.Item hidden initialValue={safraSelecionada} name='cod_safra' />
            </Form>
            {dataPolygon.map((item) => {
              ;<div>
                <h1>{item.cod_propriedade}</h1>
              </div>
            })}
          </SidePainel>
        </div>
        {dataPolygon.map((rec: any) => {
          return (
            <Polygon
              paths={rec.kml.coordenadas.map((e) => ({ lat: e.latitude, lng: e.longitude }))}
              options={{
                fillColor: rec.color,
                strokeColor: rec.color,
              }}
            />
          )
        })}
      </DefaultMap>
      <Modal onCancel={()=> {setModalColor(false)}} open={modalColor} >
        <p>Modal</p>
        <p>Modal</p>
        <p>Modal</p>
        <p>Modal</p>
      </Modal>
    </Container>
  )
}

export default AgrupamentoPropriedade