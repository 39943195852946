import React, { useEffect, useState } from 'react'

import { Card, Spin } from 'antd'
import { localConfig } from '../../../Configs/localConfig'
import { Container } from './styled'

import logoSysAgro from '../../../assets/img/logo-2.png'

import {
  ClienteItensTypes,
  ConsultoriaItensTypes,
  getCliente,
  getConsultoria,
} from '../../../services/Cliente'
import { PropriedadeItens } from '../../../services/Propriedades'

interface TypesPrint {
  qtdaPontos: number
  areaTalhao: number
  propriedade: PropriedadeItens
  data: {
    propriedadeNome: string
    talhaoNome: string
    cityUf: string
    representante?: {
      cidade_nome?: string
      cod?: number
      cpf?: string
      participacao?: string
      endereco: string
      endereco_cep: string
      endereco_complemento: string
      representante?: number
      solicitante?: string
      uf_nome?: string
      uf_sigla?: string
      usuario_nome?: string
    }
    outrosSolicitantes: string
    cpf: string
    areaAmostra: string
    dataColeta: string
  }

  // nomeTalhao?: string;
  dados: {
    cod_laboratorio: number
    cod_propriedade: number
    cod_talhao: number
    analises?: [
      {
        cod_analises?: [
          {
            cod?: number
            nome?: string
            valor?: string
          }
        ]
        cod_profundidade?: number
        id?: string
        pontos?: [
          {
            COMENTARIO: string
            INDICE: number
            NOME: string
          }
        ]
        profundidade?: string
        title?: string
      }
    ]
    responsaveis?: [string | number]
  }
}

const PrintOC: React.FC<TypesPrint> = (props) => {
  const { dados, qtdaPontos, areaTalhao, propriedade, data } = props
  const { codCliente } = localConfig()

  const [dataCliente, setDataCliente] = useState<ClienteItensTypes>({} as ClienteItensTypes)
  const [dataProprie, setDataProprie] = useState<PropriedadeItens>({} as PropriedadeItens)

  const [totalAnalises, setTotalAnalises] = useState(0)

  const [consultoria, setConsultoria] = useState<ConsultoriaItensTypes>({} as ConsultoriaItensTypes)

  const [loading, setLoading] = useState(false)

  const printDados = async () => {
    setLoading(true)
    const cliente =
      codCliente === '' ? await getConsultoria() : await getCliente(codCliente, '', '')

    // @ts-ignore
    setDataCliente(cliente?.data.itens[0])
    setDataProprie(propriedade)

    setLoading(false)
  }

  const handleGetConsultoria = async () => {
    const response = await getConsultoria()

    setConsultoria(response.data.itens[0])
  }

  const handleTotalAnalises = () => {
    let qtda = 0
    let custo = 0
    dados?.analises?.forEach?.((info) => {
      qtda += info.pontos.length

      const somaTemp = parseFloat(info.cod_analises[0]?.valor) * qtda

      custo += somaTemp

      return { qtda, custo }
    })

    setTotalAnalises(qtda)
  }

  useEffect(() => {
    printDados()
    handleTotalAnalises()
    handleGetConsultoria()
  }, [dados])

  return (
    <Container>
      {loading ? (
        <Spin style={{ justifyContent: 'center', alignItems: 'center' }} size='large' />
      ) : (
        <Card size='small' style={{ width: '100%' }}>
          <div id='printable'>
            <table style={{ top: 0, minWidth: '100%', border: 'none' }}>
              <thead>
                <tr style={{ border: 'none' }}>
                  <td>
                    {' '}
                    <div
                      style={{
                        minWidth: '100%',
                        textAlign: 'center',
                      }}
                    >
                      <h3>ORDEM DE SERVIÇO COLETA</h3>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'left',
                        textAlign: 'left',
                        fontSize: '11px',
                      }}
                    >
                      <img width='75px' src={consultoria?.logo_url} alt='Agromarques' />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        minHeight: '8px',
                        backgroundColor: '#00770e',
                        backgroundImage: 'linear-gradient(to right, #ffffff, #00770e)',
                      }}
                    />
                    <small style={{ fontSize: '10px', margin: 0 }}>
                      {consultoria?.linha1}
                      <p style={{ margin: 0 }} />
                      {consultoria?.linha2}
                    </small>
                  </td>
                </tr>
              </thead>
              <p />
              <tbody>
                <table style={{ width: '100%' }}>
                  <tr>
                    <td colSpan={5}>
                      <b>CLIENTE:</b>{' '}
                      {dataCliente.tipo === 'G'
                        ? data?.representante.usuario_nome
                        : dataCliente?.nome}
                    </td>
                    <td colSpan={4}>
                      <b>
                        {/* @ts-ignore */}
                        {data?.cpf?.length === 18 ? 'CNPJ:' : 'CPF:'}{' '}
                      </b>
                      {data?.cpf}
                    </td>
                  </tr>
                  <tr>
                    {dataCliente.tipo === 'G' ? (
                      <td colSpan={9}>
                        <b>ENDEREÇO: </b>
                        {data?.representante.endereco} {data?.representante.endereco_cep}{' '}
                        {data?.representante.endereco_complemento} {data?.representante.cidade_nome}{' '}
                        / {data?.representante.uf_sigla}
                      </td>
                    ) : (
                      <td colSpan={9}>
                        <b>ENDEREÇO:</b> {dataCliente?.endereco}
                        {', '}
                        {dataCliente?.bairro}
                        {', '}
                        {dataCliente?.complemento}
                        {', '}
                        CEP: {dataCliente?.cep} {dataCliente?.cidade?.nome}/
                        {dataCliente?.cidade?.uf_sigla}.
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td colSpan={9}>
                      <b>OUTROS SOLICITANTES: </b>
                      {data?.outrosSolicitantes}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <b>PROPRIEDADE: </b>
                      {dataProprie.nome}
                    </td>
                    <td colSpan={3}>
                      <b>ÁREA (ha): </b> {dataProprie.area}
                    </td>
                    <td colSpan={3}>
                      <b>MATRÍCULA(S): </b> {dataProprie?.matricula}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={9}>
                      <b>MUNICÍPIO/UF: </b>
                      {data?.cityUf}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <b>TALHÃO: </b> {data?.talhaoNome}
                    </td>
                    <td colSpan={4}>
                      <b>ÁREA (ha): </b> {areaTalhao}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0 }} colSpan={9}>
                      <td>
                        <b>Número inicial: </b> 1
                      </td>
                      <td>
                        <b>Número final:</b> {qtdaPontos}
                      </td>
                    </td>
                  </tr>
                  {dados?.analises?.map((info) => {
                    return (
                      <>
                        <tr>
                          <td colSpan={4}>
                            <b>ANÁLISE: </b> {info?.cod_analises[0]?.nome}
                          </td>
                          <td colSpan={2}>
                            <b>Qtde de amostras: </b> {info?.pontos.length}
                          </td>
                          <td colSpan={3}>
                            <b>Profundidade (cm):</b> {info?.profundidade}
                          </td>
                        </tr>
                        <tr style={{ height: '100%' }}>
                          <td style={{ textAlign: 'justify' }} colSpan={9}>
                            {info?.pontos.map((ponto) => {
                              return (
                                <Card
                                  size='small'
                                  style={{
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '32px',
                                    height: '32px',
                                    fontSize: '12px',
                                  }}
                                >
                                  {parseInt(ponto.NOME, 10)}
                                </Card>
                              )
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            {info?.cod_analises[0]?.cod === 5 ? (
                              <b>Obs: {propriedade?.area} ha para cada amostra</b>
                            ) : (
                              <b>Obs: {data?.areaAmostra} ha para cada amostra</b>
                            )}
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </table>
                <table style={{ width: '100%' }}>
                  <tr>
                    <td colSpan={5}>
                      <b>TOTAL GERAL DE AMOSTRAS: </b>
                      {totalAnalises}
                    </td>
                    <td colSpan={5}>
                      <b>
                        RESPONSÁVEL(EIS):{' '}
                        {dados?.responsaveis !== undefined
                          ? dados?.responsaveis.map((data) => `${data}, `)
                          : null}
                      </b>
                    </td>
                    <td colSpan={4}>
                      <b>DATA COLETA: </b> {data?.dataColeta}
                    </td>
                  </tr>
                </table>
              </tbody>
              <tfoot style={{ width: '100%' }}>
                <p />
                <tr style={{ width: '100%', border: 'none' }}>
                  <td style={{ minWidth: '100%' }}>
                    {' '}
                    <div
                      style={{
                        width: '100%',
                        minHeight: '8px',
                        backgroundImage: 'linear-gradient(to right, #00770e, #ffffff)',
                      }}
                    />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'left',
                        alignItems: 'left',
                        textAlign: 'left',
                        fontSize: '11px',
                      }}
                    >
                      <img width='95px' src={logoSysAgro} alt='SysAgro' />
                    </div>
                    <small style={{ fontSize: '10px', margin: 0 }}>
                      Licenciado para: {consultoria?.linha1}
                    </small>
                  </td>
                </tr>
                <p />
                <p />
              </tfoot>
            </table>
          </div>
        </Card>
      )}
    </Container>
  )
}

export default PrintOC
