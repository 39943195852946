import styled from "styled-components"
import { removerEspacos, ultimaPosicao } from "../../../../utils";

//STYLES
const Header = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    background-color: #FAFAFA;
    border-bottom: thin solid rgba(0,0,0,0.06);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .Header__item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        font-weight: 600;
        font-size: 14px;
        color: var(--text-color);
        text-transform: capitalize;
    }
`;

//PROPS
type Itens = {
    id?: number | string,
    valor: string;
    width: number | string;
}
interface Props {
    itens: Itens[],
    opacity?: number;
}

export const HeaderTable: React.FC<Props> = ({ itens, opacity }) => {
    return (
        <Header style={{opacity: opacity}}>
            {(itens ?? []).map(({ id, valor, width })=> {
                return (
                    <div 
                        key={id ?? removerEspacos(valor)}
                        className="Header__item" 
                        style={{
                            width: width,
                            minWidth: width,
                            maxWidth: width,
                            borderRight: ultimaPosicao(itens)?.valor !== valor 
                            ? 'thin solid rgba(0,0,0,0.06)' 
                            : undefined
                        }}
                    >
                        {valor}
                    </div>
                )
            })}
        </Header>
    )
}