import { TypesNavigate } from "../../../Configs/Navegacao";

export function isLastSubmenu(sebmenu_list: TypesNavigate, path: string) {

    let removes = (sebmenu_list?.submenu ?? []).filter(item=> item.hidden === false);
    let last_item = removes?.[removes?.length -1]?.path; 

    if (path === last_item) {
        return true;
    }
    return false;
}