import { faPagelines } from '@fortawesome/free-brands-svg-icons'
import {
  faCalendar,
  faClipboardCheck,
  faCogs,
  faCrosshairs,
  faFlask,
  faHandHoldingDroplet,
  faLaptopHouse,
  faLeaf,
  faMapMarkedAlt,
  faTachometerAlt,
  faUsers,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons'
import { localConfig } from '../Configs/localConfig'
import { APP_PREFIX_PATH } from './AppConfig'

import AgrupamentoTalhao from '../Views/app/agrupamentoTalhao'
import Fornecedores from '../Views/app/cadastros/fornecedores'
import Servicos from '../Views/app/cadastros/servicos'
import Cliente from '../Views/app/cliente'
import AppCampoConfig from '../Views/app/configAppCampo'
import PagePerfis from '../Views/app/configs/perfis'
import EquipeConsultor from '../Views/app/consultriaAgronomica/equipeConsultor'
import EquipeLider from '../Views/app/consultriaAgronomica/equipeLider'
import PageMapaPropriedade from '../Views/app/consultriaAgronomica/mapaPropriedade'
import UserConsultor from '../Views/app/consultriaAgronomica/userConsultor'
import UserLiderEquipe from '../Views/app/consultriaAgronomica/userLider'
import UserTechnician from '../Views/app/consultriaAgronomica/userTecnicoAt'
import Equipe from '../Views/app/equipe'
import Home from '../Views/app/home'
import Lab from '../Views/app/laboratorio'
import Monitoramento from '../Views/app/monitoramento'
import PageEspecie from '../Views/app/monitoramento/especie'
import PageNovoMonitoramento from '../Views/app/monitoramento/newMonitoramento'
import PageNivelControle from '../Views/app/monitoramento/nivelControle'
import PagePadraoMonitoramento from '../Views/app/monitoramento/padraoMonitoramento'
import ResumoMonitoramento from '../Views/app/monitoramento/resumo'
import Patrimonio from '../Views/app/patrimonio'
// import PageConfigBook from '../Views/app/precisao/configBook'
import PageConfigBookNovo from '../Views/app/precisao/novoConfigBook'
import GrupoServico from '../Views/app/precisao/grupoanalises'
import Laboratorios from '../Views/app/precisao/laboratorio'
import OrderSer from '../Views/app/precisao/laborder'
import PageMapaFertilidade from '../Views/app/precisao/mapFertilidade'
import Propriedades from '../Views/app/propriedades'
import PageAgrupamentoPropriedade from '../Views/app/propriedades/agrupamentoPropriedade'
import PageCalcario from '../Views/app/recomendacao/calcario'
// import CompatibilidadeAtivo from '../Views/app/recomendacao/compatibilidadeAtivo'
// import CompatibilidadeInsumos from '../Views/app/recomendacao/compatibilidadeInsumos'
import RecomendaInsumos from '../Views/app/recomendacao/insumos'
import Safra from '../Views/app/safra'
import PageSubArea from '../Views/app/subarea'
import Talhao from '../Views/app/talhao'
import TalhaoCultivavel from '../Views/app/talhaoSafra'
import GerarBook from '../Components/monitoramento/gerarBook'
import { NovoCompatibilidadeAtivo } from '../Views/app/recomendacao/novoCompatibilidadeAtivo'
import { EquipeAssistenteTecnico2 } from '../Views/app/consultriaAgronomica/equipeAssistenteTecnico2'

export interface TypesNavigate {
  key: string
  id: string | number
  hidden: boolean
  path?: string
  title: string
  className?: string
  icon: any
  breadcrumb: boolean
  component?: React.ComponentType<any>
  submenu: {
    key: string
    id: string | number
    hidden: boolean
    path: string
    title: string
    icon: any
    breadcrumb: boolean
    component: React.ComponentType
    submenu: any[]
  }[]
}

const dashBoardNavTree: TypesNavigate[] = [
  {
    key: '/home',
    id: 65,
    hidden: false,
    path: `${APP_PREFIX_PATH}/home`,
    title: 'DashBoard',
    icon: faTachometerAlt,
    breadcrumb: false,
    component: Home,
    submenu: [],
  },
  {
    key: '/equipe',
    id: 4,
    hidden: false,
    path: `${APP_PREFIX_PATH}/equipe`,
    title: 'Equipe',
    icon: faUsers,
    breadcrumb: false,
    component: Equipe,
    submenu: [],
  },
  {
    key: '/cliente',
    id: 5,
    hidden: false,
    path: `${APP_PREFIX_PATH}/cliente`,
    title: 'Clientes',
    icon: faCalendar,
    breadcrumb: true,
    component: Cliente,
    submenu: [],
  },
  {
    key: '/safra',
    id: 26,
    hidden: false,
    path: `${APP_PREFIX_PATH}/safra`,
    title: 'Safras',
    icon: faLeaf,
    breadcrumb: true,
    component: Safra,
    submenu: [],
  },
  {
    key: '/patrimonio',
    id: 27,
    hidden: false,
    path: `${APP_PREFIX_PATH}/patrimonio`,
    title: 'Patrimônio',
    icon: faLaptopHouse,
    breadcrumb: true,
    component: Patrimonio,
    submenu: [],
  },
  {
    key: '/propriedades-list',
    hidden: false,
    id: '',
    path: '',
    title: 'Propriedades',
    icon: faMapMarkedAlt,
    breadcrumb: false,
    submenu: [
      {
        key: '/propriedades',
        id: 25,
        hidden: false,
        path: `${APP_PREFIX_PATH}/propriedades`,
        title: 'Cadastro',
        icon: '',
        breadcrumb: false,
        component: Propriedades,
        submenu: [],
      },
      {
        key: '/agrupamento-propriedade',
        id: 25,
        hidden: process.env.NODE_ENV === 'production',
        path: `${APP_PREFIX_PATH}/agrupamento-propriedade`,
        title: 'Agrupamentos',
        icon: '',
        breadcrumb: false,
        component: PageAgrupamentoPropriedade,
        submenu: [],
      },
      {
        key: '/talhao/cadastro',
        id: 28,
        hidden: false,
        path: `${APP_PREFIX_PATH}/talhao/cadastro`,
        title: 'Talhões da propriedade',
        icon: '',
        breadcrumb: false,
        component: Talhao,

        submenu: [],
      },
      {
        key: '/talhao/safra',
        id: 29,
        hidden: false,
        path: `${APP_PREFIX_PATH}/talhao/safra`,
        title: 'Talhões da safra',
        icon: '',
        breadcrumb: false,
        component: TalhaoCultivavel,
        submenu: [],
      },
      {
        key: '/talhao/subarea',
        id: 30,
        hidden: false,
        path: `${APP_PREFIX_PATH}/talhao/subarea`,
        title: 'Subárea do talhão',
        component: PageSubArea,
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: '/talhao/grupotalhaoes',
        id: 31,
        hidden: false,
        path: `${APP_PREFIX_PATH}/talhao/grupotalhaoes`,
        title: 'Agrupamento de talhões',
        icon: '',
        breadcrumb: false,
        component: AgrupamentoTalhao,
        submenu: [],
      },
    ],
  },
  {
    key: '/precisao',
    id: '',
    hidden: false,
    className: 'ant-menu-item',
    title: 'Agricultura de precisão',
    icon: faCrosshairs,
    breadcrumb: true,
    submenu: [
      {
        key: '/precisao/laborder',
        id: 32,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/laborder`,
        title: 'OS para Coleta',
        icon: '',
        breadcrumb: false,
        component: OrderSer,

        submenu: [],
      },
      {
        key: '/precisao/mapa-fertilidade',
        id: 82,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/mapa-fertilidade`,
        title: 'Mapa de fertilidade',
        icon: '',
        breadcrumb: false,
        component: PageMapaFertilidade,
        submenu: [],
      },
      {
        key: '/precisao/config-book',
        id: 84,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/config-book`,
        title: 'Configuração do book',
        icon: '',
        breadcrumb: false,
        component: PageConfigBookNovo,
        submenu: [],
      },
      // {
      //   key: '/precisao/config-book-2',
      //   id: 84,
      //   hidden: false,
      //   path: `${APP_PREFIX_PATH}/precisao/config-book-2`,
      //   title: 'Config do book 2',
      //   icon: '',
      //   breadcrumb: false,
      //   component: PageConfigBookNovo,
      //   submenu: [],
      // },
      {
        key: '/precisao/laboratorios',
        id: 33,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/laboratorios`,
        title: 'Laboratórios',
        icon: '',
        breadcrumb: false,
        component: Laboratorios,

        submenu: [],
      },
      {
        key: '/precisao/gruposervicos',
        id: 34,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/gruposervicos`,
        title: 'Grupo de análises',
        icon: '',
        breadcrumb: false,
        component: GrupoServico,
        submenu: [],
      },
      {
        key: 'precisaocaocalcario',
        id: 40,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/calcario`,
        title: 'Cadastro de calcário',
        icon: '',
        breadcrumb: false,
        component: PageCalcario,
        submenu: [],
      },
    ],
  },
  {
    key: '/monitoramento',
    id: 23,
    hidden: false,
    title: 'Monitoramento',
    icon: faPagelines,
    breadcrumb: true,
    submenu: [
      {
        key: '/monitoramento/mapadecalor',
        id: 73,
        hidden: false,
        path: `${APP_PREFIX_PATH}/monitoramento/mapadecalor`,
        title: 'Mapa de calor detalhado',
        icon: '',
        breadcrumb: false,
        component: Monitoramento,
        submenu: [],
      },
      {
        key: '/monitoramento/gerarbook',
        hidden: false,
        id: 73,
        path: `${APP_PREFIX_PATH}/monitoramento/gerarbook`,
        title: 'Gerar book resumido',
        component: GerarBook,
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: '/monitoramento/resumo',
        id: 73,
        hidden: false,
        path: `${APP_PREFIX_PATH}/monitoramento/resumo`,
        title: 'Resumo',
        icon: '',
        breadcrumb: false,
        component: ResumoMonitoramento,
        submenu: [],
      },
      {
        key: '/monitoramento/especie',
        id: 35,
        hidden: false,
        path: `${APP_PREFIX_PATH}/monitoramento/especie`,
        title: 'Catálogo de pragas e ervas daninhas',
        icon: '',
        breadcrumb: false,
        component: PageEspecie,
        submenu: [],
      },
      {
        key: '/monitoramento/niveldecontrole',
        hidden: false,
        id: 36,
        path: `${APP_PREFIX_PATH}/monitoramento/niveldecontrole`,
        title: 'Nível de controle',
        icon: '',
        breadcrumb: false,
        component: PageNivelControle,
        submenu: [],
      },
      {
        key: '/monitoramento/padraomonitoramento',
        hidden: false,
        id: 37,
        path: `${APP_PREFIX_PATH}/monitoramento/padraomonitoramento`,
        title: 'Padrões de monitoramento',
        component: PagePadraoMonitoramento,
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: '/monitoramento/padrao_pontos',
        hidden: false,
        id: 71,
        path: `${APP_PREFIX_PATH}/monitoramento/padrao_pontos`,
        title: 'Pontos padrão para monitoramento',
        component: PageNovoMonitoramento,
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: '/recomendacao',
    id: '',
    hidden: false,
    title: 'Recomendação',
    icon: faHandHoldingDroplet,
    breadcrumb: false,
    submenu: [
      {
        key: 'recomendaacaoinsumos',
        id: 38,
        hidden: false,
        path: `${APP_PREFIX_PATH}/recomendacao/insumos`,
        title: 'Catálogo insumos',
        icon: '',
        breadcrumb: false,
        component: RecomendaInsumos,
        submenu: [],
      },
      //RONALDO PEDIU PARA REMOVER Compatibilidade insumos -> 15/07/2024
      // {
      //   key: 'recomendaacaocompatibilidadeinsumo',
      //   id: 39,
      //   hidden: false,
      //   path: `${APP_PREFIX_PATH}/recomendacao/compatibilidadeinsumo`,
      //   title: 'Compatibilidade insumos',
      //   icon: '',
      //   breadcrumb: false,
      //   component: CompatibilidadeInsumos,
      //   submenu: [],
      // },
      //------------------------------------------------------------------------
      // {
      //   key: 'recomendaacaocompatibilidadeativo',
      //   id: 40,
      //   hidden: false,
      //   path: `${APP_PREFIX_PATH}/recomendacao/compatibilidadeativo`,
      //   title: 'Compatibilidade ativos',
      //   icon: '',
      //   breadcrumb: false,
      //   component: CompatibilidadeAtivo,
      //   submenu: [],
      // },
      {
        key: 'recomendaacaocompatibilidadeativo',
        id: 39,
        hidden: false,
        path: `${APP_PREFIX_PATH}/recomendacao/incompatibilidades`,
        title: 'Incompatibilidades',
        icon: '',
        breadcrumb: false,
        component: NovoCompatibilidadeAtivo,
        submenu: [],
      }
    ],
  },
  {
    key: '/consultoria',
    id: '',
    hidden: localConfig().acesso === 'cliente',
    title: 'Equipes de campo',
    icon: faUserTie,
    breadcrumb: false,
    submenu: [

      {
        key: 'usuariotecnico',
        id: 68,
        hidden: false,
        path: `${APP_PREFIX_PATH}/consultoria/user-tecnico-at`,
        title: 'Atribuição de cargos',
        icon: '',
        breadcrumb: false,
        component: UserTechnician,
        submenu: [],
      },
      //OCULTADO TEMPORIAMENTE PARA ENXUTAR (07/08/24)
      // {
      //   key: 'usuarioconsultor',
      //   id: 67,
      //   hidden: false,
      //   path: `${APP_PREFIX_PATH}/consultoria/user-consultor`,
      //   title: 'Consultores',
      //   icon: '',
      //   breadcrumb: false,
      //   component: UserConsultor,
      //   submenu: [],
      // },
      //OCULTADO TEMPORIAMENTE PARA ENXUTAR (07/08/24)
      // {
      //   key: 'usuarioLiderEquipe',
      //   id: 78,
      //   hidden: false,
      //   path: `${APP_PREFIX_PATH}/consultoria/user-lider`,
      //   title: 'Líder de Equipe',
      //   icon: '',
      //   breadcrumb: false,
      //   component: UserLiderEquipe,
      //   submenu: [],
      // },
      
      {
        key: 'equipeconsultor',
        id: 69,
        hidden: false,
        path: `${APP_PREFIX_PATH}/consultoria/equipe-consultor`,
        title: 'Equipe consultor',
        icon: '',
        breadcrumb: false,
        component: EquipeConsultor,
        submenu: [],
      },
      {
        key: 'equipelider',
        id: 77,
        hidden: false,
        path: `${APP_PREFIX_PATH}/lideria/equipe-lider`,
        title: 'Equipe líder de campo',
        icon: '',
        breadcrumb: false,
        component: EquipeLider,
        submenu: [],
      },
      {
        key: 'equipeAssistenteTecnico',
        id: 89,
        hidden: false,
        path: `${APP_PREFIX_PATH}/lideria/equipe-assistente-tecnico`,
        title: 'Equipe assistente técnico',
        icon: '',
        breadcrumb: false,
        component: EquipeAssistenteTecnico2,
        submenu: [],
      },
      {
        key: '/consultoria/mapa-propriedade',
        id: 81,
        hidden: false,
        path: `${APP_PREFIX_PATH}/consultoria/mapa-propriedade`,
        title: 'Mapa de acesso',
        icon: '',
        breadcrumb: false,
        component: PageMapaPropriedade,
        submenu: [],
      },
    ],
  },
  {
    key: '/cadastros',
    hidden: false,
    id: '',
    title: 'Cadastros',
    icon: faClipboardCheck,
    breadcrumb: false,
    submenu: [
      {
        key: '/cadastros/fornecedores',
        id: 52,
        hidden: false,
        path: `${APP_PREFIX_PATH}/cadastros/fornecedores`,
        title: 'Fornecedores',
        icon: '',
        breadcrumb: false,
        component: Fornecedores,
        submenu: [],
      },
      {
        key: '/cadastros/servicos',
        id: 57,
        hidden: false,
        path: `${APP_PREFIX_PATH}/cadastros/servicos`,
        title: 'Serviços',
        icon: '',
        breadcrumb: false,
        component: Servicos,
        submenu: [],
      },
    ],
  },
  {
    key: '/config',
    hidden: false,
    id: '',
    title: 'Configurações',
    icon: faCogs,
    breadcrumb: false,
    submenu: [
      {
        key: '/config/perfil',
        id: 6,
        hidden: false,
        path: `${APP_PREFIX_PATH}/config/perfil`,
        title: 'Perfis de acesso',
        icon: '',
        breadcrumb: false,
        component: PagePerfis,
        submenu: [],
      },
      {
        key: '/config/campo',
        id: 0,
        hidden: localStorage.getItem('tipo-acesso') === 'cliente',
        path: `${APP_PREFIX_PATH}/config/campo`,
        title: 'Aplicativos mobile',
        icon: '',
        breadcrumb: false,
        component: AppCampoConfig,
        submenu: [],
      },
    ],
  },
  {
    key: '/lab',
    id: '',
    hidden: false,
    path: `${APP_PREFIX_PATH}/lab`,
    title: 'Laboratorio',
    icon: faFlask,
    breadcrumb: false,
    component: Lab,
    submenu: [],
  },
]

const navigationConfig: TypesNavigate[] = [...dashBoardNavTree]

export default navigationConfig
