import React, { FC, useEffect } from 'react'
import { Button, notification } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import * as serviceWorker from './serviceWorkerRegistration'
import { IconAlert } from './Components/iconsAlert'

const ServiceWorkerWrapper: FC = () => {
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null)

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    noti()
    setWaitingWorker(registration.waiting)
  }

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate })
    // serviceWorker.unregister();
  }, [])

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' })
    window.location.reload()
  }

  const noti = () => {
    notification.info({
      duration: 99999,
      icon: <IconAlert type='info' size={6} />,
      message: 'Nova versão',
      description: (
        <>
          Uma nova versão do sistema está disponível! <br />{' '}
          <Button icon={<SyncOutlined />} onClick={reloadPage}>
            Clique aqui para atualizar
          </Button>
        </>
      ),
      onClick: reloadPage,
      placement: 'bottom',
    })
  }

  return <></>
}

export default ServiceWorkerWrapper
