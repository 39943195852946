import React, { useEffect, useRef, useState } from 'react'
import './styles.css'
import {
  Badge,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Transfer,
  Upload,
  UploadProps,
} from 'antd'

import ImgCrop from 'antd-img-crop'

import InputMask from 'react-input-mask'

import Svg, { LoadingOutlined } from '@ant-design/icons'

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TransferDirection } from 'antd/lib/transfer'
import { UploadChangeParam } from 'antd/lib/upload'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import {
  getEquipe,
  getEquipeFiles,
  postEquipe,
  putEquipe,
  TypesEquipe,
} from '../../services/Equipe'

import { Container, TabContentME } from './styled'

import modal from 'antd/es/modal'
import { useAccess } from '../../context/useAccess'
import { useLogic } from '../../context/useLogic'
import { getB64Type, tryError } from '../../services/Afins'
import { ClienteItensTypes, getCliente, TypeGetCliente } from '../../services/Cliente'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import { getPerfil, ItensPerfilTypes, TypesPerfil } from '../../services/perfil'
import { getPropriedade, getPropriedadeLiberada } from '../../services/Propriedades'
import { postUserPropriedade } from '../../services/Usuario/propriedades'
import { InputChangeEventHandler } from '../../types'
import DatePicker from '../DatePiker'
import { IconAlert } from '../iconsAlert'
import { Notification } from '../notification'
import { Alert } from 'antd/lib'
import {
  TypesTipoLiderEquipe,
  getTipoLiderEquipe,
} from '../../services/consultoriaAgricola/tipoLiderEquipe'
import {
  delTipoEquipeLiderGerenciar,
  postTipoEquipeLiderGerenciar,
} from '../../services/consultoriaAgricola/equipLiderGerenciar'
import {
  TypesTipoConsultor,
  getTipoConsultor,
} from '../../services/consultoriaAgricola/tipoConsultor'
import {
  delTipoEquipeConsultorGerenciar,
  postTipoEquipeConsultorGerenciar,
} from '../../services/consultoriaAgricola/equipConsultorGerenciar'

const { Option } = Select
const { TabPane } = Tabs

interface TypesEquipeFC {
  update: number
  visible: boolean
  setOnClose: (data: boolean) => void
  setUpdate: (data: number) => void
  atualizar: () => void
  permissions: ItensPerUserLogged
  acessoPermissoes: ItensPerUserLogged
  callFunctionPropriedades: boolean
  equipeGeral

  setCloseCallFunction: (data: boolean) => void
  // eslint-disable-next-line react/require-default-props
  emailNew?: string
}

const Equipe: React.FC<TypesEquipeFC> = (props) => {
  const {
    update,
    visible,
    setOnClose,
    setUpdate,
    atualizar,
    permissions,
    emailNew = '',
    acessoPermissoes,
    equipeGeral,
    // handleSerachPropriedades,
    setCloseCallFunction,
  } = props

  const { loggedInAs, listDefault } = useAccess()
  const { dataCliente, setDataCliente, codCliente, responsive } = useLogic()

  const refFoto = useRef<UploadProps>({} as UploadProps)

  const [form] = Form.useForm()

  const [listUF, setListUF] = useState([])
  const [updateKey, setUpdateKey] = useState(0)
  const [listCity, setListCity] = useState([])
  const [listEscolaridades, setListEscolaridades] = useState([])
  const [listEstadoCivis, setListEstadoCivis] = useState([])
  const [fileList, setFileList] = useState([])
  const [listCityConjuge, setListCityConjuge] = useState([])
  const [listDataProfissao, setListDataProfissao] = useState([])
  const [listarPropriedades, setListarPropriedades] = useState([])
  const [noInput, setNoInput] = useState(false)
  const [accessCliente, setAccessCliente] = useState<number>()
  const [listarPropriedadesAtual, setListarPropriedadesAtual] = useState([])
  const [allClients, setAllClients] = useState<boolean>(true)
  const [listDataCliente, setListDataCliente] = useState<ClienteItensTypes[]>([])
  const [listDataClienteLiberado, setListDataClienteLiberado] = useState<ClienteItensTypes[]>([])
  const [targetKey, setTargetKey] = useState([]);
  const [targetKey_referencia, setTargetKey_referencia] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [targetKeysPro, setTargetKeysPro] = useState([])
  const [listDataProfiles, setListDataProfiles] = useState<ItensPerfilTypes[]>([])
  const [codFunc, setCodFunc] = useState(null)
  const [cargoTecnico, setCargoTecnico] = useState(false)
  const [cargoAt, setCargoAt] = useState(false)
  const [liderEquipe, setLiderEquipe] = useState({})
  const [tabActive, setTabActive] = useState<number>()
  const [dataLideres, setDataLideres] = useState<any[]>([])
  const [dataConsultores, setDataConsultores] = useState<any[]>([])
  const [dataClienteLiberados, setDataClienteLiberados] = useState<any[]>([])
  const [loadingTransferPro, setLoadingTransferPro] = useState(false)
  const [semAcesso, setSemAcesso] = useState([])
  const [acesso, setAcesso] = useState([])
  const [codsPerfil, setCodsPerfil] = useState([])

  const [dataCity, setDataCity] = useState([])
  const [possuiVinculo, setPossuiVinculo] = useState(false)

  const [codEstadoCivil, setCodEstadoCivil] = useState<number>()

  const [documents, setDocuments] = useState({
    doc_cpf: [],
    doc_rg: [],
    doc_titulo: [],
    doc_cnh: [],
    doc_pis: [],
    doc_reservista: [],
    doc_diploma: [],
    doc_certidao: [],
    doc_sus: [],
    doc_ctps: [],
  })

  const [usuario, setUsuario] = useState('')
  const [nomeLider, setNomeLider] = useState('')
  const [codConsultor, setCodConsultor] = useState(null)

  const listState = async () => {
    const resp = await listDefault({ type: 'UF' })

    setListUF(resp)
  }

  const listEscola = async () => {
    const resp = await listDefault({ type: 'ES' })

    setListEscolaridades(resp)
  }

  const listaEstadoCivil = async () => {
    const resp = await listDefault({ type: 'EC' })
    setListEstadoCivis(resp)
  }

  const listaProfissao = async () => {
    const resp = await listDefault({ type: 'PF' })

    setListDataProfissao(resp)
  }

  const listCliente = async () => {
    const resp = await getCliente('', '', '')

    setListDataCliente(resp.data.itens)
  }

  const listProfiles = async () => {
    const resp: AxiosResponse<TypesPerfil> = await getPerfil({
      order: 'nome',
      embed: 'permissoes',
    })
    setListDataProfiles(resp.data.itens)
  }

  const listClientes = async () => {
    if (dataCliente.length <= 0) {
      const resp: AxiosResponse<TypeGetCliente> = await getCliente(codCliente, '', '')
      setDataCliente(resp.data.itens)
    }
  }

  const dataListCitys = async () => {
    const resp = await listDefault({ type: 'CITY' })
    setDataCity(resp)
  }

  const handleChangeUF = (data) => {
    const resp = dataCity.filter((item) => item.cod_uf === data)
    setListCity(resp)
    form.resetFields(['cid_nasc'])
  }

  const handleChangeUfConjuge = (data) => {
    const resp = dataCity.filter((item) => item.cod_uf === data)
    setListCityConjuge(resp)
    form.resetFields(['cod_conjuge_naturalidade'])
  }

  const handleChangeEstadoCivil = (data) => {
    setCodEstadoCivil(data)
  }

  const clientesAtivos = listDataCliente.filter((cliente) => cliente.status === 1)

  const handleDrawerClose = () => {
    setOnClose(false)
    setNoInput(false)
    setUpdate(0)
    setListarPropriedadesAtual([])
    setFileList([])
    setCloseCallFunction(false)
    setTargetKey([])
    setTargetKeysPro([])
    setCodFunc(null)
    message.destroy()
    form.resetFields()
    setTabActive(1)
    setAllClients(true)
    setUsuario('')
    setPossuiVinculo(false)
    setDataClienteLiberados([])
    setDataConsultores([])
    setDataLideres([])
    setAcesso([])
    setSemAcesso([])
    setNomeLider('')
    setDataForm([])
    setDocuments({
      doc_cpf: [],
      doc_rg: [],
      doc_titulo: [],
      doc_cnh: [],
      doc_pis: [],
      doc_reservista: [],
      doc_diploma: [],
      doc_certidao: [],
      doc_sus: [],
      doc_ctps: [],
    })
  }

  const checkCodUF = (codCity) => {
    const codUf = dataCity.find((data) => data.cod === codCity)?.cod_uf
    return codUf
  }

  const handleCheckInputs = async () => {
    try {
      form?.submit()
    } catch (error) {
      Notification({
        message: `Existem ${error.errorFields.length} erros no cadastro de Equipe`,
        descricao: error?.errorFields?.map((item) => {
          return <p>{item.errors[0]}</p>
        }),
        type: 'error',
      })
    }
  }

  //console.log('propriedades', listarPropriedades)
  //console.log('propriedades Atuais', listarPropriedadesAtual)
  //console.log('Keys pro', targetKeysPro)
  // console.log('clientesRemove', clienteRemove)
  const handleChangeMoveTransfer = (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    if (update > 0) {
      if (direction === 'left') {
        // setClienteRemove([...clienteRemove, ...moveKeys])
        
        const remover = acesso.map((rec, index) => {
          if (moveKeys.some((item) => item === rec) === false) {
            return rec
          }
        })
        setSemAcesso([...semAcesso, ...moveKeys])
        setAcesso(remover.filter((item) => item !== undefined))
        setTargetKey(targetKeys)
      } else {
        const remover = semAcesso.map((rec, index) => {
          if (moveKeys.some((item) => item === rec) === false) {
            return rec
          }
        })
        // setClienteRemove(clienteRemove.filter((item) => item !== moveKeys[0]))
        setAcesso([...acesso, ...moveKeys])
        setSemAcesso(remover.filter((item) => item !== undefined))
        setTargetKey(targetKeys)
      }
    } else {
      setTargetKey(targetKeys)
    }
  }

  const handleChangeMoveTransferPro = async (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    try {
      setLoadingTransferPro(true)
      if (direction === 'left') {
        let data = {
          cod_usuario: codFunc,
          propriedades_remover: moveKeys,
        }

        if (targetKeys.length <= 0) {
          Notification({
            message: 'Atenção',
            type: 'warning',
            descricao: 'É necessário ao menos uma propriedade por cliente com acesso ao usuário',
            duration: 20,
          })
          setLoadingTransferPro(false)

          return
        }

        await postUserPropriedade({ ...data })
        setTargetKeysPro(targetKeys)
        setLoadingTransferPro(false)
      } else {
        let data = {
          cod_usuario: codFunc,
          propriedades: moveKeys,
        }
        await postUserPropriedade({ ...data })
        setTargetKeysPro(targetKeys)
        setLoadingTransferPro(false)
      }
    } catch (error) {
      tryError(error)
      setLoadingTransferPro(false)
    }
  }

  //funcao onde armazena em um array as propridades ja buscada e coloca o codCliente como index, assim nao puxa novamente o getPropriedade

  const buscarPropriedadeCliente = async (codCliente: number) => {
    try {
      if (!listarPropriedades[codCliente]) {
        const propriedadeInfo = await getPropriedade('', '', codCliente)
        listarPropriedades[codCliente] = propriedadeInfo.data.itens
      }
      return listarPropriedades[codCliente]
    } catch (error) {
      console.error('Ocorreu um erro ao buscar a propriedade do cliente:', error)
      throw error
    }
  }

  // funcao onde passa o codCliente como parametro para puxar as rotas get.

  const handleChangeAccessPro = async (codClientePropriedade) => {
    message.loading('Aguarde...', 999999)
    if (tipoAcesso === 'cliente') {
      codClientePropriedade = localStorage.getItem('cod-cliente')
    }
    try {
      // const resp = await getPropriedade('', '', codCliente)
      const resp = await buscarPropriedadeCliente(codClientePropriedade)
      const respL = await getPropriedadeLiberada(codFunc, codClienteLocal, codClientePropriedade)
      const pegarPropriedadesAtuais = listarPropriedades[codClientePropriedade]

      setListarPropriedadesAtual(pegarPropriedadesAtuais)
      // setListDataPropriedade(resp.data.itens)
      setTargetKeysPro(respL.data.itens.map((rec) => String(rec.cod)))

      message.destroy()
    } catch (error) {}
  }

  const editEquipe = async (cod) => {
    message.loading('Aguarde...', 999999)
    const resp: AxiosResponse<TypesEquipe> = await getEquipe({
      cod,
      embed: `usuario,clientes,tipo_assistente_tecnico,tipo_tecnico`,
    })

    const cliente = resp.data.itens[0]?.clientes?.map((cod) => JSON.stringify(cod.cod))

    const dadosOriginais = resp.data.itens[0]?.usuario?.map((item) => {
      return { ...item, cliente }
    })

    const codsPerfil = resp.data.itens.map((cod) => {
      return {
        codPerfilCliente: cod.cod_perfil_cliente,
        codPerfilGeral: cod.cod_perfil_cliente_geral,
        codPerfilConta: cod.cod_perfil_conta,
      }
    })

    const codEquipe = resp.data.itens[0].cod
    const usuario = resp.data.itens[0].usuario[0]
    setCodFunc(resp.data.itens[0].cod_func)
    setAccessCliente(resp.data.itens[0].acesso_cliente)

    const equipe = resp.data.itens[0]

    equipeTecnico(equipe)
    equipeConsultor()
    imgEquipe(usuario, codEquipe)

    /* Nome do usuário que vai aparecer no canto superior esquerdo */
    setUsuario(equipe.usuario_nome)
    setCodConsultor(equipe.cod_consultor)

    setCodsPerfil(codsPerfil)

    setDataForm(dadosOriginais)

    setCargoTecnico(equipe.tecnico)
    setCargoAt(equipe.assistente_tecnico)
    setDataClienteLiberados(equipe.clientes)

    onChangeDocuments(null, resp.data)

    setCodEstadoCivil(usuario.cod_estado_civil)

    form.setFieldsValue({
      endereco_uf: checkCodUF(usuario.cod_cidade),
    })

    form.setFieldsValue({
      uf_nasc: checkCodUF(usuario.cid_nasc),
    })
    handleChangeUF(checkCodUF(usuario.cid_nasc))

    form.setFieldsValue({
      uf_conjuge: checkCodUF(usuario.cod_conjuge_naturalidade),
    })
    handleChangeUfConjuge(checkCodUF(usuario.cod_conjuge_naturalidade))

    usuario.nascimento = usuario.nascimento === null ? '' : dayjs(usuario.nascimento)
    usuario.cnh_validade = usuario.cnh_validade === null ? '' : dayjs(usuario.cnh_validade)

    let clientes: any[] = []
    if (equipe.acesso_cliente === 0 && equipe?.clientes?.length > 0) {
      const temp = equipe.clientes.map((item) => String(item.cod))
      clientes = temp
      setTargetKey(clientes)
      setTargetKey_referencia(clientes)
    } else {
      clientes = []
      setTargetKey([]);
      setTargetKey_referencia([]);
    }

    form.setFieldsValue({
      ...usuario,
      cod_perfil_conta: equipe.cod_perfil_conta,
      cod_perfil_cliente_geral: equipe.cod_perfil_cliente_geral,
      cod_perfil_cliente: equipe.cod_perfil_cliente,
      acesso_cliente: equipe.acesso_cliente,
      lider_tecnico: equipe.lider_equipe,
      cliente: clientes,
    })
    setAllClients(equipe.acesso_cliente > 0)

    message.destroy()
  }

  async function equipeTecnico(data) {
    const response: AxiosResponse<TypesTipoLiderEquipe> = await getTipoLiderEquipe({})

    const dados = response.data.itens.map((item) => {
      return { label: item.usuario_nome, value: item.cod_usuario }
    })

    setDataLideres(dados)

    const lider = equipeGeral.filter((item) => item.cod_func === data.cod_consultor)

    if (lider.length > 0) {
      const nameLider = lider[0].usuario_nome

      setNomeLider(nameLider)
      setLiderEquipe(lider[0].cod_func)
      setPossuiVinculo(true)
    } else {
      console.error('Líder não encontrado ou não possui usuario_nome.')
    }
  }

  async function equipeConsultor() {
    const resp: AxiosResponse<TypesTipoConsultor> = await getTipoConsultor({})
    const dados = resp.data.itens.map((item) => {
      return { label: item.usuario_nome, value: item.cod_usuario }
    })

    setDataConsultores(dados)
  }

  async function imgEquipe(usuario, codEquipe) {
    const imgOld = usuario?.doc_foto?.split('_')

    if (imgOld?.length <= 1) {
      try {
        const foto = await getEquipeFiles({
          doc: usuario.doc_foto,
          docType: 'doc_foto',
          cod: codEquipe,
        })

        const fileFoto = {
          uid: usuario.doc_foto,
          status: 'done',
          response: 'done',
          name: usuario.doc_foto,
          url: foto.url,
          preview: foto.fileString,
          thumbUrl: foto.fileString,
        }

        // @ts-ignore
        onChange({ fileList: [fileFoto] })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const tipoAcesso = localStorage.getItem('tipo-acesso')
  const codClienteLocal = localStorage.getItem('cod-cliente')

  /**remove os cods que não estavam na lista original de permissões, 
   * evitando assim o erro na requisição.
   * 
   * pois remove os itens que estavam presentes
   */
  function removerRecemAdicionados(clientes_remover: string[]) {
    // return clientes_remover;
    if (targetKey_referencia?.length === 0) {
      return []
    }
    return clientes_remover.filter(element => (targetKey_referencia).includes(element));
  }

  const onFinish = async (data) => {
    try {
      const initialData = { ...data }
      const fb = new FormData()
      data.doc_foto_arquivo = fileList[0]?.originFileObj
      data.doc_cpf_arquivo = data.doc_cpf?.file?.originFileObj
      data.doc_rg_arquivo = data.doc_rg?.file?.originFileObj
      data.doc_titulo_arquivo = data.doc_titulo?.file?.originFileObj
      data.doc_pis_arquivo = data.doc_pis?.file?.originFileObj
      data.doc_cnh_arquivo = data.doc_cnh?.file?.originFileObj
      data.doc_reservista_arquivo = data.doc_reservista?.file?.originFileObj
      data.doc_diploma_arquivo = data.doc_diploma?.file?.originFileObj
      data.doc_certidao_arquivo = data.doc_certidao?.file?.originFileObj
      data.doc_sus_arquivo = data.doc_sus?.file?.originFileObj

      delete data.doc_foto
      delete data.doc_rg
      delete data.doc_cpf
      delete data.doc_titulo
      delete data.doc_pis
      delete data.doc_cnh
      delete data.doc_reservista
      delete data.doc_diploma
      delete data.doc_certidao
      delete data.doc_sus

      data.nascimento = dayjs.isDayjs(data.nascimento) // Corrigido de data.nacimento para data.nascimento
        ? dayjs(data.nascimento).format('DD/MM/YYYY')
        : ''

      data.cnh_validade = dayjs.isDayjs(data.cnh_validade)
        ? dayjs(data.cnh_validade).format('DD/MM/YYYY')
        : ''

      if (semAcesso.length > 0 && update > 0) {
        data.cliente_remover = removerRecemAdicionados(semAcesso)
      }

      if (data.cod_perfil_cliente === undefined) {
        data.cod_perfil_cliente = null
      }

      if (data.cod_perfil_conta === undefined) {
        data.cod_perfil_conta = null
      }

      if (data.cod_perfil_cliente_geral === undefined) {
        data.cod_perfil_cliente_geral = null
      }

      Object.entries(data).forEach(([key, value]: [string, string | string[] | any]) => {
        if (value !== undefined) {
          if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
              value.map((item) => fb.append(`${key}[]`, item === null ? '' : item))
            } else {
              fb.append(key, value)
            }
          } else {
            fb.append(key, value === null ? '' : value)
          }
        }
      })

      /* Verifica os dados atuais com os que foram alterado para fazer o PUT */

      const changedData: any = {}

      let result = dataForm.map((item) => {
        const newEntry = {
          acesso_cliente: '',
          celular: item?.celular,
          cep: item?.cep,
          cid_nasc: item?.cid_nasc,
          cpf: item?.cpf,
          rg: item?.rg,
          uf_rg: item?.uf_rg,
          cnh: item.cnh,
          cnh_categoria: item?.cnh_categoria,
          reservista: item?.reservista,
          tipo_reservista: item?.tipo_reservista,
          pis: item?.pis,
          cliente: item?.cliente,
          cod_conjuge_naturalidade: item?.cod_conjuge_naturalidade,
          cod_escolaridade: item?.cod_escolaridade,
          cod_estado_civil: item?.cod_estado_civil,
          cod_perfil_cliente_geral: codsPerfil[0].codPerfilGeral,
          cod_perfil_cliente: codsPerfil[0].codPerfilCliente,
          cod_perfil_conta: codsPerfil[0].codPerfilConta,
          cod_profissao: item?.cod_profissao,
          complemento: item?.complemento,
          conjuge: item?.conjuge,
          conjuge_cpf: item?.conjuge_cpf,
          conjuge_rg: item?.conjuge_rg,
          doc_foto: item?.doc_foto,
          doc_sus: item?.doc_sus,
          email: item?.email,
          endereco: item?.endereco,
          fone: item?.fone,
          fone_mae: item?.fone_mae,
          fone_pai: item?.fone_pai,
          list_propriedade: item?.list_propriedade,
          mae: item?.mae,
          nacionalidade: item?.nacionalidade,
          nascimento: dayjs(item?.nascimento).format('DD/MM/YYYY'),
          nome: item?.nome,
          num_plano_saude: item?.num_plano_saude,
          observacoes: item?.observacoes,
          pai: item?.pai,
          plano_saude: item?.plano_saude,
          sexo: item?.sexo,
          status: item?.status,
          sus: item?.sus,
          tipo_sanguineo: item?.tipo_sanguineo,
          tipo_uniao: item?.tipo_uniao,
          uf_conjuge: item?.uf_conjuge,
          uf_nasc: item?.uf_nasc,
        }
        return newEntry
      }, [])

      const testes = result[0]

      function formatData(dataNasc) {
        let dataFormatNasc = dayjs.isDayjs(dataNasc) // Corrigido de data.nacimento para data.nascimento
          ? dayjs(dataNasc).format('DD/MM/YYYY')
          : 'Data Invalida'

        return dataFormatNasc
      }

      if (update > 0) {
        Object.entries(data).forEach(([key, value]) => {
          if (key !== 'nascimento') {
            if (testes[key] !== initialData[key]) {
              if (value !== null) {
                changedData[key] = value
              }
            }
          } else if (testes[key] !== initialData[key]) {
            if (value !== null) {
              changedData[key] = formatData(initialData[key])
            }
          }
        })
      }

      if (update > 0) {
        if (JSON.stringify(testes.cliente) === JSON.stringify(initialData.cliente)) {
          changedData.cliente = acesso
        } else {
          if (acesso.length > 0) {
            changedData.cliente = acesso
          } else {
            changedData.cliente = []
          }

          if (semAcesso.length > 0) {
            changedData.cliente_remover = removerRecemAdicionados(semAcesso)
          } else {
            changedData.cliente_remover = []
          }
        }
      }

      if (initialData.doc_foto !== fileList?.[0]?.originFileObj?.name) {
        changedData.doc_foto_arquivo = fileList?.[0]?.originFileObj
      }

      if (update === 0) {
        message.loading('Aguarde...', 999999)

        const response = await postEquipe(fb)

        if (response.status === 201) {
          message.destroy()
          Notification({
            message: 'Membro da equipe cadastrado com sucesso!',
            type: 'success',
          })
          form.resetFields()
          form.resetFields(['doc_foto'])
          atualizar()
          setFileList([])
          setDocuments({
            doc_cpf: [],
            doc_rg: [],
            doc_titulo: [],
            doc_cnh: [],
            doc_pis: [],
            doc_reservista: [],
            doc_diploma: [],
            doc_certidao: [],
            doc_sus: [],
            doc_ctps: [],
          })
        }
      } else {
        message.loading('Aguarde...')

        const response = await putEquipe(update, changedData)

        if (response.status === 200) {
          message.destroy()
          Notification({
            message: 'Membro da equipe atualizado com Sucesso!',
            type: 'success',
          })
          atualizar()
          const cod = update
          const resp: AxiosResponse<TypesEquipe> = await getEquipe({
            cod,
            embed: `usuario,clientes,tipo_assistente_tecnico,tipo_tecnico`,
          })

          const cliente = resp.data.itens[0].clientes.map((cod) => JSON.stringify(cod.cod))
          const dadosOriginais = resp.data.itens[0].usuario.map((item) => {
            return { ...item, cliente }
          })

          const codsPerfil = resp.data.itens.map((cod) => {
            return {
              codPerfilCliente: cod.cod_perfil_cliente,
              codPerfilGeral: cod.cod_perfil_cliente_geral,
              codPerfilConta: cod.cod_perfil_conta,
            }
          })
          setCodsPerfil(codsPerfil)

          setDataForm(dadosOriginais)
        }
      }
    } catch (error) {
      tryError(error)
      console.log("error: ", error)
    }
  }

  const onChange = ({ fileList: newFileList }: UploadChangeParam) => {
    setFileList(newFileList)
  }

  //funcao que traz os clientes liberados ao usuario e quando for igual a cliente puxa a function handleChangeAccessPro

  const handleChangeTabKey = (key) => {
    setTabActive(Number(key))

    if (key === '7') {
      validarClientesLiberados()
    }
  }

  function validarClientesLiberados() {
    let listClient = clientesAtivos
    let useHandle = false

    if (allClients && codClienteLocal !== '') {
      //setListDataClienteLiberado(listDataCliente)
      //handleChangeAccessPro(codCliente)
      useHandle = true
    } else {
      let data = []

      for (let i = 0; i < targetKey.length; i += 1) {
        const chave = targetKey[i]
        const cliente = clientesAtivos.filter((rec) => rec.cod === Number(chave))

        if (cliente.length > 0) {
          data = [...data, ...cliente]
        }
      }
      if (data.length > 0 && accessCliente == 0) {
        //setListDataClienteLiberado(data)
        listClient = data
      }
      if (data.length === 0 && codClienteLocal == '' && allClients === false) {
        setNoInput(true)
        listClient = []
        Notification({
          message: 'O usuário não possui nenhum cliente liberado',

          type: 'error',
        })
      }

      if (!allClients && codClienteLocal !== '') {
        useHandle = true
      }
    }

    setListDataClienteLiberado(listClient)

    if (useHandle) {
      handleChangeAccessPro(codCliente)
    }
  }

  const handleChangeLider = async (value) => {
    try {
      if (value && cargoTecnico === true) {
        const resp = await postTipoEquipeLiderGerenciar({
          cod_consultor: value,
          tecnicos: [codFunc],
        })

        if (resp.status === 201) {
          Notification({
            message: 'Líder atualizado com sucesso',
            type: 'success',
          })
          setNomeLider('')
          setUpdateKey((prevKey) => prevKey + 1)
        }
      } else if (value && cargoAt === true) {
        const resp = await postTipoEquipeConsultorGerenciar({
          cod_consultor: value,
          assistente_tecnico: [codFunc],
        })

        if (resp.status === 201) {
          Notification({
            message: 'Líder atualizado com sucesso',
            type: 'success',
          })
          setNomeLider('')
          setUpdateKey((prevKey) => prevKey + 1)
        }
      }
    } catch (error) {
      tryError(error)
    }
  }

  const handleClearAcesso = (formItem, value) => {
    modal.confirm({
      title: 'Deseja remover o perfil de acesso do usuário?',
      onCancel: () => form.setFieldValue(formItem, value),
      okText: 'Sim',
      cancelText: 'Não',
      autoFocusButton: 'cancel',
      okType: 'primary',
      okButtonProps: { type: 'primary' },
      cancelButtonProps: { danger: true, type: 'primary' },
    })
  }

  const handleClearLiderEquipe = (value) => {
    modal.confirm({
      title: `Deseja remover ${usuario} da equipe do ${nomeLider}?`,
      onCancel: () => form.setFieldValue(nomeLider, value),
      onOk: async () => {
        try {
          if (cargoTecnico === true) {
            const resp = await delTipoEquipeLiderGerenciar(value, codFunc)

            if (resp.status === 200) {
              Notification({
                message: 'Líder removido com sucesso',
                type: 'success',
              })

              setPossuiVinculo(false)
              // Atualiza a chave de atualização para forçar a re-renderização
              setUpdateKey((prevKey) => prevKey + 1)
            }
          } else {
            const resp = await delTipoEquipeConsultorGerenciar(value, codFunc)

            if (resp.status === 200) {
              Notification({
                message: 'Líder removido com sucesso',
                type: 'success',
              })

              setPossuiVinculo(false)
              // Atualiza a chave de atualização para forçar a re-renderização
              setUpdateKey((prevKey) => prevKey + 1)
            }
          }
        } catch (error) {
          tryError(error)
        }
      },
      okText: 'Sim',
      cancelText: 'Não',
      autoFocusButton: 'cancel',
      okType: 'primary',
      okButtonProps: { type: 'primary' },
      cancelButtonProps: { danger: true, type: 'primary' },
    })
  }

  const onChangeDocuments = async (tipo, data: TypesEquipe = {} as TypesEquipe) => {
    if (tipo === null) {
      const usuario = data.itens[0].usuario[0]
      const codEquipe = data.itens[0].cod

      let temp = documents
      Object.entries(usuario).forEach(async ([key, value]) => {
        const oldImg = String(value).split('_')

        if (oldImg.length <= 1) {
          if (
            key === 'doc_certidao' ||
            key === 'doc_pis' ||
            key === 'doc_reservista' ||
            key === 'doc_cpf' ||
            key === 'doc_rg' ||
            key === 'doc_cnh' ||
            key === 'doc_ctps' ||
            key === 'doc_sus' ||
            key === 'doc_titulo' ||
            key === 'doc_diploma'
          ) {
            if (value !== null) {
              const foto = await getEquipeFiles({
                doc: value,
                // @ts-ignore
                docType: key,
                cod: codEquipe,
              })

              temp = { ...temp, [key]: [foto.fileString] }
            }
          }
        }

        setDocuments(temp)
      })
    } else {
      setDocuments({ ...documents, ...tipo })
    }
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  function onRemove() {
    setFileList([])
  }

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }

    Modal.info({
      title: 'Foto',
      width: '500px',
      okText: 'Fechar',
      icon: <Svg component={() => <FontAwesomeIcon icon={regular('image')} />} />,
      content: <img width='100%' src={src} alt='foto' />,
    })
  }

  const onPreviewDocuments = async (file, type = null) => {
    let src = documents[file].file
    if (type === null) type = documents[file].file?.type

    if (src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(documents[file].file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    } else {
      src = documents[file]
      type = getB64Type(src)
    }

    switch (file) {
      case 'doc_cpf':
        file = 'CPF'
        break
      case 'doc_rg':
        file = 'Rg'
        break
      case 'doc_titulo':
        file = 'Título de Eleitor'
        break
      case 'doc_reservista':
        file = 'Reservista'
        break
      case 'doc_pis':
        file = 'Pis'
        break
      case 'doc_diploma':
        file = 'Diploma'
        break
      case 'doc_cnh':
        file = 'Carteira de Habilitação'
        break
      case 'doc_certidao':
        file = 'Certidão'
        break
      case 'doc_sus':
        file = 'Cartão SUS'
        break

      default:
        file = 'Documento'
        break
    }

    Modal.info({
      title: file,
      width: '1024px',
      style: { top: '10px' },
      okText: 'Fechar',
      icon: (
        <Svg
          component={() => (
            <FontAwesomeIcon
              icon={type === 'application/pdf' ? regular('file-pdf') : regular('image')}
            />
          )}
        />
      ),
      content: (
        <embed
          width={type === 'application/pdf' ? '100%' : null}
          style={{
            minHeight: '80vh',
            maxHeight: '80vh',
            maxWidth: type !== 'application/pdf' ? '100%' : null,
          }}
          src={src}
        />
      ),
    })
  }

  const handleRemoveFile = (e, tipo) => {
    if (e.button === 1) {
      Modal.confirm({
        title: `Deseja remover o ${String(tipo).toLocaleUpperCase()}?`,
        okText: 'Sim',
        cancelText: 'Não',
        icon: <IconAlert type='confirm' size={6} />,
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          switch (tipo) {
            case 'doc_cpf':
              setDocuments({ ...documents, doc_cpf: [] })
              break
            case 'doc_rg':
              setDocuments({ ...documents, doc_rg: [] })
              break
            case 'doc_titulo':
              setDocuments({ ...documents, doc_titulo: [] })
              break
            case 'doc_reservista':
              setDocuments({ ...documents, doc_reservista: [] })
              break
            case 'doc_pis':
              setDocuments({ ...documents, doc_pis: [] })
              break
            case 'doc_diploma':
              setDocuments({ ...documents, doc_diploma: [] })
              break
            case 'doc_cnh':
              setDocuments({ ...documents, doc_cnh: [] })
              break
            case 'doc_certidao':
              setDocuments({ ...documents, doc_certidao: [] })
              break
            case 'doc_sus':
              setDocuments({ ...documents, doc_sus: [] })
              break

            default:
              break
          }
        },
      })
    }
  }

  const onFinishFailed = (errorInfo) => {
    errorInfo.errorFields.forEach((field) => {
      notification.error({
        message: 'Campo Obrigatório',
        description: `O campo "${field?.name?.[0] ?? ''}" é obrigatório.`,
        placement: 'topRight',
      })
    })
  }

  useEffect(() => {
    if (update > 0) {
      editEquipe(update)
    }

    if (update > 0 && codConsultor === null) {
      setNomeLider('')
    }
  }, [update, updateKey, nomeLider])

  useEffect(() => {
    if (emailNew?.length > 0) {
      form.setFieldsValue({ email: emailNew })
    }
  }, [emailNew])

  useEffect(() => {}, [possuiVinculo])

  useEffect(() => {
    if (allClients) {
      setListDataClienteLiberado([])
    }
  }, [allClients])

  useEffect(() => {
    setTabActive(1)
    listClientes()
    dataListCitys()
    listState()
    listEscola()
    listaEstadoCivil()
    listaProfissao()

    listProfiles()
    if (loggedInAs.type !== 'CLIENTE') {
      listCliente()
    }
  }, [usuario])

  useEffect(() => {
    if (tabActive === 6) {
      if (cargoTecnico === true) {
        form.setFieldsValue({ cod_lider_tecnico: liderEquipe })
      } else {
        form.setFieldsValue({ cod_lider_tecnico: codConsultor })
      }
    }
  }, [tabActive])

  

  return (
    <Container>
      <Form
        form={form}
        encType='multipart/form-data'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Drawer
          className='drawer'
          size='default'
          style={{ position: 'absolute' }}
          placement='right'
          width='100%'
          onClose={handleDrawerClose}
          open={visible}
          closeIcon={false}
          getContainer={false}
          footer={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Button type='primary' onClick={handleCheckInputs} htmlType='button'>
                {update ? 'Atualizar' : 'Salvar'}
              </Button>
              {'   '}
              <Button danger type='primary' onClick={handleDrawerClose} style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>
          }
        >
          {/* Mostra o usuário no canto superior esquerdo */}
          {usuario && <span className='membro-equipe'>Nome: {usuario}</span>}

          <Tabs
            className='ant-tabs-green'
            onChange={(key) => handleChangeTabKey(key)}
            activeKey={String(tabActive)}
            type='card'
            size='small'
          >
            <TabPane
              forceRender
              key={'1'}
              tab='Membro Equipe'
              style={{ height: 'calc(100vh - 250px)', overflow: 'auto', overflowX: 'hidden' }}
            >
              <TabContentME responsive={responsive}>
                <div className='inputs-avatar'>
                  <Form.Item name='doc_foto' className='avatar-member-equipe'>
                    <ImgCrop modalOk='Salvar' modalTitle='Foto' aspect={500 / 500} rotationSlider>
                      <Upload
                        ref={refFoto}
                        listType='picture-card'
                        className='avatar-uploader'
                        customRequest={dummyRequest}
                        fileList={fileList}
                        maxCount={1}
                        accept='image/png, image/jpeg, image/jpg'
                        onChange={onChange}
                        onPreview={onPreview}
                        onRemove={onRemove}
                      >
                        {fileList.length < 1 && 'Foto'}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>

                  <div>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          initialValue=''
                          rules={[{ required: true }]}
                          label='Nome completo'
                          name='nome'
                        >
                          <Input readOnly={permissions?.acesso === 1} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          initialValue=''
                          rules={[{ required: true, type: 'email' }]}
                          label='Email  '
                          name='email'
                        >
                          <Input readOnly={permissions?.acesso === 1} type='email' />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          className='escolaridade'
                          initialValue=''
                          rules={[{ required: false }]}
                          label='Escolaridade'
                          name='cod_escolaridade'
                        >
                          <Select
                            className={permissions?.acesso === 1 ? 'readOnly' : ''}
                            showSearch
                            popupMatchSelectWidth={false}
                            filterOption={(input, option) =>
                              option.children
                                // @ts-ignore
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {listEscolaridades.map((data) => (
                              <Option key={data.cod} value={data.cod}>
                                {data.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          initialValue=''
                          rules={[{ required: true }]}
                          label='Sexo'
                          name='sexo'
                        >
                          <Radio.Group
                            style={{
                              pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
                            }}
                            optionType='button'
                            buttonStyle='solid'
                            options={[
                              {
                                label: 'Masculino',
                                value: 'M',
                              },
                              {
                                label: 'Feminino',
                                value: 'F',
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item initialValue='' label='Data nascimento' name='nascimento'>
                          <DatePicker
                            inputReadOnly={permissions?.acesso === 1}
                            allowClear={false}
                            popupStyle={{
                              display: permissions?.acesso === 1 ? 'none' : '',
                            }}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          initialValue=''
                          rules={[{ required: false }]}
                          label='Celular'
                          name='celular'
                        >
                          <InputMask readOnly={permissions?.acesso === 1} mask='(99)99999-9999'>
                            {/* @ts-ignore */}
                            {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                          </InputMask>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          initialValue=''
                          rules={[{ required: false }]}
                          label='Telefone fixo'
                          name='fone'
                        >
                          <InputMask readOnly={permissions?.acesso === 1} mask='(99)9999-9999'>
                            {/* @ts-ignore */}
                            {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                          </InputMask>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          className='nacionalidade'
                          initialValue=''
                          rules={[{ required: true }]}
                          label='Nacionalidade'
                          name='nacionalidade'
                        >
                          <Input readOnly={permissions?.acesso === 1} type='text' />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          initialValue=''
                          // style={{ maxWidth: '92px' }}
                          rules={[{ required: true }]}
                          label='Estado nascimento'
                          name='uf_nasc'
                        >
                          <Select
                            className={permissions?.acesso === 1 ? 'readOnly' : ''}
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                // @ts-ignore
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChangeUF}
                          >
                            {listUF.map((data) => (
                              <Option key={data.cod} value={data.cod}>
                                {data.sigla}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          initialValue=''
                          rules={[{ required: true }]}
                          label='Cidade nascimento'
                          name='cid_nasc'
                        >
                          <Select
                            className={permissions?.acesso === 1 ? 'readOnly' : ''}
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                // @ts-ignore
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={listCity.length <= 0}
                          >
                            {listCity.map((data) => (
                              <Option key={data.cod} value={data.cod}>
                                {data.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          className='cep'
                          initialValue=''
                          rules={[{ required: false }]}
                          label='Cep'
                          name='cep'
                        >
                          <InputMask readOnly={permissions?.acesso === 1} mask='99999-999'>
                            {/* @ts-ignore */}
                            {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                          </InputMask>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          initialValue=''
                          rules={[{ required: true }]}
                          label='Endereço'
                          name='endereco'
                        >
                          <Input readOnly={permissions?.acesso === 1} type='text' />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item
                          initialValue=''
                          rules={[{ required: false }]}
                          label='Complemento'
                          name='complemento'
                        >
                          <Input readOnly={permissions?.acesso === 1} type='text' />
                        </Form.Item>
                      </Col>
                      
                    </Row>
                  </div>
                </div>
              </TabContentME>
            </TabPane>
            <TabPane
              forceRender
              key={'2'}
              tab='Dados complementares'
              style={{ height: 'calc(100vh - 250px)', overflow: 'auto', overflowX: 'hidden' }}
            >
              <div className='inputs-avatar' style={{ paddingRight: '20px' }}>
                <div>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                      <Form.Item
                        initialValue=''
                        style={{ width: '100%' }}
                        rules={[{ required: false }]}
                        label='Profissão'
                        name='cod_profissao'
                      >
                        <Select
                          className={permissions?.acesso === 1 ? 'readOnly' : ''}
                          style={{ width: '100%' }}
                        >
                          {listDataProfissao.map((data) => (
                            <Option key={data.cod} value={data.cod}>
                              {data.nome}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='Estado civil'
                        name='cod_estado_civil'
                      >
                        <Select
                          className={permissions?.acesso === 1 ? 'readOnly' : ''}
                          onChange={handleChangeEstadoCivil}
                        >
                          {listEstadoCivis.map((data) => (
                            <Option key={data.cod} value={data.cod}>
                              {data.nome}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                      <Form.Item
                        initialValue=''
                        // hidden={codEstadoCivil !== 2}
                        // style={{maxWidth: '450px', minWidth: '200px', width: '200px'}}
                        rules={[{ required: false }]}
                        label='Tipo de união'
                        name='tipo_uniao'
                      >
                        <Select
                          disabled={codEstadoCivil !== 2}
                          className={permissions?.acesso === 1 ? 'readOnly' : ''}
                          options={[
                            {
                              label: 'Comunhão universal de bens',
                              value: 'Comunhão universal de bens',
                            },
                            {
                              label: 'Comunhão parcial de bens',
                              value: 'Comunhão parcial de bens',
                            },
                            {
                              label: 'Separação de bens',
                              value: 'Separação de bens',
                            },
                            {
                              label: 'União estável',
                              value: 'União estável',
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={10}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='Cônjuge'
                        name='conjuge'
                      >
                        <Input readOnly={permissions?.acesso === 1} type='text' />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[8, 8]}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='CPF cônjuge'
                        name='conjuge_cpf'
                      >
                        <Input readOnly={permissions?.acesso === 1} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='RG cônjuge'
                        name='conjuge_rg'
                      >
                        <Input readOnly={permissions?.acesso === 1} type='text' />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='UF cônjuge'
                        name='uf_conjuge'
                      >
                        <Select
                          className={permissions?.acesso === 1 ? 'readOnly' : ''}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              // @ts-ignore
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={handleChangeUfConjuge}
                        >
                          {listUF.map((data) => (
                            <Option key={data.cod} value={data.cod}>
                              {data.sigla}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='Cidade cônjuge'
                        name='cod_conjuge_naturalidade'
                      >
                        <Select
                          className={permissions?.acesso === 1 ? 'readOnly' : ''}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              // @ts-ignore
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={listCityConjuge.length <= 0}
                        >
                          {listCityConjuge.map((data) => (
                            <Option key={data.cod} value={data.cod}>
                              {data.nome}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='Mãe'
                        name='mae'
                      >
                        <Input readOnly={permissions?.acesso === 1} type='text' />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='Celular da mãe'
                        name='fone_mae'
                      >
                        <InputMask readOnly={permissions?.acesso === 1} mask='(99)99999-9999'>
                          {/* @ts-ignore */}
                          {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                        </InputMask>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='Pai'
                        name='pai'
                      >
                        <Input readOnly={permissions?.acesso === 1} type='text' />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='Celular do pai'
                        name='fone_pai'
                      >
                        <InputMask readOnly={permissions?.acesso === 1} mask='(99)99999-9999'>
                          {/* @ts-ignore */}
                          {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                        </InputMask>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={20} sm={12} md={8} lg={6} xl={9}>
                      <Form.Item
                        initialValue=''
                        rules={[{ required: false }]}
                        label='Observação'
                        name='observacoes'
                      >
                        <Input.TextArea readOnly={permissions?.acesso === 1} rows={3} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </TabPane>
            <TabPane
              className='table-document'
              style={{ height: 'calc(100vh - 250px)', overflow: 'auto', overflowX: 'hidden' }}
              key={3}
              tab={
                <>
                  {form.getFieldWarning('cpf').length > 0 ? <Badge status='error' /> : null}
                  Documentos
                </>
              }
            >
              <div className='parent'>
                <div>
                  <Space>
                    <Form.Item
                      style={{ width: '19.75rem' }}
                      initialValue=''
                      rules={[{ required: true }]}
                      label='CPF'
                      name='cpf'
                    >
                      <InputMask
                        readOnly={permissions?.acesso === 1}
                        mask='999.999.999-99'
                        // style={{ width: '18rem', maxWidth: '50rem' }}
                      >
                        {/* @ts-ignore */}
                        {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                    <Form.Item
                      initialValue=''
                      label=' '
                      rules={[{ required: false }]}
                      name='doc_cpf'
                    >
                      <Upload
                        className='upload-ico'
                        customRequest={dummyRequest}
                        maxCount={1}
                        accept='image/png, image/jpeg, .pdf'
                        onChange={(file) => onChangeDocuments({ doc_cpf: file })}
                        onPreview={onPreview}
                        showUploadList={false}
                      >
                        <Button
                          type='primary'
                          icon={
                            <Svg component={() => <FontAwesomeIcon icon={solid('upload')} />} />
                          }
                        />
                      </Upload>
                    </Form.Item>
                    <Form.Item initialValue='' label=' ' rules={[{ required: false }]}>
                      <Button
                        disabled={documents.doc_cpf.length <= 0}
                        onClick={() => onPreviewDocuments('doc_cpf')}
                        onMouseUp={(event) => handleRemoveFile(event, 'doc_cpf')}
                        type='default'
                        icon={<Svg component={() => <FontAwesomeIcon icon={solid('eye')} />} />}
                      />
                    </Form.Item>
                  </Space>
                </div>
                <div>
                  <Space>
                    <Form.Item initialValue='' rules={[{ required: false }]} label='RG' name='rg'>
                      <Input readOnly={permissions?.acesso === 1} style={{ width: '7.5rem' }} />
                    </Form.Item>
                    <Form.Item
                      initialValue=''
                      rules={[{ required: false }]}
                      label='UF RG'
                      name='uf_rg'
                    >
                      <Input readOnly={permissions?.acesso === 1} />
                    </Form.Item>
                    <Form.Item
                      initialValue=''
                      label=' '
                      rules={[{ required: false }]}
                      name='doc_rg'
                    >
                      <Upload
                        className='upload-ico'
                        customRequest={dummyRequest}
                        maxCount={1}
                        accept='image/png, image/jpeg, .pdf'
                        onChange={(file) =>
                          onChangeDocuments({
                            doc_rg: file,
                          })
                        }
                        onPreview={onPreview}
                        showUploadList={false}
                      >
                        <Button
                          type='primary'
                          icon={
                            <Svg component={() => <FontAwesomeIcon icon={solid('upload')} />} />
                          }
                        />
                      </Upload>
                    </Form.Item>
                    <Form.Item initialValue='' label=' ' rules={[{ required: false }]}>
                      <Button
                        disabled={documents.doc_rg.length <= 0}
                        onClick={() => onPreviewDocuments('doc_rg')}
                        onMouseUp={(event) => handleRemoveFile(event, 'doc_rg')}
                        type='default'
                        icon={<Svg component={() => <FontAwesomeIcon icon={solid('eye')} />} />}
                      />
                    </Form.Item>
                  </Space>
                </div>
                <div>
                  {' '}
                  <Space>
                    <Form.Item
                      initialValue=''
                      style={{ width: '19.75rem' }}
                      rules={[{ required: false }]}
                      label='Diplomas/Certificados'
                      name='diploma'
                    >
                      <Input readOnly={permissions?.acesso === 1} />
                    </Form.Item>
                    <Form.Item
                      initialValue=''
                      label=' '
                      rules={[{ required: false }]}
                      name='doc_diploma'
                    >
                      <Upload
                        className='upload-ico'
                        customRequest={dummyRequest}
                        maxCount={1}
                        accept='image/png, image/jpeg, .pdf'
                        onChange={(file) =>
                          onChangeDocuments({
                            doc_diplomas: file,
                          })
                        }
                        onPreview={onPreview}
                        showUploadList={false}
                      >
                        <Button
                          type='primary'
                          icon={
                            <Svg component={() => <FontAwesomeIcon icon={solid('upload')} />} />
                          }
                        />
                      </Upload>
                    </Form.Item>
                    <Form.Item initialValue='' label=' ' rules={[{ required: false }]}>
                      <Button
                        disabled={documents.doc_diploma.length <= 0}
                        onClick={() => onPreviewDocuments('doc_diploma')}
                        onMouseUp={(event) => handleRemoveFile(event, 'doc_diploma')}
                        type='default'
                        icon={<Svg component={() => <FontAwesomeIcon icon={solid('eye')} />} />}
                      />
                    </Form.Item>
                  </Space>
                </div>
                <div>
                  <Form.Item
                    style={{ width: '24.75rem' }}
                    initialValue=''
                    rules={[{ required: false }]}
                    label='Reservista'
                    name='reservista'
                  >
                    <Input readOnly={permissions?.acesso === 1} />
                  </Form.Item>
                </div>
                <div>
                  {' '}
                  <Space>
                    <Form.Item
                      style={{ width: '19.75rem' }}
                      initialValue=''
                      rules={[{ required: false }]}
                      label='Tipo reservista'
                      name='tipo_reservista'
                    >
                      <Select
                        className='form-select'
                        style={{
                          pointerEvents: permissions?.acesso === 1 ? 'none' : 'all',
                        }}
                        showSearch
                        popupMatchSelectWidth={false}
                        optionFilterProp='label'
                        options={[
                          {
                            label: 'Certificado de alistamento militar',
                            value: 'Certificado de alistamento militar',
                          },
                          {
                            label: 'Certificado de reservista de 1ª ou 2ª categoria',
                            value: 'Certificado de reservista de 1ª ou 2ª categoria',
                          },
                          {
                            label:
                              'Certificado de dispensa de incorporação (antigo certificado de reservista de 3ª categoria)',
                            value:
                              'Certificado de dispensa de incorporação (antigo certificado de reservista de 3ª categoria)',
                          },
                          { label: 'Certificado de isenção', value: 'Certificado de isenção' },
                          {
                            label: 'Certificado de situação militar',
                            value: 'Certificado de situação militar',
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      initialValue=''
                      label=' '
                      rules={[{ required: false }]}
                      name='doc_reservista'
                    >
                      <Upload
                        className='upload-ico'
                        customRequest={dummyRequest}
                        maxCount={1}
                        accept='image/png, image/jpeg, .pdf'
                        onChange={(file) =>
                          onChangeDocuments({
                            doc_reservista: file,
                          })
                        }
                        onPreview={onPreview}
                        showUploadList={false}
                      >
                        <Button
                          type='primary'
                          icon={
                            <Svg component={() => <FontAwesomeIcon icon={solid('upload')} />} />
                          }
                        />
                      </Upload>
                    </Form.Item>
                    <Form.Item initialValue='' label=' ' rules={[{ required: false }]}>
                      <Button
                        disabled={documents.doc_reservista.length <= 0}
                        onClick={() => onPreviewDocuments('doc_reservista')}
                        onMouseUp={(event) => handleRemoveFile(event, 'doc_reservista')}
                        type='default'
                        icon={<Svg component={() => <FontAwesomeIcon icon={solid('eye')} />} />}
                      />
                    </Form.Item>
                  </Space>
                </div>

                <div>
                  <Form.Item
                    style={{ width: '24.75rem' }}
                    initialValue=''
                    rules={[{ required: false }]}
                    label='CNH'
                    name='cnh'
                  >
                    <Input readOnly={permissions?.acesso === 1} />
                  </Form.Item>
                </div>
                <div>
                  <Space>
                    <Form.Item
                      initialValue=''
                      rules={[{ required: false }]}
                      label='Categoria CNH'
                      name='cnh_categoria'
                    >
                      <Input readOnly={permissions?.acesso === 1} className='form-input' />
                    </Form.Item>
                    <Form.Item
                      initialValue=''
                      rules={[{ required: false }]}
                      label='Validade CNH'
                      name='cnh_validade'
                    >
                      {/* @ts-ignore */}
                      <DatePicker placeholder='' />
                    </Form.Item>
                    <Form.Item
                      initialValue=''
                      label=' '
                      rules={[{ required: false }]}
                      name='doc_cnh'
                    >
                      <Upload
                        className='upload-ico'
                        customRequest={dummyRequest}
                        maxCount={1}
                        accept='image/png, image/jpeg, .pdf'
                        onChange={(file) =>
                          onChangeDocuments({
                            doc_cnh: file,
                          })
                        }
                        onPreview={onPreview}
                        showUploadList={false}
                      >
                        <Button
                          type='primary'
                          icon={
                            <Svg component={() => <FontAwesomeIcon icon={solid('upload')} />} />
                          }
                        />
                      </Upload>
                    </Form.Item>
                    <Form.Item initialValue='' label=' ' rules={[{ required: false }]}>
                      <Button
                        disabled={documents.doc_cnh.length <= 0}
                        onClick={() => onPreviewDocuments('doc_cnh')}
                        onMouseUp={(event) => handleRemoveFile(event, 'doc_cnh')}
                        type='default'
                        icon={<Svg component={() => <FontAwesomeIcon icon={solid('eye')} />} />}
                      />
                    </Form.Item>
                  </Space>
                </div>
                <div>
                  <Form.Item
                    style={{ width: '24.75rem' }}
                    initialValue=''
                    rules={[{ required: false }]}
                    label='PIS'
                    name='pis'
                    // style={{ maxWidth: '150px' }}
                  >
                    <Input readOnly={permissions?.acesso === 1} />
                  </Form.Item>
                </div>
                <div>
                  <Space>
                    <Form.Item
                      initialValue=''
                      className='form-item'
                      style={{ width: '24.75rem' }}
                      rules={[{ required: false }]}
                      label='CTPS'
                      name='ctps'
                    >
                      <Input readOnly={permissions?.acesso === 1} />
                    </Form.Item>
                  </Space>
                </div>

                <div>
                  <Space>
                    <Form.Item
                      initialValue=''
                      style={{ width: '19.75rem' }}
                      rules={[{ required: false }]}
                      label='Série CTPS'
                      name='ctps_serie'
                    >
                      <Input readOnly={permissions?.acesso === 1} />
                    </Form.Item>
                    <Form.Item
                      initialValue=''
                      label=' '
                      rules={[{ required: false }]}
                      name='doc_pis'
                    >
                      <Upload
                        className='upload-ico'
                        customRequest={dummyRequest}
                        maxCount={1}
                        accept='image/png, image/jpeg, .pdf'
                        onChange={(file) =>
                          onChangeDocuments({
                            doc_pis: file,
                          })
                        }
                        onPreview={onPreview}
                        showUploadList={false}
                      >
                        <Button
                          type='primary'
                          icon={
                            <Svg component={() => <FontAwesomeIcon icon={solid('upload')} />} />
                          }
                        />
                      </Upload>
                    </Form.Item>
                    <Form.Item initialValue='' label=' ' rules={[{ required: false }]}>
                      <Button
                        disabled={documents.doc_pis.length <= 0}
                        onClick={() => onPreviewDocuments('doc_pis')}
                        onMouseUp={(event) => handleRemoveFile(event, 'doc_pis')}
                        type='default'
                        icon={<Svg component={() => <FontAwesomeIcon icon={solid('eye')} />} />}
                      />
                    </Form.Item>
                  </Space>
                </div>
              </div>
            </TabPane>
            <TabPane forceRender key={'4'} tab='Saúde'>
              <div className='container-saude'>
                <div>
                  <Form.Item
                    style={{ maxWidth: '350px' }}
                    initialValue=''
                    rules={[{ required: false }]}
                    label='Tipo sanguíneo'
                    name='tipo_sanguineo'
                  >
                    <Select
                      className={permissions?.acesso === 1 ? 'readOnly' : ''}
                      options={[
                        {
                          label: 'A+',
                          value: 'A+',
                        },
                        {
                          label: 'A-',
                          value: 'A-',
                        },
                        {
                          label: 'B+',
                          value: 'B+',
                        },
                        {
                          label: 'B-',
                          value: 'B-',
                        },
                        {
                          label: 'AB+',
                          value: 'AB+',
                        },
                        {
                          label: 'AB-',
                          value: 'AB-',
                        },
                        {
                          label: 'O+',
                          value: 'O+',
                        },
                        {
                          label: 'O-',
                          value: 'O-',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Space>
                    <Form.Item
                      style={{ maxWidth: '100%' }}
                      initialValue=''
                      rules={[{ required: false }]}
                      label='Cartão sus'
                      name='sus'
                    >
                      <Input readOnly={permissions?.acesso === 1} style={{ width: '17rem' }} />
                    </Form.Item>
                    <Form.Item
                      initialValue=''
                      label=' '
                      rules={[{ required: false }]}
                      name='doc_sus'
                    >
                      <Upload
                        className='upload-ico'
                        customRequest={dummyRequest}
                        maxCount={1}
                        accept='image/png, image/jpeg, .pdf'
                        onChange={(file) => onChangeDocuments({ doc_doc_sus: file })}
                        onPreview={onPreview}
                        showUploadList={false}
                      >
                        <Button
                          type='primary'
                          icon={
                            <Svg component={() => <FontAwesomeIcon icon={solid('upload')} />} />
                          }
                        />
                      </Upload>
                    </Form.Item>
                    <Form.Item initialValue='' label=' ' rules={[{ required: false }]}>
                      <Button
                        disabled={documents.doc_sus.length <= 0}
                        onClick={() => onPreviewDocuments('doc_sus')}
                        onMouseUp={(event) => handleRemoveFile(event, 'doc_sus')}
                        type='default'
                        className='info-button'
                        icon={<Svg component={() => <FontAwesomeIcon icon={solid('eye')} />} />}
                      />
                    </Form.Item>
                  </Space>
                </div>
                <div style={{width: '200px'}}>
                  <Form.Item
                    style={{ maxWidth: '350px' }}
                    initialValue=''
                    rules={[{ required: false }]}
                    label='Plano de saúde particular'
                    name='plano_saude'
                  >
                    <Input readOnly={permissions?.acesso === 1} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    style={{ maxWidth: '350px' }}
                    initialValue=''
                    id='form-plano-saude'
                    rules={[{ required: false }]}
                    label='Nº cartão plano de saúde'
                    name='num_plano_saude'
                  >
                    <Input readOnly={permissions?.acesso === 1} />
                  </Form.Item>
                </div>
              </div>
            </TabPane>
            {/* <TabPane forceRender key={'5'} tab='Dependentes'>
             
            </TabPane> */}
            {acessoPermissoes[0]?.acesso === 1 ? (
              <>
                <TabPane
                  disabled={codFunc === null}
                  forceRender
                  key={6}
                  tab='Acessos e permissões'
                  style={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}
                >
                  {possuiVinculo === true ? (
                    <>
                      <Row gutter={[8, 8]}>
                        <div>
                          <Col span={24}>
                            <Col span={24}>
                              <Alert
                                key={updateKey}
                                message={
                                  codConsultor === null ? (
                                    <span>
                                      O {cargoTecnico !== false ? 'Técnico' : 'Assistente técnico'}{' '}
                                      <strong>{usuario}</strong> não pertence a nenhuma equipe{' '}
                                    </span>
                                  ) : (
                                    <span>
                                      O {cargoTecnico !== false ? 'Técnico' : 'Assistente técnico'}{' '}
                                      <strong>{usuario}</strong> pertence a equipe do{' '}
                                      {cargoTecnico === false ? 'consultor' : 'líder'}{' '}
                                      <strong>{nomeLider}</strong>
                                    </span>
                                  )
                                }
                                type='info'
                                showIcon
                                style={{ maxWidth: '100%', width: '100%', marginBottom: '2rem' }}
                              />
                            </Col>

                            <Col span={24}>
                              <Form.Item
                                name='cod_perfil_conta'
                                label='Selecione um perfil de acesso a consultoria'
                                initialValue=''
                              >
                                <Select
                                  allowClear
                                  className={permissions?.acesso === 1 ? 'readOnly' : ''}
                                  style={{ maxWidth: '400px' }}
                                  onClear={() =>
                                    handleClearAcesso(
                                      'cod_perfil_conta',
                                      form.getFieldValue('cod_perfil_conta')
                                    )
                                  }
                                >
                                  {listDataProfiles.map((item) => (
                                    <Select.Option key={item.cod} value={item.cod}>
                                      {item.nome}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name='cod_perfil_cliente_geral'
                                initialValue=''
                                label={
                                  <Space>
                                    Selecione um perfil de acesso aos clientes
                                    <small style={{ opacity: '0.5' }}>(Opcional)</small>
                                  </Space>
                                }
                              >
                                <Select
                                  allowClear
                                  className={permissions?.acesso === 1 ? 'readOnly' : ''}
                                  style={{ maxWidth: '400px' }}
                                  onClear={() =>
                                    handleClearAcesso(
                                      'cod_perfil_cliente_geral',
                                      form.getFieldValue('cod_perfil_cliente_geral')
                                    )
                                  }
                                >
                                  {listDataProfiles.map((item) => (
                                    <Select.Option key={item.cod} value={item.cod}>
                                      {item.nome}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Col>
                        </div>
                      </Row>
                      <Row
                        gutter={[8, 8]}
                        style={{
                          display: 'flex',
                          width: '50rem',
                        }}
                      >
                        <div style={{ maxWidth: '100%' }}>
                          <Col>
                            <Form.Item
                              key={updateKey}
                              name='cod_lider_tecnico'
                              label={
                                <>
                                  {cargoTecnico === true
                                    ? 'Líder do Técnico'
                                    : 'Consultor do Assistente Técnico'}
                                  <small style={{ opacity: '0.5' }}>(Opcional)</small>
                                </>
                              }
                            >
                              <Select
                                disabled={
                                  cargoTecnico === true
                                    ? acessoPermissoes[1].inclusao === 0
                                    : acessoPermissoes[2].inclusao === 0
                                }
                                allowClear
                                className={permissions?.acesso === 1 ? 'readOnly' : ''}
                                style={{ maxWidth: '400px' }}
                                onChange={handleChangeLider}
                                onClear={() => handleClearLiderEquipe(codConsultor)}
                              >
                                {cargoTecnico === true ? (
                                  <>
                                    {dataLideres.map((item) => (
                                      <Select.Option key={item.value} value={item.value}>
                                        {item.label}
                                      </Select.Option>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    {dataConsultores.map((item) => (
                                      <Select.Option key={item.value} value={item.value}>
                                        {item.label}
                                      </Select.Option>
                                    ))}
                                  </>
                                )}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Table
                            style={{
                              display: 'flex',
                              width: '46rem',
                            }}
                            size='small'
                            dataSource={dataClienteLiberados}
                            footer={() => (
                              <div
                                style={{
                                  display: 'flex',
                                  width: '46rem',
                                  justifyContent: 'right',
                                }}
                              >
                                {/* <b>Total geral: {dataClienteLiberados.length > 0 ? dataClienteLiberados.length : 0}</b> */}
                              </div>
                            )}
                            columns={[
                              {
                                key: 1,
                                dataIndex: 'nome',
                                title: 'Clientes Liberados',
                              },
                            ]}
                          />
                        </div>
                      </Row>
                    </>
                  ) : (
                    <Col span={23}>
                      <Row gutter={[8, 8]}>
                        {loggedInAs?.type === 'CONTA' ? (
                          <>
                            <Col span={24}>
                              <Form.Item
                                name='cod_perfil_conta'
                                label='Selecione um perfil de acesso a consultoria'
                                initialValue=''
                              >
                                <Select
                                  allowClear
                                  className={permissions?.acesso === 1 ? 'readOnly' : ''}
                                  style={{ maxWidth: '400px' }}
                                  onClear={() =>
                                    handleClearAcesso(
                                      'cod_perfil_conta',
                                      form.getFieldValue('cod_perfil_conta')
                                    )
                                  }
                                >
                                  {listDataProfiles.map((item) => (
                                    <Select.Option key={item.cod} value={item.cod}>
                                      {item.nome}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name='cod_perfil_cliente_geral'
                                initialValue=''
                                label={
                                  <Space>
                                    Selecione um perfil de acesso aos clientes
                                    <small style={{ opacity: '0.5' }}>(Opcional)</small>
                                  </Space>
                                }
                              >
                                <Select
                                  allowClear
                                  className={permissions?.acesso === 1 ? 'readOnly' : ''}
                                  style={{ maxWidth: '400px' }}
                                  onClear={() =>
                                    handleClearAcesso(
                                      'cod_perfil_cliente_geral',
                                      form.getFieldValue('cod_perfil_cliente_geral')
                                    )
                                  }
                                >
                                  {listDataProfiles.map((item) => (
                                    <Select.Option key={item.cod} value={item.cod}>
                                      {item.nome}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            {codConsultor !== null || cargoAt === true || cargoTecnico === true ? (
                              <Col span={24}>
                                <Form.Item
                                  key={updateKey}
                                  name={codConsultor === null ? '' : nomeLider}
                                  initialValue={codConsultor === null ? '' : nomeLider}
                                  label={
                                    <Space>
                                      {cargoTecnico == true
                                        ? 'Líder do Técnico'
                                        : 'Consultor do Assistente Técnico'}
                                      <small style={{ opacity: '0.5' }}>(Opcional)</small>
                                    </Space>
                                  }
                                >
                                  <Select
                                    key={updateKey}
                                    disabled={
                                      cargoTecnico === true
                                        ? acessoPermissoes[1].inclusao === 0
                                        : acessoPermissoes[2].inclusao === 0
                                    }
                                    defaultValue={codConsultor === null ? '' : nomeLider}
                                    allowClear
                                    className={permissions?.acesso === 1 ? 'readOnly' : ''}
                                    style={{ maxWidth: '400px' }}
                                    options={cargoTecnico === true ? dataLideres : dataConsultores}
                                    onChange={handleChangeLider}
                                    onClear={() => handleClearLiderEquipe(codConsultor)}
                                  ></Select>
                                </Form.Item>
                              </Col>
                            ) : null}
                            <Col span={24}>
                              <Form.Item
                                name='acesso_cliente'
                                label='Permitir acesso a todos os clientes?'
                              >
                                <Radio.Group
                                  disabled={
                                    permissions?.acesso === 1 ||
                                    permissions?.alteracao === 0 ||
                                    permissions?.inclusao === 0
                                  }
                                  onChange={(data) => setAllClients(data.target.value > 0)}
                                  optionType='button'
                                  buttonStyle='solid'
                                  options={[
                                    { label: 'Sim', value: 1 },
                                    { label: 'Não', value: 0 },
                                  ]}
                                />
                              </Form.Item>
                            </Col>

                            <Col hidden={allClients} span={23}>
                              <Form.Item
                                name='cliente'
                                initialValue={[]}
                                label='Selecione os clientes que o usuário terá acesso'
                              >
                                <Transfer
                                  className={`${permissions?.acesso === 1 ? 'readOnly' : ''} ${
                                    responsive && 'transfer-responsive'
                                  }`}
                                  selectAllLabels={[
                                    <b>Clientes sem acesso</b>,
                                    <b>Clientes com acesso</b>,
                                  ]}
                                  listStyle={{
                                    height: 'calc(100vh - 500px)',
                                    width: '100%',
                                  }}
                                  style={{ maxWidth: '100%' }}
                                  rowKey={(item) => String(item.cod)}
                                  dataSource={listDataCliente}
                                  render={(item) => item.nome}
                                  targetKeys={targetKey}
                                  onChange={handleChangeMoveTransfer}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        ) : null}
                        {loggedInAs?.type === 'CLIENTE' || loggedInAs?.type === 'CONTA_CLIENTE' ? (
                          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item
                              name='cod_perfil_cliente'
                              label='Selecione um perfil de acesso ao cliente'
                              initialValue=''
                            >
                              <Select
                                allowClear
                                className={permissions?.acesso === 1 ? 'readOnly' : ''}
                              >
                                {listDataProfiles.map((item) => (
                                  <Select.Option key={item.cod} value={item.cod}>
                                    {item.nome}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        ) : null}
                      </Row>
                    </Col>
                  )}
                </TabPane>

                <TabPane
                  disabled={codFunc === null}
                  forceRender
                  key={7}
                  tab='Acessos a propriedades'
                  style={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}
                >
                  {codClienteLocal === '' && noInput === false ? (
                    <Form.Item name='list-propriedade' label='Cliente'>
                      <Select
                        showSearch
                        optionFilterProp='label'
                        style={{ width: '76%' }}
                        onChange={handleChangeAccessPro}
                        options={listDataClienteLiberado?.map((rec) => {
                          return { label: rec.nome, value: rec.cod }
                        })}
                      />
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                  {listarPropriedadesAtual.length != 0 ? (
                    <Form.Item>
                      <Transfer
                        disabled={loadingTransferPro}
                        className={`${permissions?.acesso === 1 ? 'readOnly' : ''} ${
                          responsive && 'transfer-responsive'
                        }`}
                        selectAllLabels={[
                          <b>
                            Propriedades sem acesso{' '}
                            <Spin
                              size='small'
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24, display: !loadingTransferPro && 'none' }}
                                  spin
                                />
                              }
                            />
                          </b>,
                          <b>
                            Propriedades com acesso{' '}
                            <Spin
                              size='small'
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24, display: !loadingTransferPro && 'none' }}
                                  spin
                                />
                              }
                            />
                          </b>,
                        ]}
                        listStyle={{
                          height: 'calc(100vh - 500px)',
                          width: '100%',
                        }}
                        style={{ width: '100%' }}
                        rowKey={(item) => String(item.cod)}
                        dataSource={listarPropriedadesAtual}
                        render={(item) => item.nome}
                        targetKeys={targetKeysPro}
                        onChange={handleChangeMoveTransferPro}
                      />
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                </TabPane>
              </>
            ) : null}
          </Tabs>

          {/* Hiddenn */}

          <Form.Item initialValue='1' hidden name='status'>
            <Input readOnly={permissions?.acesso === 1} />
          </Form.Item>
        </Drawer>
      </Form>
    </Container>
  )
}

export default Equipe
