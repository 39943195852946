import styled from "styled-components";


export const Button = styled.button`    
    font-size: 14px;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
    border: none;
    background: var(--primary-color);
    color: #fff;
    display: inline-flex;
    gap: 7px;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    transition: .3s;
    will-change: transform;
    white-space: nowrap;

    &&:active {
        transform: scale(0.98);
    }

    &&:disabled {
        background-color: var(--disabled-color) !important;
        color: var(--disabled-text-color) !important;
        border-color: var(--disabled-text-color);
        cursor: not-allowed;
    }
    :is([data-danger = true]) {
        background-color: var(--danger-color);
        color: #fff;
    }

    &&:is([data-type = 'secondary']) {
        background-color: transparent;
        color: var(--primary-color);
        border: thin solid var(--primary-color);
    }
    &&:is([data-type = 'secondary']):hover {
        background-color: var(--primary-color);
        color: #fff;
    }
    &&:is([data-type = 'secondary']):is([data-danger = true]) {
        background-color: transparent;
        color: var(--danger-color);
        border: thin solid var(--danger-color);
    }
    &&:is([data-type = 'secondary']):is([data-danger = true]):hover {
        background-color: var(--danger-color);
        color: #fff;
    }
    &&:disabled:is([data-type = 'secondary']) {
        background-color: var(--disabled-color) !important;
        color: var(--disabled-text-color) !important;
        border-color: var(--disabled-text-color);
        cursor: not-allowed;
    }

    &&:is([data-type = 'tertiary']) {
        background-color: transparent;
        color: var(--primary-color);
        text-decoration: underline;
    }
    &&:is([data-type = 'tertiary']):hover {
        background-color: var(--primary-color);
        color: #fff;
    }

    &&:is([data-type = 'tertiary']):is([data-danger = true]) {
        background-color: transparent;
        color: var(--danger-color);
        text-decoration: underline;
    }
    &&:is([data-type = 'tertiary']):is([data-danger = true]):hover {
        background-color: var(--danger-color);
        color: #fff;
    }
`