import { useEffect } from "react";
import styled from "styled-components";

//STYLES
const Item = styled.button`
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    /* outline: none; */

    :hover {
        background-color: var(--hover-color);
    }
    :focus {
        background-color: var(--hover-color);
    }
    :is([data-focused = true]) {
        background-color: var(--hover-color);
    }
    :is([data-current = true]) {
        background-color: #d8e6dc;
        font-weight: 600;
        border-radius: 7px;
    }
    :is([data-disabled = true]) {
        background-color: var(--disabled-color);
        color: var(--disabled-text-color);
        cursor: not-allowed;
    }

`;

//PROPS
interface Props {
    children?: React.ReactNode;
    index?: number;
    focused: boolean;
    onClick: Function;
    current?: string;
    disabled?: boolean;
}

export const ItemList: React.FC<Props> = ({ children, focused, index, onClick, current, disabled }) => {

    useEffect(() => {
        if (focused) {
            let elemento = document.getElementById(`item_${index}`);
            elemento.focus()
        }
    }, [focused])
    

    return (
        <Item 
            data-focused={focused} 
            disabled={disabled} 
            data-disabled={disabled} 
            data-current={current === children} 
            id={`item_${index}`} 
            onClick={()=>onClick()}
        >
            {children}
        </Item>
    )
}