
/**Remove os espaços entre as palavras */
export function removerEspacos(str: string): string {
    return String(str).replace(/\s+/g, '');
}

/**retorna a ultima posição de um array
 * 
 * (opcional) pode passar um segundo argumento `posicao` para retornar a posicao do array
 * 
 * (padrão) pode passar um segundo argumento `valor` para retornar o valor da posicao 
 */
export function ultimaPosicao(array: any[], retornar?: 'posicao' | 'valor') {
    if (retornar==='posicao') {
        return array?.slice(-1);
    }
    return array?.slice(-1)?.[0];
}


/**Remove os acentos de uma string */
export function removerAcentos(str: string): string {
    return String(str).normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}


export function leadZero(numero: string | number) {
return String(numero)?.padStart(2, '0');
}

/**retorna o próprio index fornecido, apenas para eviar warning do SonarQube */
export function gerarKey(index: number): number {
return index
}

/**Deixa que apenas números sejam digitados */
export const apenasNumeros = value => {
    return String(value)?.replace(/\D/g, '') ?? value;
}


/**Converte um array de `strings` para um array de `números` */
export function parseArrayNumeros(array: string[] | number[]) {
    return (array??[])?.map(item=> Number(item));
}

/**Converte um array de `números` para um array de `strings` */
export function parseArrayStrings(array: number[] | string[]) {
    return (array??[])?.map(item=> String(item));
}