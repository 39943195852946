import { Button, Drawer, Modal, Popconfirm, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Input from '../../../../../Components/CustomUi/input'
import {
  faArrowRightLong,
  faDna,
  faFlask,
  faHeartbeat,
  faTablets,
  faVial,
  faVials,
  faViruses,
  faWheatAwn,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import TextArea from '../../../../../Components/CustomUi/textarea'
import { SelectMulti } from '../../../../../Components/CustomUi/SelectMulti'
import { getCulturas } from '../../../../../services/cadastrarCultura'
import { postBiotecnologia, putBiotecnologia } from '../../../../../services/cadastrarBiotecnologia'
import { Notification } from '../../../../../Components/notification'
import { IconAlert } from '../../../../../Components/iconsAlert'
import { Inline } from '../../../../../Components/CustomUi/inline'
import ButtonCustom from '../../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface TypesCadastrarBio {
  open: boolean
  update: number
  onClose?: Function
  dadosBio?: any[]
}

const FormBiotecnologia: React.FC<TypesCadastrarBio> = (props) => {
  const { open, update, onClose, dadosBio } = props

  const [openSelect, setOpenSelect] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [culturas, setCulturas] = useState([])
  const [culturaSelects, setCulturasSelects] = useState([])
  const [nomeBio, setBioNome] = useState('')
  const [descricaoBio, setDescricaoBio] = useState('')
  const [nomeResumido, setNomeResumido] = useState('')

  async function getCultura() {
    const resp = await getCulturas({ pagination: -1 })
    setCulturas(resp.data.itens)
  }

  const validateFields = () => {
    let isValid = true

    if (!nomeBio) {
      Notification({
        message: 'Preencha o campo nome',
        type: 'error',
      })
      isValid = false
    }

    if (!nomeResumido) {
      Notification({
        message: 'Preencha o campo nome resumido',
        type: 'error',
      })
      isValid = false
    }

    if (!descricaoBio) {
      Notification({
        message: 'Preencha o campo descrição',
        type: 'error',
      })
      isValid = false
    }

    if (culturaSelects.length === 0) {
      Notification({
        message: 'Selecione as culturas',
        type: 'error',
      })

      isValid = false
    }

    return isValid
  }

  function validor() {
    if (update > 0) {
      onClose()
    } else {
      if (nomeBio !== '') {
        setOpenModal(true)
        return null
      } else if (nomeResumido !== '') {
        setOpenModal(true)
        return null
      } else if (descricaoBio !== '') {
        setOpenModal(true)
        return null
      } else if (culturaSelects.length !== 0) {
        setOpenModal(true)
        return null
      } else if (nomeResumido !== '') {
        setOpenModal(true)
        return null
      } else {
        onClose()
      }
    }

    // onClose()
  }

  async function onFinish() {
    if (update > 0) {
      function subtractArrays(originalArray, modifiedArray) {
        return originalArray.filter((item) => !modifiedArray.includes(item))
      }

      const original = culturas.map((cod) => cod.cod)
      const arrayEditCulturas = culturaSelects?.map((item) => item?.id)
      const resultArray = subtractArrays(original, arrayEditCulturas)

      const data = {
        nome: nomeBio,
        sigla: nomeResumido,
        descricao: descricaoBio,
        culturas: arrayEditCulturas,
        ...(resultArray.length > 0 && { culturas_removers: resultArray }),
      }
      const response = await putBiotecnologia(
        data,
        update,
        setBioNome,
        setNomeResumido,
        setDescricaoBio,
        setCulturasSelects,
        onClose
      )
    } else {
      if (validateFields()) {
        const array = culturaSelects?.map((item) => item?.id)
        const dados = {
          nome: nomeBio,
          sigla: nomeResumido,
          descricao: descricaoBio,
          culturas: array,
          culturas_removers: [],
        }

        const response = await postBiotecnologia(
          dados,
          setBioNome,
          setDescricaoBio,
          setCulturasSelects,
          setNomeResumido
        )
      }
    }
  }

  useEffect(() => {
    if (update > 0) {
      const dataEdit = dadosBio.find((item) => item.cod === update)

      setBioNome(dataEdit?.nome)
      setDescricaoBio(dataEdit?.descricao)
      setNomeResumido(dataEdit?.sigla)
      const editCulturas = dataEdit?.culturas?.map((rec, index) => {
        return { descr: rec?.cultura_nome, id: rec?.cod_cultura, key: index }
      })

      setCulturasSelects(editCulturas)
    }
  }, [update, open])

  useEffect(() => {
    getCultura()
  }, [])

  console.log('dados', dadosBio, update)

  console.log('selectds', culturaSelects)

  useEffect(() => {
    if (open === false) {
      setBioNome('')
      setDescricaoBio('')
      setNomeResumido('')
      setCulturasSelects([])
      setOpenModal(false)
    }
  }, [open])

  return (
    <Drawer
      placement='right'
      style={{ position: 'absolute' }}
      getContainer={false}
      open={open}
      closeIcon={false}
      onClose={() => validor()}
      destroyOnClose
      footer={
        <Space>
          <Button type='primary' htmlType='submit' onClick={onFinish}>
            {update > 0 ? 'Atualizar' : 'Salvar'}
          </Button>

          <Button type='primary' danger onClick={() => validor()}>
            Fechar
          </Button>
        </Space>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <h1>{update > 0 ? 'Atualizar biotecnologia' : 'Cadastrar biotecnologia'}</h1>
        <Popconfirm
          placement='left'
          title={`Você não salvou o cadastro!`}
          open={openModal}
          description={
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
              <span>Deseja fechar sem salvar? Todas as informações inseridas serão perdidas.</span>
              <Inline justifyContent='flex-end'>
                <ButtonCustom
                  onClick={() => {
                    onClose(false)
                  }}
                  icon={<FontAwesomeIcon icon={faXmark} />}
                  danger
                  type='tertiary'
                >
                  Fechar
                </ButtonCustom>

                <ButtonCustom
                  onClick={() => {
                    setOpenModal(false)
                  }}
                  // type="tertiary"
                  icon={<FontAwesomeIcon icon={faArrowRightLong} />}
                >
                  Voltar para o cadastro
                </ButtonCustom>
              </Inline>
            </div>
          }
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        ></Popconfirm>
        <Input
          onChange={(e) => setBioNome(e)}
          value={nomeBio}
          icon={faFlask}
          label='Nome'
          placeholder='Nome da biotecnologia'
        />
        <Input
          onChange={(e) => setNomeResumido(e)}
          value={nomeResumido}
          icon={faVial}
          label='Nome resumido'
          placeholder='Nome resumido da biotecnologia'
        />
        <TextArea
          onChange={(e) => setDescricaoBio(e)}
          value={descricaoBio}
          label='Descrição'
          placeholder='Descrição da biotecnologia'
        />
        <SelectMulti
          open={openSelect}
          trigger={setOpenSelect}
          descricaoItem='nome'
          idItem='cod'
          items={culturas}
          onChange={setCulturasSelects}
          icon={faWheatAwn}
          label='Culturas'
          placeholder='Seleciones as culturas'
          selecteds={culturaSelects}
        ></SelectMulti>
      </div>
    </Drawer>
  )
}

export default FormBiotecnologia
