import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { Button, Space, Table, Tooltip, message } from 'antd'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import ViewPage from '../../../../Components/ViewPage'

import { pagination } from '../../../../Components/Default'
import { localConfig } from '../../../../Configs/localConfig'
import { filterTable } from '../../../../services/Afins'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { Container } from './styles'

import { groupBy } from 'lodash'
import Icons from '../../../../Components/Icons'
import MapaFertilidade from '../../../../Components/ordemLaboratorio/mapaFertilidade'
import { useLogic } from '../../../../context/useLogic'
import { OsItensTypes, getPedLaboratorio } from '../../../../services/Laboratorio'
import {
  ItensMapaFertilidadeTypes,
  getMapaFertilidade,
} from '../../../../services/Laboratorio/osResultado'
import { PropriedadeItens } from '../../../../services/Propriedades'
import { ItensColetaTrajetoTypes, getColetaTrajeto } from '../../../../services/coletaTrajeto'
import { ItensColetaUsuariosTypes, getColetaUsuarios } from '../../../../services/coletaUsuarios'
import ShapeTalhao from '../../../../Components/CustomUi/shapeTalhao'

interface ProsDataOs {
  os: ItensMapaFertilidadeTypes[]
  talhao: OsItensTypes['talhao_kml']
  propriedade: PropriedadeItens
  areaTalhao: string
  talhaoNome: string
  data: string | Dayjs
  percurso: { [key: string]: ItensColetaTrajetoTypes[] }
  coletaUsuario: ItensColetaUsuariosTypes[]
  nome_cliente: string,
  calcario_propriedade?: string
}

function PageMapaFertilidade() {
  const { checkSafra } = useLogic()

  const history = useHistory()

  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [dataOrderSer, setDataOrderSer] = useState<OsItensTypes[]>([])

  const [dataOs, setDataOs] = useState<ProsDataOs>()
  const codSafra = localStorage.getItem('codSafra')

  const listarOS = async () => {
    setLoadingTable(true)
    const resp = await getPedLaboratorio('')

    // const respEquipe = await getEquipeConta('', '', { responsavel_fertilidade: true })
    const dados = resp.data.itens

    setDataOrderSer(dados)
    setLoadingTable(false)
  }

  const handleClickOs = async (codPedido: number, nome_cliente: string, calcario_propriedade?: string) => {
    setVisibleDrawer(true)
    message.loading('Aguarde...', 999999)

    // const resp = await getMapaFertilidade({ codPedido, elementoUsoMapa: true })
    // const respPer = await getColetaTrajeto({ codPedido })
    // const respUser = await getColetaUsuarios({ codPedido })

    const [dadosOS, resp, respPer, respUser] = await Promise.all([
      getPedLaboratorio(codPedido),
      getMapaFertilidade({ codPedido, elementoUsoMapa: true }),
      getColetaTrajeto({ codPedido }),
      getColetaUsuarios({ codPedido }),
    ])

    const percursoGroup = groupBy(respPer.data.itens, 'ponto')

    setDataOs({
      data: dadosOS?.data.itens?.[0].data_coleta,
      talhao: dadosOS?.data.itens?.[0].talhao_kml,
      areaTalhao: dadosOS?.data.itens?.[0].talhao_area,
      os: resp.data.itens,
      propriedade: dadosOS?.data.itens?.[0].propriedade[0],
      talhaoNome: dadosOS?.data.itens?.[0].talhao_nome,
      percurso: percursoGroup,
      coletaUsuario: respUser.data.itens,
      nome_cliente: nome_cliente,
      calcario_propriedade: calcario_propriedade
    })
    setVisibleDrawer(true)
    message.destroy()
  }

  useEffect(() => {
    if (!visibleDrawer) {
      setDataOs({
        data: '',
        os: [],
        propriedade: null,
        talhao: null,
        talhaoNome: '',
        areaTalhao: '',
        percurso: {},
        coletaUsuario: [],
        nome_cliente: '',
        calcario_propriedade: ''
      })
    }
  }, [visibleDrawer])

  useEffect(() => {
    listarOS()

    const idDaSafra = checkSafra('/precisao/mapa-fertilidade')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])

  useEffect(() => {
    setLoadingTable(true)
    listarOS()
  }, [codSafra])


  return (
    <Container>
      <ViewPage
        onPermission={setPermissions}
        rotina={82}
        newHide
        title='OS resultado'
        btnClick={() => {}}
        content={<MapaFertilidade open={visibleDrawer} onClose={setVisibleDrawer} data={dataOs} />}
      >
        <Table
          style={{ pointerEvents: permissions?.acesso === 0 ? 'none' : 'auto' }}
          rowKey='cod'
          size='small'
          pagination={pagination}
          dataSource={dataOrderSer.filter((record) => record.status >= 5)}
          // dataSource={dataOrderSer}
          bordered
          scroll={{ y: 'calc(100vh - 325px)' }}
          loading={loadingTable}
          columns={[
            {
              title: <div style={{ minWidth: '50px' }}>Cod</div>,
              dataIndex: 'cod',
              width: '4rem',
            },
            {
              title: <div style={{ minWidth: '50px' }}>Data</div>,
              key: 1,
              dataIndex: 'data',
              width: '150px',
              render: (data) => (
                <>
                  {data?.substr(0, 10).split('-').reverse().join('/')}
                  {data?.substr(10, 18)}
                </>
              ),
              sorter: {
                compare: (a, b) => a.status - b.status,
                multiple: 1,
              },
            },
            {
              title: <div style={{ minWidth: '100px' }}>Cliente</div>,
              dataIndex: 'conta',
              width: localConfig().codCliente !== '' ? 0 : null,
              className: localConfig().codCliente !== '' ? 'hideColum' : null,
              render: (data, dados) => (
                <div>
                  {dados?.cod_cliente !== null ? (
                    <Tooltip
                      placement='right'
                      title={dados?.cliente_nome.length > 30 ? dados?.cliente_nome : null}
                    >
                      <div
                        style={{
                          maxWidth: '30ch',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {dados?.cliente_nome}
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip placement='right' title={data[0].nome > 30 ? data[0].nome : null}>
                      <div
                        style={{
                          maxWidth: '30ch',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {data[0].nome}
                      </div>
                    </Tooltip>
                  )}
                </div>
              ),
            },
            {
              title: <div style={{ minWidth: '50px' }}>Safra</div>,
              dataIndex: 'safra_nome',
              width: 'auto',
              render: (data) => (
                <Tooltip placement='right' title={data}>
                  <div
                    style={{
                      maxWidth: '30ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {data}
                  </div>
                </Tooltip>
              ),
            },
            {
              title: <div style={{ minWidth: '50px' }}>Propriedade</div>,
              width: 'auto',
              ...filterTable(false, true, 'propriedade_nome', dataOrderSer),
              dataIndex: 'propriedade_nome',
              render: (data) => (
                <Tooltip placement='right' title={data.length > 30 ? data : null}>
                  {data}
                </Tooltip>
              ),
            },
            {
              title: <div style={{ minWidth: '50px' }}>Talhão</div>,
              dataIndex: 'talhao_nome',
              render: (data, item) => (
                <Tooltip placement='right' title={data}>
                  <div
                    style={{
                      maxWidth: '30ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 15
                      // flexDirection: 'column',

                    }}
                  >
                    <ShapeTalhao 
                      coordinates={item?.talhao_kml?.coordenadas}
                      fill='var(--primary-color)'
                    />
                    {data}
                  </div>
                </Tooltip>
              ),
            },
            {
              title: <div style={{ minWidth: '50px' }}>Ações</div>,
              dataIndex: 'cod',
              align: 'center',
              width: 90,
              key: 'acao',
              render: (data, record) => {
                return (
                  <Space>
                    <Button
                      onClick={() => handleClickOs(data, record?.cliente_nome, record?.calcario_propriedade)}
                      shape='circle'
                      disabled={record.status <= 5}
                      icon={<Icons icon={faMapMarkedAlt} />}
                    />
                  </Space>
                )
              },
            },
          ]}
        />
      </ViewPage>
    </Container>
  )
}

export default PageMapaFertilidade
