import styled from "styled-components";
import { Switch } from "antd";

//STYLES
const SwitchWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    color: var(--text-color);
`;

//PROPS
interface Props {
    isChecked: boolean;
    trigger: React.Dispatch<React.SetStateAction<boolean>>;
    label?: string | React.ReactNode;
    disabled?: boolean;
}

export const Switcher: React.FC<Props> = ({ isChecked, trigger, label, disabled }) => {

    return (
        <SwitchWrapper>
            <Switch 
                checkedChildren="Sim" 
                unCheckedChildren="Não"
                checked={isChecked}
                onChange={()=>trigger(!isChecked)}
                disabled={disabled}
            />
            {!!label &&(
                <>{label}</>
            )}
            
        </SwitchWrapper>
    )
};