import styled from 'styled-components'

const Container = styled.div<{ collapsed?: boolean }>`
  position: relative;
  overflow: hidden;
  height: 100vh;

  header {
    position: relative;

    .option-user {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 15px;
      display: flex;
      align-items: center;
      gap: 15px;

      .btn-app-notificacao {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: transparent;
        border: none;
        margin-top: 2px;
      }
    }

    .selectCliente {
      position: absolute;
      top: 15px;
      left: 50px;
    }

    

  }

  .ant-dropdown-menu-item {
    color: red !important;
  }

  .area-menu {
    position: relative;
  }

  .trigger-responsive {
    position: absolute;
    background-color: #fff;
    z-index: 99999;
    right: -40px;
    padding: 10px;
    font-size: 18px;
    line-height: 76px;
    cursor: pointer;
    transition: color 0.3s;
    border-radius: 4px;
  }

  .trigger-responsive:hover {
    color: #1890ff;
  }

  .trigger {
    position: absolute;
    left: 0;
    padding: 0 24px;
    font-size: 18px;
    line-height: 76px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }

  .area-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 30 !important;
    height: 76px;
    padding: 0;
    background: #fff
  }

  .btn-app-notificacao{
    background-color: transparent;
    border: none;
  }

  .area-logo-lab {
    display: flex;
    height: 100%;
    width: 38%;
    justify-content: left;
    align-items: center;
    
  }

  .nome-lab {
    font-size: 24px;
    font-weight: 600;
    color: #d2d2d2;
    height: 100%;
    padding-right: 10px;
    margin-left: 20px;
  }

  .header-principal {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .logo {
    height: 65px;
  }

  .logo-lab {
    height: 62px;
  }

  .logo-sm {
    height: 55px;
  }

  .logo-test {
    height: 58px;

    img {
      width: 140px;
    }
  }

  .logo-sm-test {
    height: 58px;

    img {
      width: 68px;
    }
  }

  .logo-cliente {
    height: 64px;
    background: #fff;
    max-width: 68px;
  }

  .site-layout .site-layout-background {
    background: #fff;

    span:hover {
      color: #4caf50;
    }
  }

  .btn-sair {
    width: 5%;
    background-color: transparent;
    color: #d2d2d2;
    border: none;
    cursor: pointer;
    transition: 0.2s;
  }

  .btn-sair:hover{
    transform: scale(1.1);
    transition: 0.4s;
  }

  .corThema {
    background: linear-gradient(0deg, #5da47d 15%, #2b6957 100%);
    height: 91vh;
    padding: 10px !important;

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
  .corThema.time_7h {
    background: linear-gradient(0deg, #2b6957  50%, #5da47d 80%);

  }
  .corThema.time_8h {
    background: linear-gradient(0deg, #2b6957  40%, #5da47d 77%);
  }
  .corThema.time_9h {
    background: linear-gradient(0deg, #2b6957  30%, #5da47d 73%);
  }

  .corThema.time_10h {
    background: linear-gradient(0deg, #2b6957 20%, #5da47d 70%);
  }
  .corThema.time_11h {
    background: linear-gradient(0deg, #2b6957 14%, #5da47d 73%);
  }
  .corThema.time_12h {
    background: linear-gradient(0deg, #2b6957 7%, #5da47d 77%);
  }

  .corThema.time_13h {
    background: linear-gradient(0deg, #2b6957 0%, #5da47d 80%);
  }
  .corThema.time_14h {
    background: linear-gradient(0deg, #2b6957 25%, #5da47d 87%);
  }
  .corThema.time_15h {
    background: linear-gradient(0deg, #2b6957 50%, #5da47d 95%);
  }
  .corThema.time_16h {
    background: linear-gradient(0deg, #2b6957 70%, #5da47d 100%);
  }
  .corThema.time_17h {
    background: linear-gradient(0deg, #2b6957 90%, #5da47d 100%);
  }


  .corThema.time_18h {
    background: linear-gradient(0deg, #2b6957 30%, #225446 100%);
  }
  .corThema.time_19h {
    background: linear-gradient(0deg, #5da47d 0%, #225446 70%);
  }

  .corThema.time_20h {
    background: linear-gradient(0deg, #5da47d 0%, #225446 65%);
  }
  .corThema.time_21h {
    background: linear-gradient(0deg, #5da47d 0%, #225446 60%);
  }
  .corThema.time_22h {
    background: linear-gradient(0deg, #5da47d 0%, #225446 55%);
  }
  .corThema.time_23h {
    background: linear-gradient(0deg, #5da47d 0%, #225446 50%);
  }

  .corThema.time_0h {
    background: linear-gradient(0deg, #5da47d 0%, #225446 0%);
  }
  .corThema.time_1h,
  .corThema.time_2h,
  .corThema.time_3h,
  .corThema.time_4h {
    background: linear-gradient(0deg, #225446 0%, #112a23 70%);
  }




  .ant-menu-item-selected {
    /* background: linear-gradient(0deg, rgba(64, 128, 128, 1) 0%, rgba(59, 80, 99, 1) 50%) !important; */
    background: #fff !important;
    color: var(--primary-color);
    font-weight: 600 !important;
    text-shadow: none !important;

    div.item-tile {
      display: block !important;
    }
  }


  .ant-menu-item {
    font-weight: 500;
  }

  .ant-dropdown-menu-item {
    span {
      font-size: 50px !important;
    }
  }

  .ant-menu-dark {
    color: rgba(255, 255, 255, 0.9) !important;
  }

  .ant-menu-inline-collapsed .sidebar-menu-item {
    padding-left: 24px !important;
  }

  .sidebar-menu-item {
    width: 100%;
    min-height: 40px;
    margin: 0;
    margin-top: 2px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    gap: 0px !important;
    padding: 0;
    box-sizing: border-box;
    padding: 0 15px !important;
    position: relative;
    transition: .1s;
    text-shadow: 1px 0px 10px #2B6957;

    :hover {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
    :active {
      transform: scale(0.96);
    }

    .ant-menu-item-icon {
      margin-top: -1px ;
    }

    div.item-tile {
      width: 3px;
      height: 18px;
      background-color: #8AC558;
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 10px;
      display: none;
      animation: entrada_cima 0.35s cubic-bezier(.57,.11,.03,1.18) forwards;

      :is([data-origin-below-menu = true]) {
        animation: entrada_baixo 0.35s cubic-bezier(.57,.11,.03,1.18) forwards !important;
      }

        @keyframes entrada_cima {
          0% {
              top: -50px;
              height: 2px;
          }
          100% {
              height: 18px;
          }
        }
        @keyframes entrada_baixo {
          0% {
              top: 50px;
              height: 2px;
          }
          100% {
              height: 18px;
          }
        }
    }
  }

  .leave:hover {
      background: rgba(255, 0, 0, 0.4) !important;
    }

  .sidebar-menu-item.ant-menu-item-selected:hover {
    background-color: #fff !important;
  }

  .ant-menu-inline-collapsed .sidebar-submenu {
    padding-left: 9px !important;
  }

  .sidebar-submenu {
    width: 100%;
    min-height: 40px;
    text-shadow: 1px 0px 10px #2B6957;

    :hover {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }

    .ant-menu-submenu-title {
      padding: 0px 0px 0px 15px !important;
      margin: 0px !important;

      .ant-menu-title-content {
        margin-top: -4px !important;
      }
      .ant-menu-submenu-arrow {
        inset-inline-end: 1px !important;
        margin-top: -4px !important;
      }

    }
    
    .ant-menu-item.submenu-item-new {
      border-radius: 0px !important;

      .submenu-content {
        width: 100%;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 0px 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        transition: .1s;
        text-shadow: 1px 0px 10px #2B6957;
      }
      .submenu-content:hover {
        background-color: rgba(255, 255, 255, 0.2) !important;
      }
      .submenu-content:active {
        transform: scale(0.95);
      }

    }
    
    

    .ant-menu-item-selected.submenu-item-new {
      background: transparent !important;

      .submenu-content {
        width: 100%;
        max-width: 197px !important;
        border-radius: 8px;
        box-sizing: border-box;
        background: white;
        text-shadow: none !important;

      :hover {
        background-color: white !important;
      }

        div.item-tile {
          width: 3px;
          height: 18px;
          background-color: #8AC558;
          position: absolute;
          right: 10px;
          top: 10px;
          border-radius: 10px;
          display: none;
          animation: entrada_cima 0.35s cubic-bezier(.57,.11,.03,1.18) forwards;

          :is([data-origin-below = true]) {
            animation: entrada_baixo 0.35s cubic-bezier(.57,.11,.03,1.18) forwards !important;
          }

          @keyframes entrada_cima {
            0% {
                top: -50px;
                height: 2px;
            }
            100% {
                height: 18px;
            }
          }
          @keyframes entrada_baixo {
            0% {
                top: 50px;
                height: 2px;
            }
            100% {
                height: 18px;
            }
          }
        }
      }
    }

    .ant-menu-sub {
      padding: 0px !important;
      background: transparent !important;
      /* border-left: thin solid white; */
      margin-left: 18px;
      border-radius: 0px !important;
    }

    div.submenu-wrapper {
      display: flex;
          
      .box-stroke {
        width: 18px;
        height: 40px;
        border-left: thin solid white;

        .curve-stroke {
          width: 20px;
          min-width: 18px;
          height: 20px;
          border-left: thin solid white;
          border-bottom: thin solid white;
          border-bottom-left-radius: 13px;
          margin-left: -1px;
        }
      }
      .box-stroke:is([data-hidden = true]) {
        border-left: none;

        .curve-stroke {
          margin-left: 0px;
        }
      }

      
    }
  }

  .sidebar-submenu.ant-menu-submenu-open:hover {
    background-color: rgba(255, 255, 255, 0.07) !important;
  }
  
  

  .title > div > span {
    padding-top: 5px;
  }

  .title .anticon {
    padding-top: 5px;
  }

  .loadingMenu {
    .ant-spin-dot-item:nth-child(1) {
      background-color: #fff;
    }

    .ant-spin-dot-item:nth-child(2) {
      background-color: #d2d2d2;
    }

    .ant-spin-dot-item:nth-child(3) {
      background-color: #bcbcbc;
    }

    .ant-spin-dot-item:nth-child(4) {
      background-color: #ababab;
    }
  }

  .option-user {
    display: flex;
  }

  .loader {
    display: flex;
    min-width: 100vw;
    min-height: 100vh;
    background: linear-gradient(0deg, rgba(64, 128, 128, 1) 0%, rgba(59, 80, 99, 1) 50%);
    justify-content: center;
    align-items: center;

    .logo-loader {
      text-align: center;

      img {
        padding-bottom: 15px;
      }
    }
  }

  .ant-menu-item {
    z-index: 2;
    margin-inline: 0px;
    padding-inline: 0px;
    margin-block: 0px;
    width: 100%;
  }
  .ant-menu-submenu {
    z-index: 2;
  }

  .ant-menu-submenu-selected {
    background-color: rgba(255, 255, 255, 0.1) !important;
    /* background-color: rgba(93, 165, 125, 0.4) !important; */
  }

  .version-list {
    margin-top: 15px;
    width: ${(p) => (p.collapsed ? '78px' : '250px')};
    position: fixed;
    bottom: 0;
    left: 0;
    cursor: pointer;
    height: 20px;
    align-items: center;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    background-color: #225446;
    transition: 0.2s;
    box-shadow: rgb(34 84 70 / 100%) 0px -5px 6px -6px;

    span {
      padding: 10px;
      font-size: 11px;
    }
  }

  .version-list:hover {
    background-color: var(--primary-color);
  }

  img.fancy_detail {
    position: absolute;
    bottom: 40px;
    left: 90px;
    opacity: 0.10;
    z-index: 1;
  }

`

export default Container;