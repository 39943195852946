import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter, faXmark } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { PainelProps } from "./styles";

//APP
import { leadZero } from "../../../../../utils";
import { TalhaoItens } from "../../../../../services/Talhao";

//COMPONENTS
import styled from "styled-components";
import ButtonCustom from "../../../../../Components/ButtonCustom";
import { Switcher } from "../../../../../Components/CustomUi/Switcher";
import { LineDivider } from "../../../../../Components/CustomUi/LineDivider";
import { localConfig } from "../../../../../Configs/localConfig";
import { TabelaTodasPropiedades } from "../tabelaTodasPropriedades";

//PROPS
interface TypesVisuPropri {
    name?: string
    cod?: number
    cor?: string
    areaPropriedade?: number
    talhoes?: TalhaoItens[]
}
interface Props {
    exibir_area_talhao: boolean,
    setExibir_area_talhao: React.Dispatch<React.SetStateAction<boolean>>, 
    exibir_nome_talhao: boolean, 
    setExibir_nome_talhao: React.Dispatch<React.SetStateAction<boolean>>,
    onClose: Function,
    dados_propriedades: TypesVisuPropri[],
    distinguir_cor: boolean, 
    setDistinguir_cor: React.Dispatch<React.SetStateAction<boolean>>,
    ocultarSafra?: boolean
}

export const PainelTodasPropriedades: React.FC<Props> = ({ exibir_area_talhao, setExibir_area_talhao, 
    exibir_nome_talhao, setExibir_nome_talhao, onClose, dados_propriedades, distinguir_cor, 
    setDistinguir_cor, ocultarSafra }) => {

    const qtd_propriedades: number = dados_propriedades?.length ?? 0;
    const apenas_talhoes = dados_propriedades?.map(item=> item.talhoes);
    const qtd_talhoes:number = apenas_talhoes?.reduce((acumulador, array) => acumulador + array.length, 0) ?? 0;
    const tamanho_propriedades: number = dados_propriedades.reduce((acumulador, item) => acumulador + Number(item.areaPropriedade), 0) ?? 0;

    //STATES
    const [shrink, setShrink] = useState<boolean>(false);

    useEffect(() => {
        
      return () => {
        setExibir_nome_talhao(false);
        setExibir_area_talhao(false);
        setDistinguir_cor(false);
      }
    }, [])
    
    return (
        <PainelProps data-shrink={shrink}>
            <div style={{width: '100%', justifyContent: 'space-between', display: 'flex'}}>
                <ButtonCustom
                    onClick={()=>{onClose()}}
                    type="tertiary"
                    danger
                    icon={<FontAwesomeIcon icon={faXmark} />}
                    style={{marginLeft: -15, alignSelf: 'flex-start'}}
                >
                    Fechar
                </ButtonCustom>
                <ButtonCustom
                    onClick={()=>{setShrink(!shrink)}}
                    type={shrink ? 'primary' : "tertiary"}
                    icon={<FontAwesomeIcon icon={shrink ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter} />}
                    style={{marginLeft: -15, alignSelf: 'flex-start'}}
                />
            </div>
            
            
            {/* <LineDivider /> */}
            {!ocultarSafra &&(
                <>
                <div className="PainelProps__info">
                    <ItemInfo 
                        title="Safra"
                        value={`${localConfig()?.nomeSafra}`}
                    />
                </div>
                <LineDivider />
                </>
            )}
            


            <div className="PainelProps__info">
                <ItemInfo 
                    title="Área total"
                    value={`${Number(tamanho_propriedades).toFixed(2)} ha`}
                />
                <ItemInfo 
                    title="Propriedades"
                    value={String(leadZero(qtd_propriedades))}
                />
                <ItemInfo 
                    title="Talhões"
                    value={String(leadZero(qtd_talhoes))}
                />
            </div>

            <LineDivider />
            
            <TabelaTodasPropiedades dados_propriedades={dados_propriedades} distinguir_cor={distinguir_cor} />

            <LineDivider />

            <Switcher 
                isChecked={exibir_nome_talhao}
                trigger={setExibir_nome_talhao}
                label='Exibir nome dos talhões'
            />
            <Switcher 
                isChecked={exibir_area_talhao}
                trigger={setExibir_area_talhao}
                label='Exibir área dos talhões'
            />
            <Switcher 
                isChecked={distinguir_cor}
                trigger={setDistinguir_cor}
                label='Distinguir cor em cada propriedade'
            />
            {/* <Switcher 
                isChecked={distinguir_cor_talhao}
                trigger={setDistinguir_cor_talhao}
                label='Distinguir cor em cada talhão'
            /> */}

        </PainelProps>
    )
};

// -----------------------------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------

const Item = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    box-sizing: border-box;
    padding: 5px;

    p.ItemInfo__item__title {
        margin: 0;
        font-size: 13px;
        color: var(--text-color-light);
        font-weight: 500;
    }
    span.ItemInfo__item__value {
        margin: 0;
        font-size: 14px;
        color: var(--text-color);
        font-weight: 600;
        line-height: 15px;
    }
`;

//PROPS
interface PropsItemInfo {
    title: string;
    value: string;
}

const ItemInfo: React.FC<PropsItemInfo> = ({ title, value }) => {
    return (
        <Item>
            <p className="ItemInfo__item__title">
                {title}
            </p>
            <span 
                className="ItemInfo__item__value"
                style={{
                    textTransform: title==='Propriedade' ? 'capitalize' : 'none',
                    whiteSpace: title==='Área total' ? 'nowrap' : 'normal'
                }}
            >
                {value}
            </span>
        </Item>
    )
}